import React, { Component, createRef } from "react";
import "component/listItem/CategoryType7/styles.scss";
import bannerDefault from "assets/images/bannerDefault.png";
import {
  PlayDesktopIcon,
  TrophyIcon,
  RibbonIcon,
  EditorsChoiceIcon,
} from "component/icons";
import { ReactComponent as CrownIcon } from "assets/icons/crown.svg";
import { currencyFormatter } from "utils/currencyHelper";
import { setDefaultImageFormat } from "utils/image";

const ICON_DEFAULT = "ICON_DEFAULT";

class CategoryType7 extends Component {
  constructor(props) {
    super(props);
    this.onMouseOverHandler = this._onMouseOverHandler.bind(this);
    this.mainComponent = createRef();

    let jackpot = null;
    if (props.game.get("jackpotBalance")) {
      jackpot = currencyFormatter(props.game.get("jackpotBalance"), {
        currency: this.props.currency,
        symbol: this.props.symbol,
      });
    }
    this.state = {
      hovered: false,
      jackpot: jackpot,
      isVisible: false,
    };
  }

  _onMouseOverHandler(bol) {
    this.setState({
      hovered: bol,
    });
  }

  componentDidMount() {
    this.initObeserver();
  }

  initObeserver() {
    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      let observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (this.mainComponent.current) {
                this.setState({
                  isVisible: true,
                });
              }
            } else {
              if (this.mainComponent.current) {
                //clearInterval(this.initLoadDelay)
              }
            }
          });
        },
        {
          //rootMargin: '500px 0px 500px 0px'
          rootMargin: "0px 0px 0px 0px",
        }
      );
      observer.observe(this.mainComponent.current);
    } else {
      //this.getGames(this.props.link);
      //this.getTournamentPreview(this.props.links)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.game && nextProps.game !== this.props.game) {
      let jackpot;
      let imgSrc = `${nextProps.game.getIn([
        "images",
        "bannerUrl",
      ])}&width=180&theme=dark`;

      if (nextProps.game.get("jackpotBalance")) {
        jackpot = currencyFormatter(nextProps.game.get("jackpotBalance"), {
          currency: this.props.currency,
          symbol: this.props.symbol,
        });
      }

      this.setState({
        imgSrc: imgSrc,
        jackpot: jackpot,
      });
    }
  }

  render() {
    let { game } = this.props;
    let gameDetailsWidth;

    const hasPromotionIcons =
      game.get("hasFreeRounds") ||
      game.get("partOfOnGoingTournament") ||
      game.get("isFeatured") ||
      game.get("isJackpot");

    switch (true) {
      case game.get("hasFreeRounds") && game.get("partOfOnGoingTournament"):
      case game.get("hasFreeRounds") && game.get("isFeatured"):
      case game.get("isFeatured") && game.get("partOfOnGoingTournament"):
        gameDetailsWidth = "pr-2";
        break;
      case game.get("hasFreeRounds") &&
        game.get("partOfOnGoingTournament") &&
        game.get("isFeatured"):
        gameDetailsWidth = "pr-3";
        break;
      default:
        gameDetailsWidth = "pr-1";
        break;
    }

    return (
      <div
        className={`${this.props.className} category-type-7`}
        onMouseOver={() => {
          this.onMouseOverHandler(true);
        }}
        onMouseOut={() => {
          this.onMouseOverHandler(false);
        }}
        onClick={(event) => {
          this.props.onClick(this.props.linkDetails.get("rel"));
          event.stopPropagation();
        }}
        ref={this.mainComponent}
      >
        <div>
          <div className="img-holder">
            {this.state.isVisible && (
              <img
                src={`${setDefaultImageFormat(
                  game.getIn(["images", "bannerUrl"]) + "&width=250&theme=dark",
                  this.props.imageFormat.get("banner")
                )}`}
                className="icon d-none"
                alt={game.get("name")}
                onLoad={({ currentTarget }) => {
                  currentTarget.classList.remove("d-none");
                  this.refs[ICON_DEFAULT].classList.add("d-none");
                }}
              />
            )}
            <img
              ref={ICON_DEFAULT}
              src={bannerDefault}
              className="icon"
              alt={game.get("name")}
            />
            {this.state.jackpot && (
              <div className="jackpot-amount">
                <CrownIcon />
                {this.state.jackpot}
                <CrownIcon />
              </div>
            )}
            <div
              className={`actions w-100 h-100 justify-content-center align-items-center ${
                this.state.hovered ? "d-flex" : "d-none"
              }`}
            >
              {this.props.linkReal && (
                <div
                  className="play-button text-center"
                  onClick={(event) => {
                    this.props.onClick(this.props.linkReal.get("rel"));
                    event.stopPropagation();
                  }}
                >
                  <PlayDesktopIcon />
                </div>
              )}
              {this.props.linkDemo && (
                <button
                  className="demo-button btn btn-secondary btn-xs"
                  onClick={(event) => {
                    this.props.onClick(this.props.linkDemo.get("rel"));
                    event.stopPropagation();
                  }}
                >
                  {this.props.linkDemo.get("name")}
                </button>
              )}
            </div>
          </div>
          <div className="details">
            <div className="name">{game.get("name")}</div>
            <div className="provider">{game.get("provider")}</div>
            {game.get("gameType") && (
              <div className={`game-type ${gameDetailsWidth}`}>
                {game.get("gameType")}
              </div>
            )}
          </div>
          {hasPromotionIcons && (
            <div className="game-feature">
              {game.get("hasFreeRounds") && (
                <div className="game-feature-badge ribbonIcon">
                  <RibbonIcon color="rgba(255, 204, 0, 1)" />
                </div>
              )}
              {game.get("partOfOnGoingTournament") && (
                <div className="game-feature-badge trophyIcon">
                  <TrophyIcon color="rgba(255, 204, 0, 1)" />
                </div>
              )}
              {game.get("isJackpot") && (
                <div className="game-feature-badge jackpotIcon">
                  <CrownIcon color="rgba(255, 204, 0, 1)" />
                </div>
              )}
              {game.get("isFeatured") && (
                <div className="game-feature-badge editorsChoiceIcon">
                  <EditorsChoiceIcon color="rgba(255, 204, 0, 1)" />
                </div>
              )}
            </div>
          )}
          {!game.get("gameType") && (
            <div className="spacer">
              <>&nbsp;</>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CategoryType7;
