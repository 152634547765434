import React, { Component, Fragment } from "react";
import ReactGA from "react-ga4";
import { connect } from "react-redux";
import { fromJS, is } from "immutable";
import { bindActionCreators } from "redux";
import { ActionCreator } from "actions";
import * as queryString from "query-string";
import "containers/SearchQuick/styles.scss";
import { ProviderTypeSkeleton } from "component/listItemSkeleton";
import { ProviderTypeListItem, NoImageTypeListItem } from "component/listItem";
import { VericalList } from "component/list";
import { ReactComponent as PuzzlePieceIcon } from "assets/icons/puzzle_piece.svg";
import { ReactComponent as PantoneIcon } from "assets/icons/pantone.svg";
import { ReactComponent as GearsIcon } from "assets/icons/gears.svg";
import { CategoryFiltersComponent } from "component/filters";
import ShowMoreFilters from "containers/ShowMoreFilters";

class SearchQuick extends Component {
  constructor(props) {
    super(props);
    this.setSearchQuick = this._setSearchQuick.bind(this);
    this.onReachEnd = this._onReachEnd.bind(this);
    this.onShowMoreHandler = this._onShowMoreHandler.bind(this);
    this.fetchFilters = this._fetchFilters.bind(this);
    this.onFilterClickHandler = this._onFilterClickHandler.bind(this);
    this.onFilterClearHandler = this._onFilterClearHandler.bind(this);
    this.onApplyFilterClickHandler = this._onApplyFilterClickHandler.bind(this);
    this.loadList = this._loadList.bind(this);
    this.state = {
      searchQuickLinks: null,
      searchQuick: null,
      searchQuickIndex: 0,
      skeleton: [1, 2, 3, 4, 5, 6],
      activeFilter: null,
    };
  }

  _fetchFilters(route, filters) {
    let params = new URLSearchParams();
    if (filters) {
      filters.forEach((filter) => {
        //if(filter.get('id') !== "providers") {
        filter.get("values").forEach((value) => {
          if (value.get("toggled") === true) {
            params.append(filter.get("id"), value.get("id"));
          }
        });
        //}
      });
    }
    this.props.fetchFilters(route, params);
  }

  _onFilterClickHandler(subCategory, value) {
    this.props.setActiveFilter(this.props.filtersLink, subCategory, value);
  }

  _onFilterClearHandler(subCategory) {
    this.props.clearFilterByCategory(
      this.props.filtersLink.get("rel"),
      subCategory
    );
  }

  _onApplyFilterClickHandler() {
    this.props.showFilters(!this.props.filtersVisible);
  }

  _onShowMoreHandler(rel, route) {
    let params = {
      showMore: rel,
      route: btoa(encodeURIComponent(JSON.stringify(route.toJSON()))),
    };
    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;
    this.props.navigateTo(newLoc);
  }

  _setSearchQuick(value) {
    let search = queryString.parse(this.props.location.search);
    search.searchQuick = value;
    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      search
    )}`;
    this.props.navigateTo(newLoc);
  }

  _onReachEndX() {
    let searchQuick = this.state.searchQuick;
    let currentSearchQuick = searchQuick.get(this.props.searchQuick);

    let { totalPages, page, items, isLoading } = currentSearchQuick.toJSON();

    if (page < totalPages && !isLoading) {
      searchQuick = searchQuick.setIn(
        [this.props.searchQuick, "isLoading"],
        true
      );
      this.setState({
        searchQuick,
      });

      let nextPage = page + 1;
      let params = {
        size: 20,
        page: nextPage,
      };

      this.props
        .fetchSearchQuick(this.props.quickLink, params)
        .then((resp) => {
          resp.items = items.concat(resp.items);
          searchQuick = searchQuick.mergeIn([this.props.searchQuick], resp);
          searchQuick = searchQuick.setIn(
            [this.props.searchQuick, "isLoading"],
            false
          );
          this.setState({
            searchQuick,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  _onReachEnd() {
    if (!this.props.games) return;

    let { totalPages, page } = this.props.games.toJSON();

    if (page < totalPages && !this.props.isLoading) {
      let nextPage = page + 1;
      let params = new URLSearchParams();
      params.append("size", 20);
      params.append("page", nextPage);

      if (this.props.sortBy) {
        params.append("sortBy", this.props.sortBy);
      }

      if (this.props.filters) {
        this.props.filters.forEach((filter) => {
          filter.get("values").forEach((value) => {
            if (value.get("toggled") === true) {
              params.append(filter.get("id"), value.get("id"));
            }
          });
        });
      }

      this.props.fetchGames(this.props.route, params);
    }
  }

  getRandomColors() {
    let hue = Math.floor(360 * Math.random());
    let saturation = Math.floor(60 + 80 * Math.random());
    let lightness = Math.floor(15 + 30 * Math.random());

    let cssHSL = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    let cssHSLDark = `hsl(${hue}, ${saturation + 15}%, ${lightness + 15}%)`;

    return [cssHSL, cssHSLDark];
  }

  setGAparameters() {
    let search = queryString.parse(this.props.location.search);
    if (search.searchQuick) {
      ReactGA.event({
        category: "Quick Search",
        action: `quicksearch.${search.searchQuick.split(".")[1]}`,
        label: search.searchQuick,
      });
    }
  }

  _loadList(route, filters, sortBy, page) {
    if (!route) return;

    let params = new URLSearchParams();
    params.append("size", 20);
    params.append("page", page ? page : 1);

    if (sortBy) {
      params.append("sortBy", sortBy);
    }

    if (filters) {
      filters.forEach((filter) => {
        filter.get("values").forEach((value) => {
          if (value.get("toggled") === true) {
            params.append(filter.get("id"), value.get("id"));
          }
        });
      });
    }
    this.props.fetchGames(route, params);
  }

  componentWillReceiveProps(nextProps) {
    if (
      !is(nextProps.filtersLink, this.props.filtersLink) &&
      nextProps.filtersLink
    ) {
      this.fetchFilters(nextProps.filtersLink);
    }

    if (this.props.q !== nextProps.q && nextProps.q === "") {
      if (this.props.searchQuick === undefined && this.state.searchQuickLinks) {
        let search = queryString.parse(nextProps.location.search);
        search.searchQuick = this.state.searchQuickLinks.getIn([
          this.state.searchQuickIndex,
          "rel",
        ]);
        let newLoc = `${this.props.location.pathname}?${queryString.stringify(
          search
        )}`;
        this.props.navigateTo(newLoc);
      }
    }

    if (
      this.props.searchQuick !== nextProps.searchQuick &&
      nextProps.searchQuick
    ) {
      let searchQuickIndex = this.state.searchQuickLinks.findIndex((link) => {
        return link.get("rel") === nextProps.searchQuick;
      });
      this.setState({
        searchQuickIndex,
      });
    }

    if (
      this.props.searchQuickLinks !== nextProps.searchQuickLinks &&
      nextProps.searchQuickLinks
    ) {
      return this.props
        .fetchSearchQuickLinks(nextProps.searchQuickLinks)
        .then((resp) => {
          let searchQuickLinks = fromJS(resp);
          this.setState({
            searchQuickLinks,
          });
          this.props.setSearchQuickLinks(searchQuickLinks);
          return searchQuickLinks;
        })
        .catch((err) => {
          console.error(err);
        });
    }

    if (
      this.props.route !== nextProps.route &&
      nextProps.route &&
      !nextProps.filtersLink
    ) {
      this.loadList(nextProps.route, nextProps.filters, nextProps.sortBy, 1);
    }

    if (!is(nextProps.sortBy, this.props.sortBy) && nextProps.sortBy) {
      this.loadList(this.props.route, nextProps.filters, nextProps.sortBy, 1);
    }

    if (
      nextProps.filtersIsLoading !== this.props.filtersIsLoading &&
      nextProps.filtersIsLoading === false
    ) {
      this.loadList(this.props.route, nextProps.filters, nextProps.sortBy, 1);
    }
  }

  render() {
    if (this.props.q !== "") {
      return null;
    }

    return (
      <Fragment>
        <div className={`search-quick ${this.props.show ? "" : "d-none"}`}>
          <div className="row justify-content-center quick-links">
            <div className="col-auto">
              {this.state.searchQuickLinks &&
                this.state.searchQuickLinks.entrySeq().map(([key, link]) => {
                  let selected =
                    (!this.props.searchQuick && key === 0) ||
                    link.get("rel") === this.props.searchQuick;
                  let Icon;
                  switch (link.get("rel")) {
                    case "search-quick-types.themes":
                    case "searchQuickTypes.themes":
                      Icon = PantoneIcon;
                      break;
                    case "search-quick-types.providers":
                    case "searchQuickTypes.providers":
                      Icon = GearsIcon;
                      break;
                    case "search-quick-types.special-features":
                    case "searchQuickTypes.specialFeatures":
                      Icon = PuzzlePieceIcon;
                      break;
                    default:
                      Icon = null;
                      break;
                  }

                  return (
                    <span
                      key={key}
                      className={`btn ${
                        selected ? "btn-danger" : "btn-secondary"
                      } ${this.state.isLoading ? "disabled" : ""}  btn-sm `}
                      onClick={() => {
                        if (!this.state.isLoading) {
                          this.setSearchQuick(link.get("rel"));
                        }
                      }}
                    >
                      {Icon && (
                        <i>
                          <Icon />
                        </i>
                      )}
                      {link.get("name")}
                    </span>
                  );
                })}
              {this.props.searchQuickLinks && !this.state.searchQuickLinks && (
                <Fragment>
                  <span className={`btn btn-secondary btn-sm disabled dummy`} />
                  <span className={`btn btn-secondary btn-sm disabled dummy`} />
                  <span className={`btn btn-secondary btn-sm disabled dummy`} />
                </Fragment>
              )}
            </div>
          </div>
          {this.props.filters && (
            <CategoryFiltersComponent
              filters={this.props.filters}
              filterRoute={this.props.filtersLink}
              onApplyFilterClickHandler={(filter) => {
                this.onApplyFilterClickHandler();
                this.setState({
                  activeFilter: filter.get("id"),
                });
              }}
              onSortClick={() => {
                this.onApplyFilterClickHandler();
                this.setState({
                  activeFilter: "sortBy",
                });
              }}
              onFilterClickHandler={this.props.setActiveFilter}
              showSelectedFilter={false}
              games={this.props.games}
              link={this.props.quickLink}
              sortBy={this.props.sortBy}
              enableClearAll={this.props.enableClearAll}
              clearAllFilters={this.props.clearAllFilters}
              isMobile={this.props.isMobile}
              sortingSize={this.props.sortingSize}
            />
          )}
          <VericalList onReachEnd={this.onReachEnd}>
            {this.props.games &&
              this.props.games.get("items").map((game, key) => {
                let route = game.get("links").find((link) => {
                  return link;
                });
                if (
                  this.props.route.get("rel") ===
                    "search-quick-types.providers" ||
                  this.props.route.get("rel") === "searchQuickTypes.providers"
                ) {
                  return (
                    <div className="col" key={key}>
                      <ProviderTypeListItem
                        game={game}
                        onClick={(rel) => {
                          this.onShowMoreHandler(route.get("rel"), route);
                          this.setGAparameters();
                        }}
                        imageFormat={this.props.imageFormat}
                      />
                    </div>
                  );
                } else {
                  let rnd = this.getRandomColors();
                  let rGame = game.set("randomColors", rnd);
                  return (
                    <div className="col" key={key}>
                      <NoImageTypeListItem
                        isMobile={this.props.isMobile}
                        game={rGame}
                        onClick={(rel) => {
                          this.onShowMoreHandler(route.get("rel"), route);
                          this.setGAparameters();
                        }}
                      />
                    </div>
                  );
                }
              })}
            {!this.props.games ||
              (this.props.games &&
                this.props.games.get("page") <
                  this.props.games.get("totalPages") && (
                  <Fragment>
                    {this.state.skeleton.map((key2) => {
                      if (
                        this.props.route.get("rel") ===
                          "search-quick-types.providers" ||
                        this.props.route.get("rel") ===
                          "searchQuickTypes.providers"
                      ) {
                        return (
                          <div className={`col`} key={key2}>
                            <ProviderTypeSkeleton />
                          </div>
                        );
                      } else {
                        return (
                          <div className="col">
                            <NoImageTypeListItem />
                          </div>
                        );
                      }
                    })}
                  </Fragment>
                ))}
          </VericalList>
        </div>
        <ShowMoreFilters
          activeFilter={this.state.activeFilter}
          filters={this.props.filters}
          filtersLink={this.props.filtersLink}
          sorts={this.props.sorts}
          games={this.props.games}
          onClose={() => {
            this.setState({
              activeFilter: null,
            });
          }}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { q, searchQuick } = queryString.parse(ownProps.location.search);
  let isMobile = state.window.get("isMobile");
  let searchQuickLinks = state.links.get("searchQuickLinks");
  let quickLinks = state.search.get("quickLinks");
  let quickLink;
  let filtersLink;
  let filters;
  let filtersIsLoading;
  let sortBy;
  let sorts;
  let sortingSize = 0;
  let games;
  let isLoading;
  let isLoadingNext;
  let tournamentPreview;
  let route;
  let enabledFilters;
  let enableClearAll;

  try {
    games = state.games.getIn([searchQuick, "data"]);
    isLoading = state.games.getIn([searchQuick, "isLoading"]);
    tournamentPreview = state.tournaments.getIn([searchQuick]);
  } catch (err) {}

  try {
    isLoadingNext = state.games.getIn([searchQuick, "isLoadingNext"]);
  } catch (err) {}

  try {
    quickLink =
      searchQuick !== undefined
        ? quickLinks.find((link) => {
            return link.get("rel") === searchQuick;
          })
        : quickLinks.get(0);
  } catch (err) {}

  try {
    route = quickLinks.find((link) => {
      return link.get("rel").indexOf(searchQuick) > -1;
    });
  } catch (err) {}

  try {
    filtersLink = route.get("links").find((link) => {
      return link.get("rel").indexOf("filters") > -1;
    });
  } catch (err) {}
  try {
    enabledFilters = state.filters.getIn([
      filtersLink.get("rel"),
      "data",
      "filters",
    ]);
  } catch (err) {}
  try {
    sortBy = state.filters.getIn([filtersLink.get("rel"), "data", "sortBy"]);
  } catch (err) {}

  try {
    sorts = state.filters.getIn([filtersLink.get("rel"), "data", "sorts"]);
  } catch (err) {}

  try {
    filters = state.filters.getIn([filtersLink.get("rel"), "data", "filters"]);
  } catch (err) {}

  try {
    let toggledSize = 0;
    for (var i = 0; filters.size > i; i++) {
      toggledSize = filters.getIn([i, "toggledCount"]);
      sortingSize = sortingSize + toggledSize;
    }
  } catch (err) {}

  try {
    filtersIsLoading = state.filters.getIn([
      filtersLink.get("rel"),
      "isLoading",
    ]);
  } catch (err) {}

  try {
    enableClearAll = state.filters.getIn([
      filtersLink.get("rel"),
      "enableClearAll",
    ]);
  } catch (err) {}

  return {
    q,
    isMobile,
    searchQuick,
    searchQuickLinks,
    route,
    games,
    isLoading,
    isLoadingNext,
    tournamentPreview,
    sortBy,
    sorts,
    sortingSize,
    filtersLink,
    filters,
    filtersIsLoading,
    enabledFilters,
    enableClearAll,
    quickLink,
    imageFormat: state.window.get("imageFormat"),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    navigateTo: (path) => ownProps.history.push(path),
    fetchGames: (route, params) => dispatchProps.fetchGames(route, params),
    fetchFilters: (route, params) => dispatchProps.fetchFilters(route, params),
    fetchSearchQuickLinks: (route) =>
      dispatchProps.fetchSearchQuickLinks(route),
    setSearchQuickLinks: (data) => dispatchProps.setSearchQuickLinks(data),
    showFilters: (bol) => dispatchProps.showFilters(bol),
    fetchSearchQuick: (route, params) =>
      dispatchProps.fetchSearchQuick(route, params),
    setSearchQuick: (data) => dispatchProps.setSearchQuick(data),
    setActiveFilter: (category, subCategory, filter) =>
      dispatchProps.setActiveFilter(category, subCategory, filter),
    clearAllFilters: (category) => dispatchProps.clearAllFilters(category),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(SearchQuick);
