import React, { Component, Fragment, createRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreator } from "actions";
import { fromJS } from "immutable";
import * as queryString from "query-string";
import { GameAction } from "component/modal";
import translations from "translations";
import { isEmpty } from "lodash";
import { LoadingAnimation } from "component/animation";
import { CategoryType8Skeleton } from "component/listItemSkeleton";
import { CommonHeader } from "component/common";

import "containers/CategoryList/styles.scss";
import { setDefaultImageFormat } from "utils/image";

const ACTION_MODAL = "ACTION_MODAL";
class CategoryList extends Component {
  constructor(props) {
    super(props);
    this.onGameItemClickHandler = this._onGameItemClickHandler.bind(this);
    this.launchActions = this._launchActions.bind(this);
    this.onRowScrollHandler = this._onRowScrollHandler.bind(this);
    this.getGames = this._getGames.bind(this);
    this.getTournamentPreview = this._getTournamentPreview.bind(this);
    this.categoryListRef = createRef();
    this.initLoadDelay = null;
    this.onShowMoreHandler = this._onShowMoreHandler.bind(this);
    this.onShowTournamentDetailsHandler = this._onShowTournamentDetailsHandler.bind(
      this
    );
    this.onShowTournamentResultHandler = this._onShowTournamentResultHandler.bind(
      this
    );
    this.onShowJackpotDetailsHandler = this._onShowJackpotDetailsHandler.bind(
      this
    );
    //this.checkReachEnd = this._checkReachEnd.bind(this);
    this.state = {
      initLoadList: true,
      isLoading: true,
      selectedGame: null,
      realUrl: null,
      demoUrl: null,
      detailsLink: null,
      detailsAvailable: false,
      startsIn: null,
      endsIn: null,
      skeleton: [1, 2, 3],
    };
  }

  _onRowScrollHandler(event) {
    let { page } = this.props.games.toJSON();

    if (
      (this.props.maxCount && this.props.maxCount === this.props.items.size) ||
      (this.props.games &&
        this.props.games.get("totalPages") === this.props.games.get("page"))
    ) {
      return;
    }

    let positionToload =
      event.target.scrollWidth - event.target.offsetWidth * 2;
    let scrollLeft = event.target.scrollLeft;

    if (
      scrollLeft > positionToload &&
      !this.props.isLoading &&
      !this.props.isLoadingNext
    ) {
      let nextPage = page + 1;
      let params = {
        size: 10,
        page: nextPage,
      };
      this.setState({
        isLoadingNext: true,
      });
      this.props
        .fetchGames(this.props.link, params)
        .then(() => {
          this.setState({
            isLoadingNext: false,
          });
        })
        .catch((err) => {
          this.setState({
            isLoadingNext: false,
          });
        });
    }
  }

  componentDidMount() {
    if (this.props.link.get("rel") === this.props.showMore) {
      this.getGames(this.props.link);
    }
    this.getTournamentPreview(this.props.links);
    this.initObeserver();
  }

  initObeserver() {
    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      let observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (this.categoryListRef.current && !this.props.games) {
                this.initLoadDelay = setTimeout(() => {
                  this.getGames(this.props.link);
                  this.getTournamentPreview(this.props.links);
                }, 500);
              }
            } else {
              if (this.categoryListRef.current) {
                clearInterval(this.initLoadDelay);
              }
            }
          });
        },
        {
          rootMargin: "100px 0px 150px 0px",
        }
      );
      observer.observe(this.categoryListRef.current);
    } else {
      this.getGames(this.props.link);
      this.getTournamentPreview(this.props.links);
    }
  }

  _getTournamentPreview(links) {
    if (!links) return;

    let link = links.find((link) => {
      return link.get("rel").indexOf("tournament.preview") > -1;
    });
    if (link)
      this.props.fetchTournamentPreview(link, {
        parentRel: this.props.link.get("rel"),
      });
  }

  _getGames(link) {
    if (link) {
      let params = {
        size: 10,
      };

      this.setState({
        isLoading: true,
      });
      this.props
        .fetchGames(link, params)
        .then(() => {
          this.setState({
            isLoading: false,
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
        });
    }
  }

  _onShowTournamentDetailsHandler(rel, links, images, providers, tab) {
    let params = {
      showTournamentDetails: rel,
      routes: btoa(encodeURIComponent(JSON.stringify(links.toJSON()))),
      tab: tab,
    };

    if (images) {
      params.images = btoa(encodeURIComponent(JSON.stringify(images.toJSON())));
    }

    if (providers) {
      params.providers = btoa(
        encodeURIComponent(JSON.stringify(providers.toJSON()))
      );
    }

    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;
    this.props.navigateTo(newLoc);
  }

  _onShowTournamentResultHandler(rel, route, images, providers) {
    let params = {
      showTournamentResults: rel,
      route: btoa(encodeURIComponent(JSON.stringify(route.toJSON()))),
    };

    if (images) {
      params.images = btoa(encodeURIComponent(JSON.stringify(images.toJSON())));
    }

    if (providers) {
      params.providers = btoa(JSON.stringify(providers.toJSON()));
    }

    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;
    this.props.navigateTo(newLoc);
  }

  _onShowJackpotDetailsHandler(rel, links, images, providers, isWinner) {
    let params = {
      showJackpotDetails: rel,
      routes: btoa(encodeURIComponent(JSON.stringify(links.toJSON()))),
      isWinnerView: isWinner,
    };

    if (images) {
      params.images = btoa(JSON.stringify(images.toJSON()));
    }

    if (providers) {
      params.providers = btoa(JSON.stringify(providers.toJSON()));
    }

    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;
    this.props.navigateTo(newLoc);
  }

  _onShowMoreHandler(
    rel,
    route,
    images,
    providers,
    status,
    subtitle,
    highestJpPoolAmount
  ) {
    if (
      rel !== "jackpots" &&
      rel !== "tournaments" &&
      rel !== "new-providers" &&
      rel !== "popular.providers"
    ) {
      route = route.setIn(["rel"], `show.more.${route.getIn(["rel"])}`);
      rel = `${route.getIn(["rel"])}`;
    }
    let params = {
      showMore: rel,
      route: btoa(encodeURIComponent(JSON.stringify(route.toJSON()))),
      status: status,
      JpSubtitle: subtitle,
      JpAmount: highestJpPoolAmount,
    };

    if (images) {
      params.images = btoa(encodeURIComponent(JSON.stringify(images.toJSON())));
    }

    if (providers) {
      params.imaprovidersges = btoa(
        encodeURIComponent(JSON.stringify(providers.toJSON()))
      );
    }

    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;
    this.props.navigateTo(newLoc);
  }

  componentWillUnmount() {
    this.props.clearGames(this.props.link);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.match.isExact &&
      nextProps.match.isExact !== this.props.match.isExact
    ) {
      this.getGames(nextProps.link);
    }
  }

  _launchActions(game) {
    let {
      locale,
      sessionId,
      page,
      category,
      subCategory,
    } = this.props.match.params;
    let realLink;
    let demoLink;
    let detailsLink;
    let params;
    let categoryPath = this.props.match.path.split("/")[3];

    if (category) {
      params = {
        returnUrl: `${window.location.protocol}//${window.location.host}/${[
          locale,
          sessionId,
          categoryPath,
          page,
          category,
          subCategory,
        ].join("/")}`,
      };
    } else {
      params = {
        returnUrl: `${window.location.protocol}//${window.location.host}/${[
          locale,
          sessionId,
          categoryPath,
          page,
          subCategory,
        ].join("/")}`,
      };
    }

    this.setState({
      realUrl: null,
      demoUrl: null,
    });

    try {
      detailsLink = game.get("links").find((link) => {
        return link.get("rel") === "launch.real";
      });
      this.setState({
        detailsLink: detailsLink,
      });
    } catch (err) {}

    try {
      realLink = game.get("links").find((link) => {
        return link.get("rel") === "launch.real";
      });
      this.props.fetchGameUrl(realLink, params).then((response) => {
        let realUrl = response.payload.data.url;
        this.setState({
          realUrl: realUrl,
        });
      });
    } catch (err) {}

    try {
      demoLink = game.get("links").find((link) => {
        return link.get("rel") === "launch.demo";
      });
      this.props.fetchGameUrl(demoLink, params).then((response) => {
        let demoUrl = response.payload.data.url;
        this.setState({
          demoUrl: demoUrl,
        });
      });
    } catch (err) {}
    this.props.showModal(ACTION_MODAL);
    this.setState({
      selectedGame: game,
    });
  }

  _onGameItemClickHandler(game, rel) {
    if (this.props.isMultiGame && !this.props.isMobile) {
      let games = fromJS([]);
      let gameItem = fromJS({
        gameId: game.get("gameId"),
        playType: rel,
        nav: this.props.link ? this.props.link.get("rel") : null,
      });
      games = games.push(gameItem);
      games = games.push(null); // for multi game support
      games = games.push(null); // for multi game support
      games = games.push(null); // for multi game support
      let gamesEnc = btoa(encodeURIComponent(JSON.stringify(games.toJSON())));
      let params = {
        games: gamesEnc,
      };
      let newLoc = `${this.props.location.pathname}?${queryString.stringify(
        params
      )}`;

      this.props.navigateTo(newLoc);
      return;
    }

    let params = {
      playType: rel,
      nav: this.props.link ? this.props.link.get("rel") : null,
      gameId: game.get("gameId"),
      isMobile: this.props.isMobile,
    };
    this.props.setGameAction(params);
  }

  renderGameListItem(game, key) {
    let ComponentListItem = this.props.listItemComponent;
    let linkDemo = game.get("links").find((link) => {
      return link.get("rel") === "launch.demo";
    });
    let linkReal = game.get("links").find((link) => {
      return link.get("rel") === "launch.real";
    });
    let linkDetails = game.get("links").find((link) => {
      return link.get("rel") === "game.details";
    });

    return (
      <ComponentListItem
        key={key}
        game={game}
        linkDemo={linkDemo}
        linkReal={linkReal}
        linkDetails={linkDetails}
        host={this.props.host}
        isMobile={this.props.isMobile}
        onClick={(rel) => {
          this.onGameItemClickHandler(game, rel);
        }}
        onShowActionClick={() => {
          this.launchActions(game);
        }}
        currency={this.props.currency}
        symbol={this.props.symbol}
        imageFormat={this.props.imageFormat}
      />
    );
  }

  renderProviderListItem(item, key) {
    let ComponentListItem = this.props.listItemComponent;
    //TODO: add checking
    let categoryLink = item.getIn(["links", 0]);
    categoryLink = categoryLink.setIn(
      ["rel"],
      `show.more.${categoryLink.get("rel")}`
    );

    return (
      <ComponentListItem
        key={key}
        game={item}
        isMobile={this.props.isMobile}
        onClick={(rel) => {
          this.onShowMoreHandler(categoryLink.get("rel"), categoryLink);
        }}
        imageFormat={this.props.imageFormat}
      />
    );
  }

  renderJackpotListItem(item, key) {
    let ComponentListItem = this.props.listItemComponent;
    let detailsLink = item.get("links").find((link) => {
      return link.get("rel").indexOf("jackpot-details") > -1;
    });
    let gamesLink = item.get("links").find((link) => {
      return link.get("rel").indexOf("jackpot-games") > -1;
    });
    let images = item.get("images");
    let providers = item.get("providers");

    return (
      <ComponentListItem
        key={key}
        game={item}
        isMobile={this.props.isMobile}
        onClick={(rel) => {
          this.onShowJackpotDetailsHandler(
            detailsLink.get("rel"),
            item.get("links"),
            images,
            providers,
            true
          );
        }}
        onViewWinnersClick={() => {
          this.onShowJackpotDetailsHandler(
            detailsLink.get("rel"),
            item.get("links"),
            images,
            providers,
            true
          );
        }}
        onViewGamesClick={() => {
          gamesLink = gamesLink.setIn(["name"], item.get("title"));
          this.onShowJackpotDetailsHandler(
            detailsLink.get("rel"),
            item.get("links"),
            images,
            providers,
            false
          );
        }}
        currency={this.props.currency}
        symbol={this.props.symbol}
        fetchGameTypes={this.props.fetchGameTypes}
        imageFormat={this.props.imageFormat}
      />
    );
  }

  renderTournamentListItem(item, key) {
    let ComponentListItem = this.props.listItemComponent;
    let detailsLink = item.get("links").find((link) => {
      return link.get("rel").indexOf("tournament-promotions") > -1;
    });
    let resultsLink = item.get("links").find((link) => {
      return link.get("rel").indexOf("tournament-leaderboard") > -1;
    });
    let images = item.get("images");
    let providers = item.get("providers");

    return (
      <ComponentListItem
        key={key}
        game={item}
        host={this.props.host}
        isMobile={this.props.isMobile}
        onClick={() => {
          let links = item.get("links");
          let tab;
          links.forEach((link, key) => {
            links = links.setIn([key, "name"], item.get("title"));
          });
          resultsLink ? (tab = "results") : (tab = "games");
          this.onShowTournamentDetailsHandler(
            detailsLink.get("rel"),
            links,
            images,
            providers,
            tab
          );
        }}
        onViewGamesClick={() => {
          let links = item.get("links");
          links.forEach((link, key) => {
            links = links.setIn([key, "name"], item.get("title"));
          });
          this.onShowTournamentDetailsHandler(
            detailsLink.get("rel"),
            links,
            images,
            providers,
            "games"
          );
        }}
        onViewResultsClick={() => {
          let links = item.get("links");
          links.forEach((link, key) => {
            links = links.setIn([key, "name"], item.get("title"));
          });
          this.onShowTournamentDetailsHandler(
            detailsLink.get("rel"),
            links,
            images,
            providers,
            "results"
          );
        }}
        currency={this.props.currency}
        symbol={this.props.symbol}
        fetchGameTypes={this.props.fetchGameTypes}
        imageFormat={this.props.imageFormat}
      />
    );
  }

  renderListItem(game, key) {
    switch (this.props.link.get("rel")) {
      case "jackpots":
        return this.renderJackpotListItem(game, key);
      case "tournaments":
        return this.renderTournamentListItem(game, key);
      case "providers":
      case "new-providers":
      case "popular.providers":
        return this.renderProviderListItem(game, key);
      default:
        return this.renderGameListItem(game, key);
    }
  }

  renderPlaceHolder(template, key) {
    let placeholderType;
    switch (template.get("size")) {
      case "l":
        placeholderType = "placeholder-type-7";
        break;
      case "m":
        placeholderType = "placeholder-type-5";
        break;
      default:
        placeholderType = null;
        break;
    }
    return (
      <CategoryType8Skeleton key={key} placeholderType={placeholderType} />
    );
  }

  render() {
    const isPortrait = window.innerHeight > window.innerWidth;

    if (
      !this.state.isLoading &&
      (!this.props.items || this.props.items.size === 0)
    ) {
      return null;
    }

    return (
      <Fragment>
        <div
          className={`categoryList ${
            this.props.items &&
            this.props.items.size === 0 &&
            !this.props.showIfNoList
              ? ""
              : ""
          }`}
          ref={this.categoryListRef}
          onClick={(event) => {
            if (
              !this.state.isLoading &&
              this.props.games &&
              ((!isPortrait && this.props.games.get("totalCount") > 5) ||
                (isPortrait && this.props.games.get("totalCount") > 2))
            ) {
              //TODO: is this a valid condition
              event.stopPropagation();
              this.onShowMoreHandler(
                this.props.link.get("rel"),
                this.props.link
              );
            }
          }}
        >
          {this.state.selectedGame && (
            <GameAction
              isMobile={this.props.isMobile}
              game={this.state.selectedGame}
              sizeSuffix={this.props.sizeSuffix}
              imgSrc={`${setDefaultImageFormat(
                this.state.selectedGame.getIn(["images", "bannerUrl"]) +
                  "&width=180&theme=dark",
                this.props.imageFormat.get("banner")
              )}`}
              realUrl={this.state.realUrl}
              demoUrl={this.state.demoUrl}
              onClose={() => {
                this.props.hideModal(ACTION_MODAL);
                this.setState({
                  selectedGame: null,
                });
              }}
              onClick={(rel) => {
                this.props.hideModal(ACTION_MODAL);
                this.onGameItemClickHandler(this.state.selectedGame, rel);
                this.setState({
                  selectedGame: null,
                });
              }}
            />
          )}
          {this.state.isLoading && (
            <Fragment>
              <div className="placeholder-name">
                <div className="dummy" />
              </div>
              <div className="placeholder-ui">
                {this.state.skeleton.map((key) => {
                  return this.renderPlaceHolder(this.props.template, key);
                })}
              </div>
            </Fragment>
          )}
          {!this.state.isLoading &&
            this.props.games &&
            this.props.games.get("totalCount") > 0 && (
              <div>
                <CommonHeader
                  link={this.props.link}
                  tournamentPreview={this.props.tournamentPreview}
                  detailsAvailable={this.props.detailsAvailable}
                  onInfoClick={() => {
                    let params = {
                      tournamentDetails: this.props.link.get("rel"),
                    };
                    let newLoc = `${
                      this.props.location.pathname
                    }?${queryString.stringify(params)}`;
                    this.props.navigateTo(newLoc);
                  }}
                  rightPanel={
                    this.props.games.get("totalCount") > 2 ||
                    (this.props.link.get("rel") === "tournaments" &&
                      this.props.games.get("totalCount") > 1)
                      ? () => {
                          return (
                            <div className="d-flex align-items-center h-100">
                              <button
                                className="btn btn-dark"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  let moreLink = this.props.link;
                                  //moreLink = moreLink.setIn(['rel'], `show.more.${moreLink.get('rel')}`)
                                  this.onShowMoreHandler(
                                    moreLink.get("rel"),
                                    moreLink
                                  );
                                }}
                              >
                                {translations('showMore')}
                              </button>
                            </div>
                          );
                        }
                      : !isPortrait && this.props.games.get("totalCount") > 5
                      ? () => {
                          return (
                            <div className="d-flex align-items-center h-100">
                              <button
                                className="btn btn-dark"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  this.props.showSeeAllCategory(
                                    this.props.link.get("rel")
                                  );
                                }}
                              >
                                {translations('showMore')}
                              </button>
                            </div>
                          );
                        }
                      : ""
                  }
                />
                <div
                  className="row item-container"
                  onScroll={this.onRowScrollHandler}
                >
                  {this.props.items &&
                    this.props.items.map((game, key) => {
                      return (
                        <div
                          className={`col template-${this.props.template.get(
                            "size"
                          )}`}
                          key={key}
                        >
                          {this.renderListItem(game, key)}
                        </div>
                      );
                    })}
                  {this.state.isLoadingNext && (
                    <div
                      className={`col ${this.props.link
                        .get("rel")
                        .replace(".", "")}`}
                    >
                      <div className="row h-100 justify-content-center align-items-center nopadding loadingAnim">
                        <LoadingAnimation />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let games;
  let isLoading = true;
  let items = fromJS([]);
  let symbols = null;
  let currency = null;
  let detailsAvailable = null;
  let tournamentPreview = null;
  let { showMore } = queryString.parse(ownProps.location.search);
  let template;

  try {
    template = ownProps.link.get("templates").find((template) => {
      return template.get("id") === "myQT";
    });
  } catch (err) {
    template = fromJS({ size: "m" });
  }

  try {
    games = state.games.getIn([ownProps.link.get("rel"), "data"]);
    isLoading = state.games.getIn([ownProps.link.get("rel"), "isLoading"]);
    items = games.get("items");
    tournamentPreview = state.tournaments.get(ownProps.link.get("rel"));
    detailsAvailable = state.games.getIn([
      ownProps.link.get("rel"),
      "route",
      "links",
    ])
      ? true
      : false;
  } catch (err) {}
  if (!isEmpty(state.links.getIn(["symbol"]))) {
    let symbolList = state.links.getIn(["symbol"]);
    symbolList = symbolList.map((symbol) => {
      return String.fromCharCode(symbol);
    });
    symbols = symbolList.toJSON().join("");
  }

  if (!isEmpty(state.links.get("currency"))) {
    currency = state.links.get("currency");
  }

  return {
    gameDefaultAction: state.links.get("gameDefaultAction")
      ? state.links.get("gameDefaultAction")
      : null,
    detailsAvailable,
    host: state.config.get("host"),
    height: state.window.get("height"),
    gameAction: state.game,
    games: games,
    items: items,
    currency: currency,
    symbol: symbols,
    isLoading: isLoading,
    isMobile: state.window.get("isMobile"),
    //links: tempLinks,
    links: ownProps.link.get("links"),
    tournamentPreview: tournamentPreview,
    showMore,
    template,
    isMultiGame: state.links.get("isMultiGame"),
    imageFormat: state.window.get("imageFormat"),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    setGameAction: (params) => dispatchProps.setGameAction(params),
    fetchGames: (route, params) => dispatchProps.fetchGames(route, params),
    clearGames: (route) => dispatchProps.clearGames(route),
    fetchGameUrl: (link, params) => dispatchProps.fetchGameUrl(link, params),
    fetchTournamentPreview: (link, params) =>
      dispatchProps.fetchTournamentPreview(link, params),
    showModal: (modal) => dispatchProps.showModal(modal),
    hideModal: (modal) => dispatchProps.hideModal(modal),
    showSeeAllCategory: (rel) => dispatchProps.showSeeAllCategory(rel),
    hideSeeAllCategory: (rel) => dispatchProps.hideSeeAllCategory(rel),
    fetchGameTypes: (route) => dispatchProps.fetchGameTypes(route),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CategoryList);
