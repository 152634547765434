import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as queryString from "query-string";
import { bindActionCreators } from "redux";
import { ActionCreator } from "actions";
import { currencyFormatter } from "utils/currencyHelper";
import { HamburgerIcon } from "component/icons";
import { UserProfileIcon } from "component/icons";
import { LanguageMenu } from "component/menu";
import { ReactComponent as UnitedKingdomFlag } from "assets/flags/united-kingdom.svg";
import { ReactComponent as ChinaFlag } from "assets/flags/china.svg";
import { ReactComponent as JapanFlag } from "assets/flags/japan.svg";
import { ReactComponent as SpainFlag } from "assets/flags/spain.svg";
import { ReactComponent as FranceFlag } from "assets/flags/france.svg";
import { ReactComponent as RussiaFlag } from "assets/flags/russia.svg";
import { ReactComponent as BrazilFlag } from "assets/flags/brazil.svg";
import { ReactComponent as ChooseLanguageIcon } from "assets/icons/chooseLanguage.svg";
import { ReactComponent as SignOutIcon } from "assets/icons/sign-out-option.svg";
import { ReactComponent as HistoryIcon } from "assets/icons/viewHistory.svg";
import { ReactComponent as CashierIcon } from "assets/icons/depositwithdraw.svg";
import { ReactComponent as SwitchDevice } from "assets/icons/switchDevice.svg";
import { ReactComponent as ScreensIcon } from "assets/icons/screens-icon-04.svg";

import { PlayNow } from "component/icons";
import { isEmpty, isEqual } from "lodash";

import ExitDialogModal from "component/modal/ExitDialog";

import translations from "translations";
import "containers/Menu/styles.scss";

const LANGUAGE_PANEL = "LANGUAGE_PANEL";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.setMenuVisibility = this._setMenuVisibility.bind(this);
    this.setGameHistoryVisibility = this._setGameHistoryVisibility.bind(this);
    this.onPanelClose = this._onPanelClose.bind(this);
    this.onExitClick = this._onExitClick.bind(this);
    this.onBetHistoryClick = this._onBetHistoryClick.bind(this);
    this.state = {
      activePanel: null,
      showExitModal: false,
      language: null,
    };
  }

  componentDidMount() {
    let menu = this.props.getMenu();

    if (menu) {
      this.setState(menu.toJSON());
    } else {
      this.props.setMenu({
        expand: false,
        activePanel: null,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.recentlyPlayedGames &&
      !isEqual(this.props.recentlyPlayedGames, nextProps.recentlyPlayedGames)
    ) {
      this.props.wlFetchProfile();
    }

    if (
      !isEqual(
        this.props.supportedLanguagesLink,
        nextProps.supportedLanguagesLink
      ) &&
      !this.props.showMenu
    ) {
      this.props
        .fetchLanguage(nextProps.supportedLanguagesLink)
        .then((response) => {
          return this.props.setLanguage(response);
        })
        .then((response) => {
          this.setState({
            language: response,
          });
        })
        .catch((err) => {
          throw err;
        });
    }
  }

  _setGameHistoryVisibility = () => {
    this.props.setGameHistoryVisibility(!this.props.showGameHistory);
  };

  _onPanelClose() {
    this.setState({
      activePanel: null,
    });
    this.props.setMenu({
      activePanel: null,
    });
  }

  getFlag() {
    let { locale } = this.props.match.params;

    let ActiveFlag;

    switch (locale) {
      case "zh-CN":
      case "zh_CN":
        ActiveFlag = ChinaFlag;
        break;
      case "ja-JP":
      case "ja_JP":
        ActiveFlag = JapanFlag;
        break;
      case "es-ES":
      case "es_ES":
        ActiveFlag = SpainFlag;
        break;
      case "fr-FR":
      case "fr_FR":
        ActiveFlag = FranceFlag;
        break;
      case "ru-RU":
      case "ru_RU":
        ActiveFlag = RussiaFlag;
        break;
      case "pt-BR":
      case "pt_BR":
        ActiveFlag = BrazilFlag;
        break;
      default:
        ActiveFlag = UnitedKingdomFlag;
        break;
    }

    return <ActiveFlag />;
  }

  _setMenuVisibility() {
    let newShowMenu = !this.props.showMenu;
    this.props.setMenuVisibility(newShowMenu);
    this.props.setMenu({
      expand: newShowMenu,
      activePanel: this.state.activePanel,
    });

    if (!newShowMenu) {
      this.setState({
        activePanel: null,
      });
    }

    if (!this.props.isMobile) return;

    if (newShowMenu) {
      this.props.showModal("MENU");
    } else {
      this.props.hideModal("MENU");
    }
  }

  _onExitClick() {
    this.setState({
      showExitModal: !this.state.showExitModal,
    });
  }

  _onBetHistoryClick() {
    let params = {
      modalRel: this.props.gameRoundHistoryLink.get("rel"),
      link: JSON.stringify(this.props.gameRoundHistoryLink.toJSON()),
    };
    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;
    this.props.navigateTo(newLoc);
  }

  render() {
    const { showMenu, filtersVisible } = this.props;

    return (
      <Fragment>
        <div
          className={`menu-container ${showMenu ? "show" : ""}`}
          onClick={this.setMenuVisibility}
        />
        <div
          className={`menu ${this.props.isMobile ? "mobile" : "desktop"} ${
            showMenu ? "active" : "inactive"
          } ${filtersVisible ? "menu-filtervisible" : ""}`}
        >
          {this.props.visibleMenu &&
            (showMenu ? (
              <HamburgerIcon
                onClick={this.setMenuVisibility}
                active={showMenu}
                className={"icon-wrapper"}
              />
            ) : (
              <span />
            ))}
          {showMenu && (
            <div
              className={`content d-flex h-100 flex-column ${
                this.state.activePanel ? "disable" : ""
              }`}
              style={{
                height: `${this.props.height}px`,
              }}
            >
              {this.props.balance && !filtersVisible && (
                <span className={`menu-balance ${showMenu ? "withMenu" : ""}`}>
                  {currencyFormatter(this.props.balance, {
                    currency: this.props.currency,
                    symbol: this.props.symbol,
                  })}
                </span>
              )}
              <div className="row">
                <div className="col">
                  <span className="usericon">
                    <UserProfileIcon />
                  </span>
                  <span className="name">{this.props.displayName}</span>
                </div>
              </div>
              <div className="spacer" />
              <div className="row group">
                {this.props.showCashier && (
                  <div className="col-12">
                    <button
                      className="item btn"
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={this.props.broadcastCashier}
                    >
                      <span className="icon">
                        <CashierIcon />
                      </span>
                      <label>{translations("cashIn")}</label>
                    </button>
                  </div>
                )}
                {this.props.continuePlayingLink && (
                  <div className="col-12">
                    <button
                      className="item btn"
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => {
                        let params = {
                          showMore: this.props.continuePlayingLink.get("rel"),
                          route: btoa(
                            encodeURIComponent(
                              JSON.stringify(
                                this.props.continuePlayingLink.toJSON()
                              )
                            )
                          ),
                        };
                        let newLoc = `${
                          this.props.location.pathname
                        }?${queryString.stringify(params)}`;
                        this.props.navigateTo(newLoc);
                      }}
                    >
                      <span className="icon">
                        <PlayNow />
                      </span>
                      <label>
                        {this.props.continuePlayingLink.get("name")}
                      </label>
                    </button>
                  </div>
                )}
                {this.props.gameRoundHistoryLink && (
                  <div className="col-12">
                    <button
                      className="item btn"
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={this.onBetHistoryClick}
                    >
                      <span className="icon">
                        <HistoryIcon />
                      </span>
                      <label>
                        {this.props.gameRoundHistoryLink.get("name")}
                      </label>
                    </button>
                  </div>
                )}
              </div>
              <div className="spacer" />
              <div className="row group">
                {this.props.showLanguage && (
                  <div className="col-12">
                    <button
                      className="item btn"
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => {
                        this.setState({
                          activePanel: LANGUAGE_PANEL,
                        });
                        this.props.setMenu({
                          activePanel: LANGUAGE_PANEL,
                        });
                      }}
                    >
                      <span className="icon">
                        <ChooseLanguageIcon />
                      </span>
                      <label>
                        {this.props.supportedLanguagesLink.get("name")}
                      </label>
                      <span className="flag">{this.getFlag()}</span>
                    </button>
                  </div>
                )}
                {this.props.showSwitchDevice && (
                  <div className="col-12">
                    <button
                      className="item btn"
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={this.props.broadcastSwitchDevice}
                    >
                      <span className="icon icon-switch">
                        <SwitchDevice />
                      </span>
                      <label>{translations("switchDevice")}</label>
                    </button>
                  </div>
                )}
              </div>
              {this.props.allowMultiGamePlay && !this.props.isMobile && (
                <>
                  <div className="spacer" />
                  <div className="row group">
                    <div className="col-12">
                      <button
                        className="item btn w-100 d-flex"
                        type="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="icon">
                          <ScreensIcon />
                        </span>
                        <div className="custom-control custom-control-right custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customSwitch1"
                            onClick={(event) => {
                              this.props.setMultigameEnabled(
                                event.currentTarget.checked
                              );
                            }}
                            defaultChecked={
                              this.props.isMultiGame ? true : false
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customSwitch1"
                          >
                            {translations("multiGamePlay")}
                          </label>
                        </div>
                      </button>
                    </div>
                  </div>
                </>
              )}

              <div className="flex-fill d-flex justify-content-start">
                <div className="col" />
              </div>
              <div className="spacer" />
              <div className="row footer">
                {this.props.exitLink && (
                  <div className="col-12">
                    <button
                      className="sign-out btn text-center"
                      type="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => {
                        this.onExitClick();
                      }}
                    >
                      <span className="icon">
                        <SignOutIcon />
                      </span>
                      <label>{this.props.exitLink.get("name")}</label>
                    </button>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col">
                  <div className="version text-center">
                    {`${translations("version")}: ${
                      this.props.version
                    }, ${translations("build")}: ${this.props.buildno}`}
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.showExitModal ? (
            <ExitDialogModal
              onLeaveClickHandler={() => {
                this.props.fetchExit(this.props.exitLink).then((response) => {
                  window.parent.postMessage(
                    {
                      type: "LOBBY_EXITED",
                    },
                    "*"
                  );
                  this.setMenuVisibility();
                  if (!response.url) {
                    window.location.reload();
                  } else {
                    window.location.href = response.url;
                  }
                });
              }}
              onClose={() => {
                this.onExitClick();
              }}
              broadcastSwitchDevice={this.props.broadcastSwitchDevice}
            />
          ) : null}
          {this.props.supportedLanguagesLink && this.state.activePanel === LANGUAGE_PANEL && showMenu && (
            <LanguageMenu
              className="panel"
              show={this.state.activePanel === LANGUAGE_PANEL}
              locale={this.props.match.params.locale}
              isMobile={this.props.isMobile}
              setLocale={this.props.setLocale}
              header={this.props.supportedLanguagesLink.get("name")}
              onClose={() => {
                this.onPanelClose();
              }}
              supportedLanguagesLink={this.state.language}
            />
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let symbols;
  let showCashier;
  let showSwitchDevice;

  try {
    showCashier = state.profile.get("showCashier");
  } catch (err) {}
  try {
    showSwitchDevice = state.profile.get("showSwitchDevice");
  } catch (err) {}

  let continuePlayingLink = state.profile.get("links").find((link) => {
    return link.get("rel") === "categories-home.recentlyplayed";
  });
  let supportedLanguagesLink = state.profile.get("links").find((link) => {
    return link.get("rel") === "supportedLanguages";
  });
  let betHistoryLink = state.profile.get("links").find((link) => {
    return link.get("rel") === "service.url";
  });
  let gameRoundHistoryLink = state.profile.get("links").find((link) => {
    return link.get("rel") === "games-round-history";
  });
  let exitLink = state.profile.get("links").find((link) => {
    return link.get("rel") === "exit";
  });

  let recentlyPlayedGames = state.games.get("categories-home.recentlyplayed");

  if (!isEmpty(state.user.getIn(["symbol"]))) {
    let symbolList = state.user.getIn(["symbol"]);
    symbolList = symbolList.map((symbol) => {
      return String.fromCharCode(symbol);
    });
    symbols = symbolList.toJSON().join("");
  }

  let visibleMenu =
    !isEmpty(state.profile.get("displayName")) ||
    state.profile.get("showLanguage") ||
    exitLink ||
    betHistoryLink ||
    continuePlayingLink ||
    gameRoundHistoryLink ||
    supportedLanguagesLink ||
    showCashier ||
    showSwitchDevice;

  return {
    version: state.application.get("version"),
    buildno: state.application.get("buildno"),
    isMobile: state.window.get("isMobile"),
    height: state.window.get("height"),
    betHistoryLink: betHistoryLink ? betHistoryLink : null,
    gameRoundHistoryLink: gameRoundHistoryLink ? gameRoundHistoryLink : null,
    continuePlayingLink: continuePlayingLink ? continuePlayingLink : null,
    supportedLanguagesLink: supportedLanguagesLink
      ? supportedLanguagesLink
      : null,
    exitLink: exitLink ? exitLink : null,
    displayName: state.profile.get("displayName"),
    showLanguage: state.profile.get("showLanguage"),
    balance: state.profile.get("balance"),
    symbol: symbols,
    showMenu: state.application.get("showMenu"),
    showGameHistory: state.application.get("showGameHistory"),
    menu: state.cookie.get("menu"),
    visibleMenu: visibleMenu,
    filtersVisible: state.config.get("filtersVisible"),
    recentlyPlayedGames,
    showCashier,
    showSwitchDevice,
    allowMultiGamePlay: state.links.get("allowMultiGamePlay"),
    isMultiGame: state.links.get("isMultiGame"),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    clearBetHistory: () => dispatchProps.clearBetHistory(),
    setMenuVisibility: (value) => dispatchProps.setMenuVisibility(value),
    setGameHistoryVisibility: (value) =>
      dispatchProps.setGameHistoryVisibility(value),
    setLocale: (locale) => dispatchProps.setLocale(locale),
    showModal: (value) => dispatchProps.showModal(value),
    hideModal: (value) => dispatchProps.hideModal(value),
    showBetHistory: () => dispatchProps.showBetHistory(),
    setMenu: (value) => dispatchProps.setMenu(value),
    getMenu: () => dispatchProps.getMenu(),
    fetchExit: (route) => dispatchProps.fetchExit(route),
    fetchGames: (route, params) => dispatchProps.fetchGames(route, params),
    wlFetchProfile: () => dispatchProps.wlFetchProfile(),
    navigateTo: (path) => ownProps.history.push(path),
    broadcastCashier: () => dispatchProps.broadcastCashier(),
    broadcastSwitchDevice: () => dispatchProps.broadcastSwitchDevice(),
    setMultigameEnabled: (bol) => dispatchProps.setMultigameEnabled(bol),
    fetchLanguage: (route) => dispatchProps.fetchLanguage(route),
    setLanguage: (payload) => dispatchProps.setLanguage(payload),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Menu);
