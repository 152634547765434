import React, { Component, createRef, Fragment} from 'react';
import { connect } from 'react-redux';
import { ActionCreator } from 'actions';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash';
import * as queryString from 'query-string';
import { fromJS } from 'immutable';
import {
	currencyFormatter
} from 'utils/currencyHelper';
import { ReactComponent as PreviousIcon} from 'assets/icons/previous.svg';
import { ReactComponent as CloseIcon} from 'assets/icons/close.svg';
import { ReactComponent as SortArrowDown } from 'assets/icons/sort-arrow-down.svg';
import { ReactComponent as SortArrowUp } from 'assets/icons/sort-arrow-up.svg';
import translations from 'translations';

import 'containers/RoundHistoryPerGame/styles.scss';

class RoundHistoryPerGame extends Component {
    constructor(props) {
		super(props);
    this.gameHistoryRef = createRef();
		this.gameHistoryRoundsRef = createRef();
		this.onClickPrevious = this._onClickPrevious.bind(this);
		this.onGameClick = this._onGameClick.bind(this);
		this.checkIfEnd = this._checkIfEnd.bind( this );
		this.onSetSorting = this._onSetSorting.bind(this);
		this.onCloseClickHandler = this._onCloseClickHandler.bind(this);
		this.dummyArr = [0,1,2,3,4,5];
		this.state = {
			roundHistoryPerGame: null,
			isLoading: true,
			showTitle: false,
			sorting: {
				sortBy: "initiated", //"initiated, completed, status, totalBet, totalPayout"
				orderBy: "DESC" //"ASC or DESC"
			},
		};
  }

	loadGameRoundHistoryPerGame(link, params) {
		this.setState({
			isLoading: true
		})
		this.props.fetchGameRoundHistoryPerGame(link, params)
		.then(response => {
			return this.props.setGameRoundHistoryPerGame(response);
		})
		.then(response => {
			let roundHistoryPerGame = this.state.roundHistoryPerGame ? response.setIn(['items'], this.state.roundHistoryPerGame.getIn(['items']).concat(response.getIn(['items']))) : response;
			this.setState({
				roundHistoryPerGame: roundHistoryPerGame,
				isLoading: false
			})
			this.checkIfEnd();
		})
		.catch(err => {
			throw err
		})
	}

	componentWillReceiveProps( nextProps ) {
		if(!isEqual(nextProps.roundHistoryPerGameLink, this.props.roundHistoryPerGameLink) &&
			nextProps.roundHistoryPerGameLink) {
			this.setState({
				roundHistoryPerGame: null,
				isLoading: true
			})
			let params = {
				size: 30
			}
			this.loadGameRoundHistoryPerGame(nextProps.roundHistoryPerGameLink, params)
		}
	}

	_onClickPrevious() {
		this.props.goBack(`${this.props.location.pathname}${this.props.location.search}`)
		this.props.history.goBack();
	}

	_onGameClick(game) {
		let params = {
			modalRel: game.get('rel'),
			link: JSON.stringify(game.toJSON())
		}
		let newLoc = `${this.props.location.pathname}?${queryString.stringify(params)}`
  	this.props.navigateTo( newLoc )
	}

	_onCloseClickHandler() {
    this.props.navigateTo(this.props.location.pathname)
  }

	_onSetSorting(sortBy) {
		this.setState({
			roundHistoryPerGame: null,
			isLoading: true
		})

		let orderBy = "DESC"

		if(this.state.sorting.sortBy === sortBy) {
			orderBy = (this.state.sorting.orderBy === "DESC") ? "ASC" : "DESC";
		}

		let sorting = {
			sortBy,
			orderBy
		}

		this.setState({
			sorting,
		})

		let params = {
			size: 30,
			page: 1,
			sortBy,
			orderBy
		}
		this.loadGameRoundHistoryPerGame(this.props.roundHistoryPerGameLink, params)
	}

	_checkIfEnd() {
		const scrollDemo = document.querySelector(".round-history-per-game-modal");
		if(scrollDemo.scrollTop !== 0){
			this.setState({ showTitle: true })
		} else {
			this.setState({ showTitle: false })
		}

		if ( !this.state.isLoading &&
		this.state.roundHistoryPerGame &&
		this.state.roundHistoryPerGame.get( 'page' ) < this.state.roundHistoryPerGame.get( 'totalPages' ) ) {
		let {
			innerHeight
		} = window
		let {
			scrollHeight,
					scrollTop
				} = this.gameHistoryRef.current

		let scrollPosition = innerHeight + scrollTop;

		scrollPosition = scrollPosition + 150;

		if ( scrollPosition >= scrollHeight && !this.state.isLoading ) {
			let nextPage = this.state.roundHistoryPerGame.get('page') + 1;

			if (nextPage > this.state.roundHistoryPerGame.get('totalPages')) {
				return;
			}

			let params = {
				size: 30,
				page: nextPage,
				sortBy: this.state.sorting.sortBy,
				orderBy: this.state.sorting.orderBy
			}
			this.loadGameRoundHistoryPerGame(this.props.roundHistoryPerGameLink, params)
		}
    }

  }

	render() {

		if (!this.props.modalRel || (this.props.modalRel && this.props.modalRel.indexOf("round-history-per-game") < 0)) {
			return null
		}

		return <Fragment>
			<div ref={this.gameHistoryRef} className={`round-history-per-game-modal active`} onScroll={this.checkIfEnd} >
				<div className={`round-history-per-game-modal-header`}>
				<div className='row'>
					<div className="col-auto">
						<span className="float-left prev-btn"
							onClick={this.onClickPrevious}>
							<PreviousIcon width="34"/>
						</span>
					</div>
					<div className={`col details-actions ${this.state.showTitle ? 'fade-in' : ''}`} ref={this.actionLabel}>
						{
							this.state.roundHistoryPerGame && <span className={`action-name`}>
								<div>{ this.state.roundHistoryPerGame.get('title') }</div>
							</span>
						}
					</div>
					<div className="col-auto">
						<CloseIcon
							width="34"
							onClick={this.onCloseClickHandler}
						/>
					</div>
				</div>
				<div>
					<div ref={this.gameHistoryRoundsRef} className={`game-rounds-history`}>
					<div className={`round-history-per-game-modal-header`}>
					{
						!this.state.roundHistoryPerGame && <Fragment>
							<div className={`round-history-per-game-modal-header-title dummy`}><span>XXXXXXX XXXXXXXXX</span></div>
							<div className={`round-history-per-game-modal-header-sub-title dummy`}><span>XXXXXXXXX XXXXXX</span></div>
							<div className={`round-history-per-game-modal-header-sub-title-rounds dummy`}><span>XX XXXXXX</span></div>
						</Fragment>
					}
					{
						this.state.roundHistoryPerGame && <Fragment>
							<div className={`round-history-per-game-modal-header-title`}>{this.state.roundHistoryPerGame.get('title')}</div>
							<div className={`round-history-per-game-modal-header-sub-title`}>{this.state.roundHistoryPerGame.get('subtitle')}</div>
							<div className={`round-history-per-game-modal-header-sub-title-rounds`}>
								{ this.props.locale === "ja_JP" ?
									`${translations('rounds')} ${this.state.roundHistoryPerGame.get('totalCount')}` :
									`${this.state.roundHistoryPerGame.get('totalCount')} ${translations('rounds')}`
								}
							</div>
						</Fragment>
					}
					<div className={`round-history-per-game-modal-table`}>

						<table className="table">
							<thead>
								<tr className="titles">
									<th scope="col" onClick={() => {
											this.onSetSorting("initiated")
										}}>
										{translations('initiated')}
										{
										this.state.sorting && this.state.sorting.sortBy === "initiated" && <Fragment>
											{
												this.state.sorting.orderBy === "DESC" ? <SortArrowDown /> : <SortArrowUp />
											}
											</Fragment>
										}
									</th>
									<th scope="col" className="d-none-sm"onClick={() => {
											this.onSetSorting("completed")
										}}>
										{translations('completed')}
										{
										this.state.sorting && this.state.sorting.sortBy === "completed" && <Fragment>
											{
												this.state.sorting.orderBy === "DESC" ? <SortArrowDown /> : <SortArrowUp />
											}
											</Fragment>
										}
									</th>
									<th scope="col" className='status'onClick={() => {
											this.onSetSorting("status")
										}}>
										{translations('status')}
										{
										this.state.sorting && this.state.sorting.sortBy === "status" && <Fragment>
											{
												this.state.sorting.orderBy === "DESC" ? <SortArrowDown /> : <SortArrowUp />
											}
											</Fragment>
										}
									</th>
									<th scope="col" className="d-none-sm" onClick={() => {
											this.onSetSorting("type")
										}}>
										{translations('type')}
										{
										this.state.sorting && this.state.sorting.sortBy === "type" && <Fragment>
											{
												this.state.sorting.orderBy === "DESC" ? <SortArrowDown /> : <SortArrowUp />
											}
											</Fragment>
										}
									</th>
									<th scope="col sort" className="text-right align-middle" onClick={() => {
											this.onSetSorting("totalBet")
										}}>
										{translations('totalBet')}
										{
										this.state.sorting && this.state.sorting.sortBy === "totalBet" && <Fragment>
											{
												this.state.sorting.orderBy === "DESC" ? <SortArrowDown /> : <SortArrowUp />
											}
											</Fragment>
										}
									</th>
									<th scope="col" className="text-right align-middle" onClick={() => {
											this.onSetSorting("totalPayout")
										}}>
										{translations('totalPayout')}
										{
										this.state.sorting && this.state.sorting.sortBy === "totalPayout" && <Fragment>
											{
												this.state.sorting.orderBy === "DESC" ? <SortArrowDown /> : <SortArrowUp />
											}
											</Fragment>
										}
									</th>
								</tr>
							</thead>
							{
								this.state.roundHistoryPerGame && <tbody>
									{
										this.state.roundHistoryPerGame.get('items').map((item, key) => {
											let link = item.get('links').find(link => {return link.get('rel').indexOf("game-rounds") > -1})

											return <tr key={key} onClick={() => {
													this.onGameClick(link)
													}}>
												<td className="game">
													<span className="name">{item.get("initiatedDate")}</span>
												</td>
												<td className="d-none-sm  align-middle">{item.get("completedDate")}</td>
												<td className='status align-middle'><span>{item.get("status")}</span></td>
												<td className="d-none-sm  align-middle">{item.get("type")}</td>
												<td className="text-right align-middle">{currencyFormatter(item.get("totalBet"), { currency : null })}</td>
												<td className="text-right align-middle">{currencyFormatter(item.get("totalPayout"), { currency : null })}</td>
											</tr>
										})
									}
								</tbody>
							}
							{
							(!this.state.roundHistoryPerGame || this.state.isLoading) && <tbody>
									{
										this.dummyArr.map((dummy, key) => {
											return <tr key={key} className='dummy'>
												<td className="game text-center">
													<span className="name">XXX X XXXX XX XX XX</span>
												</td>
												<td className="d-none-sm"><span>XXX X XXXX XX XX XX</span></td>
												<td className='status'><span>XXXXXXXXX</span></td>
												<td className="d-none-sm"><span>XXXXXX</span></td>
												<td className='text-right'><span>XXX.XX</span></td>
												<td className='text-right'><span>XXX.XX</span></td>
											</tr>
										})
									}
								</tbody>
							}
							</table>
							</div>
						</div>
					</div>
				</div>
				</div>
			</div>
		</Fragment>
	}
}

const mapStateToProps = (state, ownProps) => {
	let headerAuthorization = state.config.getIn(['headers', 'Authorization']);
	let { modalRel, link } = queryString.parse(ownProps.location.search);

	if(!headerAuthorization || !modalRel || (modalRel && modalRel.indexOf("round-history-per-game") < 0)) {
		return {}
	}

	let roundHistoryPerGameLink = link ? fromJS(JSON.parse(link)) : undefined;
	return {
		modalRel,
		roundHistoryPerGameLink,
		locale: state.config.getIn( [ 'locale' ] )
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(ActionCreator, dispatch);
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return Object.assign({}, stateProps, ownProps, {
			setGameHistoryVisibility: (value) => dispatchProps.setGameHistoryVisibility(value),
			navigateTo: ( path ) => ownProps.history.push( path ),
			showModal: (modal) => dispatchProps.showModal(modal),
			hideModal: (modal) => dispatchProps.hideModal(modal),
			fetchGameRoundHistoryPerGame: (route, params) => dispatchProps.fetchGameRoundHistoryPerGame(route, params),
			setGameRoundHistoryPerGame: (payload) => dispatchProps.setGameRoundHistoryPerGame(payload),
			goBack: url => dispatchProps.goBack(url)
    })
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(RoundHistoryPerGame);
