import * as LocalStorageAction from 'actions/localStorage';
import * as LoginAction from 'actions/login';
import * as UrlsAction from 'actions/urls';
import * as SecurityAction from 'actions/security';
import * as PagesAction from 'actions/pages';
import * as CategoryAction from 'actions/category';
import * as GameAction from 'actions/game';
import * as WindowAction from 'actions/window';
import * as ApplicationAction from 'actions/application';
import * as ConfigAction from 'actions/links';
import * as UserAction from 'actions/user';
import * as BetHistoryAction from 'actions/betHistory';
import * as UpdateAction from 'actions/update';
import * as TournamentAction from 'actions/tournament';
import * as SearchAction from 'actions/search';
import * as ModalHistoryAction from 'actions/modalHistory';
import * as JackpotAction from 'actions/jackpot';
import * as ServerSentEventAction from 'actions/serverSentEvents';
import * as LanguageAction from 'actions/language';

export const ActionCreator = Object.assign({},
    LocalStorageAction,
    UrlsAction,
    LoginAction,
    SecurityAction,
    PagesAction,
    CategoryAction,
    GameAction,
    WindowAction,
    ApplicationAction,
    ConfigAction,
    UserAction,
    BetHistoryAction,
    UpdateAction,
    TournamentAction,
    SearchAction,
    ModalHistoryAction,
    JackpotAction,
    ServerSentEventAction,
    LanguageAction
)
