import { ApplicationTypes } from "actions/types";
import { fromJS } from "immutable";
import { setCookie as utilSetCookie, getCookie } from "utils/cookie";

export function initApp(payload) {
  payload.locale = payload.locale.replace("_", "-");
  return (dispatch, getState) => {
    //dispatch(setCookie(JSON.stringify({'srvcred': srvcred})));
    dispatch({
      type: ApplicationTypes.INITIALIZE_APPLICATION,
      payload: payload,
    });
  };
}

export function getMenu() {
  return (dispatch, getState) => {
    let menu = getCookie("menu");

    if (menu === "") return null;

    menu = fromJS(JSON.parse(menu));
    dispatch({
      type: ApplicationTypes.GET_MENU,
      payload: menu,
    });
    return menu;
  };
}

export function setMenu(payload) {
  return (dispatch, getState) => {
    let menu = getState().cookie.get("menu");
    menu = menu.mergeDeep(payload);
    utilSetCookie("menu", JSON.stringify(menu.toJSON()));
    dispatch({
      type: ApplicationTypes.SET_MENU,
      payload: menu,
    });
  };
}

export function setMenuVisibility(payload) {
  return (dispatch, getState) => {
    dispatch({
      type: ApplicationTypes.SET_MENU_VISIBILITY,
      payload: payload,
    });
  };
}

export function setSearchVisibility(payload) {
  return (dispatch, getState) => {
    dispatch({
      type: ApplicationTypes.SET_SEARCH_VISIBILITY,
      payload: payload,
    });
  };
}

export function setGameHistoryVisibility(payload) {
  return (dispatch, getState) => {
    dispatch({
      type: ApplicationTypes.SET_GAME_HISTORY_VISIBILITY,
      payload: payload,
    });
  };
}

export function setCookie(payload) {
  return (dispatch, getState) => {
    let storedSessionId = getCookie("sessionId");
    if (payload.sessionId !== storedSessionId) {
      let allCookies = document.cookie.split(";");
      for (var i = 0; i < allCookies.length; i++)
        document.cookie =
          allCookies[i] + "=;expires=" + new Date(0).toUTCString();

      for (var j in payload) {
        if (payload[j] !== undefined) {
          console.log(j, payload[j]);
          utilSetCookie(j, payload[j]);
        } else {
          delete payload[j];
        }
      }
    } else {
      for (var k in payload) {
        if (
          payload[k] === undefined &&
          getCookie(k) !== "undefined" &&
          k !== "sessionId"
        ) {
          payload[k] = getCookie(k) ? getCookie(k) === "true" : false;
        } else if (payload[k] !== undefined) {
          utilSetCookie(k, payload[k]);
        }
      }
    }

    dispatch({
      type: ApplicationTypes.SET_COOKIE,
      payload: payload,
    });
    return payload;
  };
}

export function setLocale(payload) {
  return (dispatch, getState) => {
    window.parent.postMessage(
      {
        type: "LANGUAGE_CHANGED",
        payload: {
          locale: payload.replace("-", "_"),
        },
      },
      "*"
    );

    let path = getState().router.location.pathname;
    let pathArr = path.split("/");
    pathArr.splice(1, 1, payload);
    let newPath = pathArr.join("/");

    dispatch({
      type: ApplicationTypes.SET_LOCALE,
      payload: {
        locale: payload,
        newPath: newPath,
      },
    });

    window.location.href = newPath;
  };
}
