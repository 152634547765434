import React, { Component } from "react";
import { fromJS } from "immutable";
import translations from "translations";

import { ReactComponent as ArrowDown } from "assets/icons/arrow-down-2.svg";
import { ReactComponent as ArrowUp } from "assets/icons/arrow-up.svg";

import "component/list/Games4by2/styles.scss";

class Games4by2 extends Component {
  constructor(props) {
    super(props);
    this.fetchGames = this._fetchGames.bind(this);
    this.state = {
      games: null,
      showMore: false,
    };
  }

  _fetchGames(link, params) {
    this.props.fetchGames(link, params).then((resp) => {
      this.setState({
        games: fromJS(resp),
      });
    });
  }

  componentDidMount() {
    if (!this.props.route) return;

    this.fetchGames(this.props.route);
  }

  render() {
    let ItemComponent = this.props.itemComponent;

    if (this.state.games && this.state.games.get("items").size === 0) {
      return null;
    }

    return (
      <div className="games-4by2">
        <div
          className={`category-name ${
            this.props.route.get("rel").indexOf("free.rounds-claim.rewards")
              ? ""
              : "highlight"
          } ${
            this.props.route.get("rel").indexOf("tournament-games")
              ? ""
              : "highlight"
          } ${
            this.props.route.get("rel").indexOf("jackpot-games")
              ? ""
              : "highlight"
          }
          `}
        >
          {this.props.route.get("name")}
        </div>
        <div
          className={`row nopadding flex-row w-100 ${
            this.state.showMore ? "show-more" : ""
          }`}
        >
          {this.state.games &&
            this.state.games.get("items").map((item, key) => {
              let linkDemo = item.get("links").find((link) => {
                return link.get("rel") === "launch.demo";
              });
              let linkReal = item.get("links").find((link) => {
                return link.get("rel") === "launch.real";
              });
              let linkDetails = item.get("links").find((link) => {
                return link.get("rel") === "game.details";
              });
              return (
                <ItemComponent
                  key={key}
                  className="col-3 nopadding"
                  game={item}
                  isMobile={this.props.isMobile}
                  linkDemo={linkDemo}
                  linkReal={linkReal}
                  linkDetail={linkDetails}
                  onClick={(playType) => {
                    let gameId = item.get("gameId");
                    this.props.onGameItemClickHandler({ gameId, playType });
                  }}
                  imageFormat={this.props.imageFormat}
                />
              );
            })}
          {!this.state.games &&
            [...Array(4)].map((item, key) => {
              return (
                <ItemComponent
                  key={key}
                  className="col-3"
                  isMobile={this.props.isMobile}
                />
              );
            })}
        </div>
        {this.state.games && this.state.games.get("items").size > 8 && (
          <div className="show-more text-center">
            <button
              className="btn btn-dark btn-sm"
              onClick={() => {
                this.setState({
                  showMore: !this.state.showMore,
                });
              }}
            >
              {this.state.showMore
                ? translations('showLess')
                : translations('showMore')}
              {this.state.showMore ? <ArrowUp /> : <ArrowDown />}
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default Games4by2;
