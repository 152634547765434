import GameFrame from "component/game/GameFrame";
import GameItem from "component/game/GameItem";
import GameItemBanner from "component/game/GameItemBanner";
import GameInfo2 from "component/game/GameInfo2";

export { GameFrame };
export { GameItem };
export { GameItemBanner };
export { GameInfo2 };

export default Object.assign({
  GameFrame,
  GameItem,
  GameItemBanner,
  GameInfo2,
});
