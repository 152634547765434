import { LinkTypes } from "actions/types";
import axios from "axios";
import { serverError } from "actions/error";
import { setCookie } from "actions/application";

export function setMultigameEnabled(bol) {
  return (dispatch, getState) => {
    return dispatch({
      type: LinkTypes.SET_MULTIGAME_ENABLED,
      payload: bol,
    });
  };
}

export function fetchLinks() {
  return (dispatch, getState) => {
    try {
      var route = getState().config.get("route");
      var host = getState().config.get("host");
      var headers = getState()
        .config.get("headers")
        .toJSON();
      var href = route.get("href");
      var url = `${host}${href}`;
      var method = route.get("method");
    } catch (error) {
      throw error;
    }

    dispatch({
      type: LinkTypes.FETCH_LINKS,
      payload: {
        headers,
        route,
        url,
      },
    });
    return axios({
      method,
      headers,
      url,
      withCredentials: true,
    })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        dispatch(serverError(error));
        throw error;
      });
  };
}

export function setLinks(payload) {
  return (dispatch, getState) => {
    let sessionId = getState().cookie.get("sessionId");
    let srvcred = JSON.parse(getState().cookie.get("srvcred"));
    srvcred[sessionId] = {
      gs: payload.gs,
      uuid: payload.uuid,
    };
    dispatch(
      setCookie({
        srvcred: JSON.stringify(srvcred),
      })
    );
    //payload.allowMultiGamePlay = true; //HARDCODE MULTI GAME
    return dispatch({
      type: LinkTypes.SET_LINKS,
      payload: payload,
    });
  };
}
