import React, { Component } from "react";

import { ReactComponent as LobbyIcon } from "assets/icons/lobby.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/refresh.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { ReactComponent as TransferIcon } from "assets/icons/transfer.svg";

import translations from "translations";

import "./styles.scss";

class GameController extends Component {
  render() {
    return (
      <div className="game-controller">
        <div className="row nopadding">
          <div className="col-auto" />

          <div className="col" />

          {this.props.showInfo && (
            <div
              className="col-auto"
              onClick={() => {
                this.props.toggleInfo();
              }}
            >
              <InfoIcon />
            </div>
          )}
          {this.props.showSwitchDevice && (
            <div
              className="col-auto"
              onClick={this.props.broadcastSwitchDevice}
              title={translations("switchDevice")}
            >
              <TransferIcon />
            </div>
          )}
          {this.props.onRefreshClickHandler && (
            <div
              className="col-auto"
              onClick={this.props.onRefreshClickHandler}
              title={translations("refresh")}
            >
              <RefreshIcon />
            </div>
          )}
          {this.props.onCloseClickHandler && (
            <div
              className="col-auto"
              onClick={this.props.onCloseClickHandler}
              title={translations("lobby")}
            >
              <LobbyIcon />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default GameController;
