import {
    LocalStorageTypes
} from 'actions/localStorage/type';
import {
    LoginTypes
} from 'actions/login/type';
import {
    UrlsTypes
} from 'actions/urls/type';
import {
    SecurityTypes
} from 'actions/security/type';
import {
    PagesTypes
} from 'actions/pages/type';
import {
    CategoryTypes
} from 'actions/category/type';
import {
    GameTypes
} from 'actions/game/type';
import {
    WindowTypes
} from 'actions/window/type';
import {
    ApplicationTypes
} from 'actions/application/type';
import {
    LinkTypes
} from 'actions/links/type';
import {
    UserTypes
} from 'actions/user/type';
import {
    BetHistoryTypes
} from 'actions/betHistory/type';
import {
    ErrorTypes
} from 'actions/error/type';
import {
    UpdateTypes
} from 'actions/update/type';
import {
    TournamentTypes
} from 'actions/tournament/type';
import {
    SearchTypes
} from 'actions/search/type';
import {
    ModalHistoryTypes
} from'actions/modalHistory/type';
import {
    JackpotTypes
} from 'actions/jackpot/type'
import {
    ServerSentEventTypes
} from 'actions/serverSentEvents/type'
import {
    LanguageTypes
} from 'actions/language/type';

export { LocalStorageTypes }
export { UrlsTypes };
export { LoginTypes };
export { SecurityTypes };
export { PagesTypes };
export { CategoryTypes };
export { GameTypes };
export { WindowTypes };
export { ApplicationTypes };
export { LinkTypes };
export { UserTypes };
export { BetHistoryTypes };
export { ErrorTypes };
export { UpdateTypes };
export { TournamentTypes };
export { SearchTypes };
export { ModalHistoryTypes };
export { JackpotTypes };
export { ServerSentEventTypes };
export { LanguageTypes };

export const ActionTypes = Object.assign({
    LocalStorageTypes,
    UrlsTypes,
    LoginTypes,
    SecurityTypes,
    PagesTypes,
    CategoryTypes,
    GameTypes,
    WindowTypes,
    ApplicationTypes,
    LinkTypes,
    UserTypes,
    BetHistoryTypes,
    ErrorTypes,
    UpdateTypes,
    TournamentTypes,
    SearchTypes,
    ModalHistoryTypes,
    JackpotTypes,
    ServerSentEventTypes,
    LanguageTypes
})