import { GameTypes } from "actions/types";
import * as queryString from "query-string";
import axios from "axios";
import { serverError } from "actions/error";
import { fromJS } from "immutable";

export function fetchGame(params) {
  return (dispatch, getState) => {
    dispatch({
      type: GameTypes.FETCH_GAME,
      payload: params,
    });
    let { gameId } = params.toJSON();

    return (
      dispatch(fetchGameDetailsUrl(gameId))
        .then((resp) => {
          return dispatch(fetchGameDetails(fromJS(resp)));
        })
        /* .then((resp) => {
        let linksIm = fromJS(resp.payload.data.links);
        let playRealRoute = linksIm.find((link) => {
          return link.get("rel") === "launch.real";
        });

        if (playRealRoute) {
          let newResp = resp;
          return dispatch(fetchGameUrl(playRealRoute)).then((resp) => {
            newResp.payload.data.playRealUrl = resp.payload.data.url;
            return newResp;
          });
        } else {
          return resp;
        }
      })
      .then((resp) => {
        let linksIm = fromJS(resp.payload.data.links);
        let playDemoRoute = linksIm.find((link) => {
          return link.get("rel") === "launch.demo";
        });

        if (playDemoRoute) {
          let newResp = resp;
          return dispatch(fetchGameUrl(playDemoRoute)).then((resp) => {
            newResp.payload.data.playDemoUrl = resp.payload.data.url;
            return newResp;
          });
        } else {
          return resp;
        }
      }) */
        .then((resp) => {
          return dispatch({
            type: GameTypes.SET_GAME,
            payload: fromJS(resp.payload.data),
          });
        })
    );
  };
}

export function onGameClose(params) {
  return (dispatch, getState) => {
    params.location.pathname = params.location.pathname.replace([
      getState().config,
    ]);
    let strRemove = `/${getState().config.get(
      "locale"
    )}/${getState().security.get("sessionId")}`;
    let postMsgParams = { location: {} };

    if (params.location.pathname) {
      postMsgParams.location.pathname = params.location.pathname.replace(
        strRemove,
        ""
      );
    }

    if (params.location.search) {
      postMsgParams.location.search = params.location.search;
    }
    window.parent.postMessage(
      {
        type: "GAME_CLOSED",
        payload: postMsgParams,
      },
      "*"
    );

    return dispatch({
      type: GameTypes.GAME_CLOSE,
      payload: params,
    });
  };
}

export function onGameLaunch(params) {
  return (dispatch, getState) => {
    params.location.pathname = params.location.pathname.replace([
      getState().config,
    ]);
    let strRemove = `/${getState().config.get(
      "locale"
    )}/${getState().security.get("sessionId")}`;
    let postMsgParams = { location: {} };

    if (params.location.pathname) {
      postMsgParams.location.pathname = params.location.pathname.replace(
        strRemove,
        ""
      );
    }

    if (params.location.search) {
      postMsgParams.location.search = params.location.search;
    }

    if (params.playType) {
      postMsgParams.playType = params.playType;
    }

    window.parent.postMessage(
      {
        type: "GAME_LAUNCHED",
        payload: postMsgParams,
      },
      "*"
    );

    if (params.target === "_self") {
      return dispatch({
        type: GameTypes.GAME_LAUNCH,
        payload: params,
      });
    }

    let gameUrl = `${params.url}${params.hideSplash ? "&hideSplash=true" : ""}`;

    dispatch({
      type: GameTypes.GAME_LAUNCH,
      payload: params,
    });

    // Create link in memory
    let a = window.document.createElement("a");

    //safari has diffrent value
    if (!params.appId) {
      switch (params.target) {
        case "_top":
        case "_self":
        case "_parent":
          a.href = gameUrl;
          a.target = params.target;
          break;
        default:
          window.open(params.url, "_blank");
          break;
      }
    } else {
      let data = encodeURIComponent(
        window.btoa(
          JSON.stringify({
            url: gameUrl,
          })
        )
      );
      a.href = `${params.appId}://game/${data}`;
    }

    // Dispatch fake click
    let e = window.document.createEvent("MouseEvents");
    e.initMouseEvent(
      "click",
      true,
      true,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
    a.dispatchEvent(e);
  };
}

export function setGameAction(params) {
  return (dispatch, getState) => {
    dispatch({
      type: GameTypes.SET_GAME_ACTION,
      payload: params,
    });
  };
}

export function setGameActionDetails(payload) {
  return (dispatch, getState) => {
    dispatch({
      type: GameTypes.SET_GAME_ACTION_DETAILS,
      payload,
    });
  };
}

export function clearGameAction() {
  return (dispatch, getState) => {
    dispatch({
      type: GameTypes.CLEAR_GAME_ACTION,
    });
  };
}

export function clearGameActionDetails() {
  return (dispatch, getState) => {
    dispatch({
      type: GameTypes.CLEAR_GAME_ACTION_DETAILS,
    });
  };
}

export function fetchRelatedLinks(route, params) {
  return (dispatch, getState) => {
    let headers = getState()
      .config.get("headers")
      .toJSON();
    let href = route.get("href");
    let url = `${href}`;
    var method = route.get("method");
    dispatch({
      type: GameTypes.FETCH_GAME_DETAILS_URL,
      payload: {
        route,
        headers,
        href,
        url,
        method,
      },
    });
    return axios({
      headers,
      method: "GET",
      url,
      params,
    })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        dispatch(serverError(error));
        throw error;
      });
  };
}

export function fetchGameDetailsUrl(gameId) {
  return (dispatch, getState) => {
    let headers = getState()
      .config.get("headers")
      .toJSON();
    let url = `${[
      getState().config.get("host"),
      `/services/wl/config?gameId=${gameId}`,
    ].join("")}`;
    dispatch({
      type: GameTypes.FETCH_GAME_DETAILS_URL,
      payload: {
        headers,
        gameId,
        url,
      },
    });

    return axios({
      headers,
      method: "GET",
      url,
    })
      .then(({ data }) => {
        return data["game.details"];
      })
      .catch((error) => {
        dispatch(serverError(error));
        throw error;
      });
  };
}

export function fetchGameDetails(route, params) {
  return (dispatch, getState) => {
    let host = getState().config.get("host");
    let headers = getState()
      .config.get("headers")
      .toJSON();
    let href = route.get("href");
    let url = `${host}${href}`;
    var method = route.get("method");

    dispatch({
      type: GameTypes.FETCH_GAME_DETAILS,
      payload: {
        headers,
        route,
        url,
        params,
      },
    });

    return axios({
      headers,
      method,
      url,
      params,
    })
      .then(({ data }) => {
        return dispatch(setGameDetails(Object.assign({ route, data })));
      })
      .catch((error) => {
        dispatch(serverError(error));
        throw error;
      });
  };
}

export function setGameFullscreen(bol) {
  window.parent.postMessage(
    {
      type: bol ? "FULLSCREEN_ENTERED" : "FULLSCREEN_EXITED",
    },
    "*"
  );
  return {
    type: GameTypes.SET_GAME_FULSCREEN,
    payload: {
      gameFullscreen: bol,
    },
  };
}

export function setGameDetails(payload) {
  return {
    type: GameTypes.SET_GAME_DETAILS,
    payload: payload,
  };
}

export function fetchGameUrl(route, linkParams) {
  return (dispatch, getState) => {
    try {
      var host = getState().config.get("host");
      var headers = getState()
        .config.get("headers")
        .toJSON();
      var href = route.get("href");
      var deviceType = getState().window.get("isMobile") ? "MOBILE" : "DESKTOP";
      var params = {
        ...linkParams,
        imageSize: 180,
        imageShape: "round",
        deviceType: deviceType,
      };
      var url = `${[host, href].join("")}?${queryString.stringify(params)}`;
      var method = route.get("method");
    } catch (error) {
      throw error;
    }

    dispatch({
      type: GameTypes.FETCH_GAME_URL,
      payload: {
        headers,
        url,
        route,
      },
    });

    return axios({
      headers,
      method,
      url,
    })
      .then(({ data }) => {
        return dispatch(setGameUrl(Object.assign({ route, data })));
      })
      .catch((error) => {
        dispatch(serverError(error));
        throw error;
      });
  };
}

export function setGameUrl(payload) {
  return {
    type: GameTypes.SET_GAME_URL,
    payload: payload,
  };
}
