import React, { Component, createRef} from 'react';
import { connect } from 'react-redux';
import { ActionCreator } from 'actions';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash';
import * as queryString from 'query-string';
import { fromJS } from 'immutable';
import { ReactComponent as CopyIcon} from 'assets/icons/copy.svg';
import translations from 'translations';
import 'containers/GameRounds/Mobile/styles.scss';
import {
	currencyFormatter
} from 'utils/currencyHelper';

class GameRoundsMobile extends Component {
    constructor(props) {
		super(props);
    this.gameHistoryRoundsRef = createRef();
		this.onClickPrevious = this._onClickPrevious.bind(this);
		this.onCloseClickHandler = this._onCloseClickHandler.bind(this);
		this.onCopyClickHandler = this._onCopyClickHandler.bind(this);
		this.dummyArr = [0,1,2,3,4,5];
		this.state = {
			gameRounds: null,
			isLoading: true,
			tab: 0,
			showToast: false
		};
  }

	loadGameRounds(link, params) {
		this.setState({
			isLoading: true
		})
		this.props.fetchGameRounds(link, params)
		.then(response => {
			return this.props.setGameRounds(response);
		})
		.then(response => {
			this.setState({
				gameRounds: response,
				isLoading: false
			})
		})
		.catch(err => {
			this.setState({
				isLoading: false,
				gameRounds: null
			})
			throw err
		})
	}

	componentDidMount() {
		if (this.props.gameRoundsLink) {
			this.loadGameRounds(this.props.gameRoundsLink)
		}
	}

	componentWillReceiveProps( nextProps ) {
		if(!isEqual(nextProps.gameRoundsLink, this.props.gameRoundsLink) &&
			nextProps.gameRoundsLink) {
			this.setState({
				gameRounds: null,
				isLoading: true
			})
			this.loadGameRounds(nextProps.gameRoundsLink)
		}
	}

	_onClickPrevious() {
		this.props.goBack(`${this.props.location.pathname}${this.props.location.search}`)
		this.props.history.goBack();
	}

	_onCloseClickHandler() {
		this.props.navigateTo(this.props.location.pathname)
	}

	_onCopyClickHandler() {
		navigator.clipboard.writeText(this.state.gameRounds.get("id"));

		this.setState({ showToast: true})

		setTimeout(() => {
			this.setState({ showToast: false})
		}, 3000)
	}

	render() {
		if (!this.props.modalRel || (this.props.modalRel && this.props.modalRel.indexOf("game-rounds") < 0)) {
			return null
		}

		if (!this.state.isLoading && !this.state.gameRounds) {
			return <div className="game-rounds-mobile">
				<div>error loading</div>
			</div>
		}

		return<div className="game-rounds-mobile">
			<div className='title'>
				{translations('roundDetails')}
			</div>
			<div className="gr-navigation">
				<ul className='nav mx-auto justify-content-center'>
					<li className='nav-item' onClick={() => this.setState({
						tab: 0
					})}>
						<span className={`nav-link ${this.state.tab === 0 ? "active" : ""}`}>{translations('overview')}</span>
					</li>
					<li className='nav-item' onClick={() => this.setState({
						tab: 1
					})}>
						<span className={`nav-link ${this.state.tab === 1 ? "active" : ""}`}>{translations('gameResult')}</span>
					</li>
				</ul>
			</div>
			<div className={`gr-overview ${this.state.tab === 0 ? "" : "d-none"}`}>
				<table className='table'>
					<tbody className='table-dark'>
						<tr>
							<td colSpan={2}>&nbsp;</td>
						</tr>
						<tr>
							<th>{translations('status')}</th>
							<td>
								{
									this.state.gameRounds && <span>{this.state.gameRounds.get("status")}</span>
								}
								{
									!this.state.gameRounds && <span className='dummy'>XXXXXXXXXX</span>
								}
							</td>
						</tr>
						<tr>
							<th>{translations('roundId')}</th>
							<td>
								{
									this.state.gameRounds && <span className="round-id">
										<div className='row nopadding'>
										<div className='col nopadding'>
											{this.state.gameRounds.get("id")}
										</div>
										<div className='col-auto nopadding'>
											<CopyIcon
													width="30"
													height="30"
													fill="#cccc"
													onClick={() => {
														this.onCopyClickHandler();
													}}
												/>
										</div>
										</div>
									</span>
								}
								{
									!this.state.gameRounds && <span className='dummy'>645b07a4715e3900019e006f</span>
								}
							</td>
						</tr>
						<tr>
							<th>{translations('initiated')}</th>
							<td>
								{
									this.state.gameRounds && <span>{this.state.gameRounds.get("initiatedDate")}</span>
								}
								{
									!this.state.gameRounds && <span className='dummy'>May 10, 2023 (10:58:30AM GMT+8)</span>
								}
							</td>
						</tr>
						<tr>
							<th>{translations('completed')}</th>
							<td>
								{
									this.state.gameRounds && <span>{this.state.gameRounds.get("completedDate")}</span>
								}
								{
									!this.state.gameRounds && <span className='dummy'>May 10, 2023 (10:58:30AM GMT+8)</span>
								}
							</td>
						</tr>
						<tr>
							<th>{translations('type')}</th>
							<td>
								{
									this.state.gameRounds && <span>{this.state.gameRounds.get("type")}</span>
								}
								{
									!this.state.gameRounds && <span className='dummy'>Normal</span>
								}
							</td>
						</tr>
						<tr>
								<td colSpan={2}>&nbsp;</td>
						</tr>
						<tr>
							<th>{translations('game')}</th>
							<td>
								{
									this.state.gameRounds && <span>{this.state.gameRounds.get("gameName")}</span>
								}
								{
									!this.state.gameRounds && <span className='dummy'>Birthday!</span>
								}
							</td>
						</tr>
						<tr>
							<th>{translations('provider')}</th>
							<td>
								{
									this.state.gameRounds && <span>{this.state.gameRounds.get("provider")}</span>
								}
								{
									!this.state.gameRounds && <span className='dummy'>ELK</span>
								}
							</td>
						</tr>
						<tr>
							<th>{translations('currency')}</th>
							<td>
								{
									this.state.gameRounds && <span>{this.state.gameRounds.get("currency")}</span>
								}
								{
									!this.state.gameRounds && <span className='dummy'>CNY</span>
								}
							</td>
						</tr>
						<tr>
							<th>{translations('roundRefId')}</th>
							<td>
								{
									this.state.gameRounds && <span>{this.state.gameRounds.get("gameProviderRoundId")}</span>
								}
								{
									!this.state.gameRounds && <span className='dummy'>2305100255321000000</span>
								}
							</td>
						</tr>
						<tr>
								<td colSpan={2}>&nbsp;</td>
						</tr>
						{
							this.state.gameRounds && this.state.gameRounds.get("walletCurrency") && <tr>
								<th>{translations('playerCurrency')}</th>
								<td>
									<span>{this.state.gameRounds.get("walletCurrency")}</span>
								</td>
							</tr>
						}
						{
							this.state.gameRounds && this.state.gameRounds.get("walletConversionRate") && <tr>
								<th>{translations('playerToGameFx')}</th>
								<td>
									<span>{this.state.gameRounds.get("walletConversionRate")}</span>
								</td>
							</tr>
						}
						{
							this.state.gameRounds && this.state.gameRounds.get("startBalance") && <tr>
							<th>{translations('startBalance')}</th>
								<td>
									<span>{ currencyFormatter(this.state.gameRounds.get("startBalance"), this.state.gameRounds.get('walletCurrency'))}</span>
								</td>
							</tr>
						}
						{
							this.state.gameRounds && this.state.gameRounds.get("endBalance") && <tr>
							<th>{translations('endBalance')}</th>
								<td>
									<span>{ currencyFormatter(this.state.gameRounds.get("endBalance"), this.state.gameRounds.get('walletCurrency'))}</span>
								</td>
							</tr>
						}
						<tr>
							<th>{translations('totalBet')}</th>
							<td>
								{
									this.state.gameRounds && <span>{currencyFormatter(this.state.gameRounds.get("totalBet"), this.state.gameRounds.get('walletCurrency'))}</span>
								}
								{
									!this.state.gameRounds && <span className='dummy'>5.00</span>
								}
							</td>
						</tr>
						{
							this.state.gameRounds && this.state.gameRounds.get("totalFreeBet") && <tr>
								<th>{translations('totalFreeBet')}</th>
								<td>
									<span>{currencyFormatter(this.state.gameRounds.get("endBalance"), this.state.gameRounds.get('totalFreeBet'))}</span>
								</td>
							</tr>
						}
						<tr>
							<th>{translations('totalPayout')}</th>
							<td>
								{
									this.state.gameRounds && <span>{currencyFormatter(this.state.gameRounds.get("totalPayout"), this.state.gameRounds.get("walletCurrency"))}</span>
								}
								{
									!this.state.gameRounds && <span className='dummy'>15.00</span>
								}
							</td>
						</tr>
					</tbody>
				</table>
				<div className={`toast ${this.state.showToast ? 'active' : ''}`} role="alert" aria-live="assertive" aria-atomic="true">
					<div className="toast-header">
						<strong className="mr-auto">{translations('roundIdCopied')}</strong>
					</div>
				</div>
			</div>
			<div className={`gr-game-result ${this.state.tab === 1 ? "" : "d-none"}`}>
				{
					this.state.gameRounds && this.state.gameRounds.get('gameResultUrl') && <iframe title={this.state.gameRounds.get('gameName')} src={this.state.gameRounds.get('gameResultUrl')}  width="100%" scrolling='yes'/>
				}
			</div>
		</div>
	}
}

const mapStateToProps = (state, ownProps) => {
	let headerAuthorization = state.config.getIn(['headers', 'Authorization']);
	let { modalRel, link } = queryString.parse(ownProps.location.search);

	if(!headerAuthorization || !modalRel || (modalRel && modalRel.indexOf("game-rounds") < 0)) {
		return {}
	}

	let gameRoundsLink = link ? fromJS(JSON.parse(link)) : undefined;
	return {
		modalRel,
		gameRoundsLink
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(ActionCreator, dispatch);
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return Object.assign({}, stateProps, ownProps, {
			setGameHistoryVisibility: (value) => dispatchProps.setGameHistoryVisibility(value),
			navigateTo: ( path ) => ownProps.history.push( path ),
			showModal: (modal) => dispatchProps.showModal(modal),
			hideModal: (modal) => dispatchProps.hideModal(modal),
			fetchGameRounds: (route) => dispatchProps.fetchGameRounds(route),
			setGameRounds: (payload) => dispatchProps.setGameRounds(payload),
			goBack: url => dispatchProps.goBack(url)
    })
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(GameRoundsMobile);
