import React, { Component, createRef } from "react";
import "component/listItem/CategoryType1/styles.scss";
import iconDefault from "assets/images/iconDefault.png";
import { setDefaultImageFormat } from "utils/image";

const ICON_DEFAULT = "ICON_DEFAULT";

class CategoryType1 extends Component {
  constructor(props) {
    super(props);
    this.onMouseOverHandler = this._onMouseOverHandler.bind(this);
    this.mainComponent = createRef();
    this.state = {
      hovered: false,
      isVisible: false,
    };
  }

  _onMouseOverHandler(bol) {
    this.setState({
      hovered: bol,
    });
  }

  componentDidMount() {
    this.initObeserver();
  }

  initObeserver() {
    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      let observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (this.mainComponent.current) {
                this.setState({
                  isVisible: true,
                });
              }
            } else {
              if (this.mainComponent.current) {
                //clearInterval(this.initLoadDelay)
              }
            }
          });
        },
        {
          //rootMargin: '500px 0px 500px 0px'
          rootMargin: "0px 0px 0px 0px",
        }
      );
      observer.observe(this.mainComponent.current);
    } else {
      //this.getGames(this.props.link);
      //this.getTournamentPreview(this.props.links)
    }
  }

  render() {
    let { game } = this.props;

    return (
      <div
        className={`${this.props.className} category-type-1`}
        onMouseOver={() => {
          this.onMouseOverHandler(true);
        }}
        onMouseOut={() => {
          this.onMouseOverHandler(false);
        }}
        onClick={(event) => {
          this.props.onClick(this.props.linkDetails.get("rel"));
          event.stopPropagation();
        }}
        ref={this.mainComponent}
      >
        <div>
          {this.state.isVisible && (
            <img
              src={`${setDefaultImageFormat(
                game.getIn(["images", "url"]).replace("width=90", "width=200") +
                  "&theme=dark",
                this.props.imageFormat.get("logoRound")
              )}`}
              className="icon"
              alt={game.get("name")}
            />
          )}
          <img
            ref={ICON_DEFAULT}
            src={iconDefault}
            className="icon"
            alt={game.get("name")}
          />
          <div className="details">
            <div className="name">{game.get("name")}</div>
            <div className="provider">{game.get("provider")}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default CategoryType1;
