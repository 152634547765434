import React, { Component, createRef, Fragment } from "react";
import { connect } from "react-redux";
import * as queryString from "query-string";
import reactStringReplace from "react-string-replace";
import { isEqual } from "lodash";
import { fromJS } from "immutable";
import { bindActionCreators } from "redux";
import { ActionCreator } from "actions";
import "containers/TournamentViewDetailsDesktop/styles.scss";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import LeaderBoard from "containers/LeaderBoard";
import ShowMoreTournaments from "containers/ShowMoreDesktop/Tournaments";
import { CommonHeaderDesktop } from "component/common";
import { VericalList } from "component/list";
import {
  CategoryType8ListItem,
  TournamentTypeListItem,
} from "component/listItem";
import { CategoryType8Skeleton } from "component/listItemSkeleton";
import { ReactComponent as PreviousIcon } from "assets/icons/previous.svg";
import translations from "translations";
import bannerDefault from "assets/images/headerBannerDefault.png";
import { addSymbol } from "utils/currencyHelper";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down-2.svg";
import { ReactComponent as ArrowUp } from "assets/icons/arrow-up.svg";
import CategoryListDesktop from "containers/CategoryListDesktop";

const VIEW_TOURNAMENT_MODAL = "VIEW_TOURNAMENT_MODAL";

class TournamentViewDetailsDesktop extends Component {
  constructor(props) {
    super(props);
    this.viewTournamentModalRef = createRef();
    this.onGameItemClickHandler = this._onGameItemClickHandler.bind(this);
    this.onClickPrevious = this._onClickPrevious.bind(this);
    this.onChangeTabHandler = this._onChangeTabHandler.bind(this);
    this.onClickName = this._onClickName.bind(this);
    this.handleScroll = this._handleScroll.bind(this);
    this.handleStringCurrency = this._handleStringCurrency.bind(this);
    this.state = {
      skeleton: [1, 2, 3, 4, 5, 6],
      games: null,
      gamesRoute: null,
      tournaments: null,
      tournamentLinks: null,
      tournamentDetails: null,
      description: null,
      pointsConditionsDescription: null,
      prizesDescription: null,
      tab: "games",
      activeDetails: false,
      showCategoryLabel: false,
      bannerLoaded: false,
    };
  }

  _handleScroll(event) {
    const { scrollTop } = event.currentTarget;
    if (scrollTop !== 0) {
      this.setState({ showCategoryLabel: true });
    } else {
      this.setState({ showCategoryLabel: false });
    }
  }

  _onClickName() {
    this.setState({ activeDetails: !this.state.activeDetails });
  }

  _onClickPrevious() {
    this.props.goBack(
      `${this.props.location.pathname}${this.props.location.search}`
    );
    this.props.history.goBack();
  }

  _onChangeTabHandler(tab) {
    this.setState({
      tab,
    });
  }

  _onGameItemClickHandler(game, rel) {
    if (this.props.isMultiGame && !this.props.isMobile) {
      let games = fromJS([]);
      let gameItem = fromJS({
        gameId: game.get("gameId"),
        playType: rel,
        nav: this.state.gamesRoute.get("rel"),
      });
      games = games.push(gameItem);
      games = games.push(null); // for multi game support
      games = games.push(null); // for multi game support
      games = games.push(null); // for multi game support
      let gamesEnc = btoa(encodeURIComponent(JSON.stringify(games.toJSON())));
      let params = {
        games: gamesEnc,
      };
      let newLoc = `${this.props.location.pathname}?${queryString.stringify(
        params
      )}`;

      this.props.navigateTo(newLoc);
      return;
    }

    let params = queryString.parse(this.props.location.search);
    params.playType = rel;
    params.nav = this.state.gamesRoute.get("rel");

    if (!this.props.isMobile) {
      params.gameId = game.get("gameId");
      let newLoc = `${this.props.location.pathname}?${queryString.stringify(
        params
      )}`;
      this.props.navigateTo(newLoc);
    } else {
      params.gameId = game.get("gameId");

      this.props.setGameAction(params);
    }
  }

  _handleStringCurrency(string, currencySymbol) {
    return string.replace(
      "[currencySymbol]",
      String.fromCharCode(parseInt(currencySymbol, 16))
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.showTournamentDetailsRel !== this.props.showTournamentDetailsRel
    ) {
      if (!nextProps.showTournamentDetailsRel) {
        this.setState({
          games: null,
          gamesRoute: null,
          tournaments: null,
          tournamentDetails: null,
          description: null,
          pointsConditionsDescription: null,
          prizesDescription: null,
          tab: null,
        });
        this.props.hideModal(VIEW_TOURNAMENT_MODAL);
      } else {
        this.props.showModal(VIEW_TOURNAMENT_MODAL);
      }
    }

    if (nextProps.isMobile !== this.props.isMobile) {
      this.setState({
        activeDetails: false,
      });
    }

    if (
      !isEqual(nextProps.promotionRoute, this.props.promotionRoute) &&
      nextProps.promotionRoute
    ) {
      this.props
        .fetchTournamentDetails(nextProps.promotionRoute)
        .then((resp) => {
          let imResp = fromJS(resp);
          this.setState({
            tournamentDetails: imResp,
          });

          return imResp;
        })
        .then((resp) => {
          this.props.setTournamentDetails(resp);

          return resp;
        })
        //check description
        .then((resp) => {
          let description = resp.get("description");
          let replaceText = reactStringReplace(
            description,
            /\[(.*?)\]/g,
            (match, i) => {
              let tableData = resp.get(match);

              if (!tableData) {
                return null;
              }

              return (
                <table
                  className={`table table-bordered table-dark ${match}`}
                  key={i}
                >
                  <thead className="table-dark header">
                    <tr>
                      <th colSpan={2}>{match}</th>
                    </tr>
                  </thead>
                  <tbody className="table-dark">
                    {tableData &&
                      tableData.map((tableRow) => {
                        return (
                          <tr>
                            <td>{tableRow.get("condition")}</td>
                            <td>{tableRow.get("points")}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              );
            }
          );

          this.setState({
            description: replaceText,
            tab: this.props.tab,
          });

          return resp;
        })
        //check pointsConditionsDescription
        .then((resp) => {
          let description = resp.get("pointsConditionsDescription");
          let pointsCondition = resp.getIn(["pointsCondition", 0]);
          let currencySymbol = resp.getIn(["currencySymbol", 0]);
          let replaceText = reactStringReplace(
            description,
            /\[(.*?)\]/g,
            (match, i) => {
              let tableData = resp.get(match);

              if (!tableData) {
                return null;
              }

              return (
                <div className="row  justify-content-md-center" key={i}>
                  <div
                    className={`${
                      this.props.isMobile ? "col-12" : "col"
                    } col-points-condition`}
                  >
                    <div className="text-center header">
                      {pointsCondition.get("points") === undefined
                        ? translations('condition')
                        : this.state.tournamentDetails.get("campaignType")}
                    </div>
                    <table
                      className={`table table-bordered table-dark ${match}`}
                    >
                      <tbody className="table-dark">
                        {pointsCondition.get("points") && (
                          <tr>
                            <th>{translations('condition')}</th>
                            <th>{translations('points')}</th>
                          </tr>
                        )}
                        {tableData &&
                          tableData.map((tableRow, key) => {
                            return (
                              <Fragment key={key}>
                                <tr>
                                  <td>
                                    {this.handleStringCurrency(
                                      tableRow.get("condition"),
                                      currencySymbol
                                    )}
                                  </td>
                                  {tableRow.get("points") && (
                                    <td className="text-center">
                                      {tableRow.get("points")}
                                    </td>
                                  )}
                                </tr>
                                {tableRow.get("subConditions") &&
                                  tableRow
                                    .get("subConditions")
                                    .map((tableSubRow, key) => {
                                      return (
                                        <tr key={key}>
                                          <td className="condition">
                                            {this.handleStringCurrency(
                                              tableSubRow.getIn(["condition"]),
                                              currencySymbol
                                            )}
                                          </td>
                                          <td className="text-center">
                                            {tableSubRow.getIn(["points"])}
                                          </td>
                                        </tr>
                                      );
                                    })}
                              </Fragment>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            }
          );

          this.setState({
            pointsConditionsDescription: replaceText,
          });
          return resp;
        })
        .then((resp) => {
          let currencySymbol = resp.getIn(["currencySymbol", 0]);
          let description = resp.get("prizesDescription");
          let isSingleTournament =
            resp.getIn(["prizeTypes", 0]) === (translations('daily')) ||
            resp.getIn(["prizeTypes", 1]) === (translations('daily'));
          let replaceText = reactStringReplace(
            description,
            /\[(.*?)\]/g,
            (match, i) => {
              let tableData = resp.get(match);
              if (!tableData) {
                return null;
              }
              return (
                <div className={`col-12 justify-content-md-center`} key={i}>
                  <div className="col col-points-condition">
                    <div className="text-center header">
                      {isSingleTournament
                        ? translations(match)
                        : translations('prizes')}
                    </div>
                    <table className={`table table-bordered ${match}`}>
                      <tbody className="table-dark">
                        <tr>
                          <th scope="col">{translations('rank')}</th>
                          <th scope="col">{`${
                            translations('prize')
                          } (${String.fromCharCode(
                            parseInt(currencySymbol, 16)
                          )})`}</th>
                        </tr>
                      </tbody>
                      <tbody className="table-dark">
                        {tableData &&
                          tableData.map((tableRow, key) => {
                            return (
                              <tr key={key}>
                                <td className="text-center">
                                  {tableRow.get("rank")}
                                </td>
                                <td className="text-right">
                                  {tableRow.get("amount")}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            }
          );

          this.setState({
            prizesDescription: replaceText,
          });
          return resp;
        })
        .then((resp) => {
          let route;
          try {
            route = resp.getIn(["links"]).find((link) => {
              return link.get("rel").indexOf("tournament-games") > -1;
            });
            let params = {
              size: 25,
            };
            let games = this.state.games ? this.state.games : fromJS({});
            games = games.setIn(["isLoading"], true);

            this.setState({
              games,
              gamesRoute: route,
            });

            this.props.fetchGames(route, params).then((resp) => {
              games = fromJS(resp);
              games = games.setIn(["isLoading"], false);
              this.setState({
                games,
              });
            });

            let tournamentsRoute;
            tournamentsRoute = resp.getIn(["links"]).find((link) => {
              return link.get("rel").indexOf("tournaments") > -1;
            });
            this.setState({
              tournaments: tournamentsRoute,
            });
          } catch (err) {}
        });
    }

    if (!isEqual(nextProps.route, this.props.route) && nextProps.route) {
      let games = this.state.games ? this.state.games : fromJS({});
      games = games.setIn([this.props.route.get("rel"), "isLoading"], true);

      this.setState({
        games,
      });

      let params = {
        size: 25,
      };

      this.props.fetchGames(this.props.route, params).then((resp) => {
        games = games.setIn([this.props.route.get("rel"), "isLoading"], false);
        games = games.mergeIn([this.props.route.get("rel")], fromJS(resp));
        this.setState({
          games,
        });
      });
    }
  }

  renderSeeAllListItem(game, key) {
    let linkDemo = game.get("links").find((link) => {
      return link.get("rel") === "launch.demo";
    });
    let linkReal = game.get("links").find((link) => {
      return link.get("rel") === "launch.real";
    });
    let linkDetails = game.get("links").find((link) => {
      return link.get("rel") === "game.details";
    });

    let ListItem = CategoryType8ListItem;

    return (
      <ListItem
        key={key}
        game={game}
        linkDemo={linkDemo}
        linkReal={linkReal}
        linkDetails={linkDetails}
        host={this.props.host}
        isMobile={this.props.isMobile}
        onClick={(rel) => {
          this.onGameItemClickHandler(game, rel);
        }}
        onShowActionClick={() => {
          this.launchActions(game);
        }}
        currency={this.props.currency}
        symbol={this.props.symbol}
        imageFormat={this.props.imageFormat}
      />
    );
  }

  renderDetails(defaultDetailsVisibility) {
    return (
      <Fragment>
        {this.state.tournamentDetails &&
          this.state.tournamentDetails.get("title") && (
            <div
              className={`tournament-view-details-desktop-content-title`}
              onClick={this.onClickName}
            >
              <div className="row">
                <div className="col">
                  {this.state.tournamentDetails.get("title")}{" "}
                </div>
                {
                  <div
                    className={`col-auto ${
                      defaultDetailsVisibility ? "d-none" : "d-flex"
                    } align-items-center`}
                  >
                    <div>
                      {" "}
                      {defaultDetailsVisibility || this.state.activeDetails ? (
                        <ArrowUp />
                      ) : (
                        <ArrowDown />
                      )}{" "}
                    </div>
                  </div>
                }
              </div>
            </div>
          )}

        {this.state.tournamentDetails && this.props.providers && (
          <div
            className="row nopadding providers subtitle"
            onClick={() => {
              this.onClickName();
            }}
          >
            <div
              className={`col tournament-view-details-desktop-content-provider ${
                this.state.activeDetails ? "expanded" : ""
              }`}
            >
              {this.state.tournamentDetails.get("status") === "IN_PROGRESS" && (
                <span className="status inprogress">
                  {translations('ongoing')}
                </span>
              )}
              {this.state.tournamentDetails.get("status") === "OPEN" && (
                <span className="status open">{translations('comingSoon')}</span>
              )}
              {this.state.tournamentDetails.get("status") === "COMPLETED" && (
                <span className="status completed">{translations('expired')}</span>
              )}
            </div>
          </div>
        )}
        <div
          className={`row nopadding ${
            defaultDetailsVisibility || this.state.activeDetails ? "" : "d-none"
          } description`}
        >
          <div className="col">
            <div className="tournament-view-details-desktop-content-description">
              {this.state.tournamentDetails &&
                this.state.tournamentDetails.get("prizesDescription") && (
                  <div className="description-text pre-wrap">
                    {this.state.description}
                  </div>
                )}
              {this.state.tournamentDetails && (
                <div className="info">
                  <div className="row">
                    <div className="col col-label text-right">
                      {translations('prizePool')}:
                    </div>
                    <div className="col">
                      {this.state.tournamentDetails &&
                        this.state.tournamentDetails.get("prizePool") && (
                          <span>
                            {addSymbol(
                              this.state.tournamentDetails.get("prizePool"),
                              {
                                currency: this.state.tournamentDetails.get(
                                  "currency"
                                ),
                              }
                            )}
                          </span>
                        )}
                    </div>
                  </div>
                  {this.state.tournamentDetails &&
                      this.state.tournamentDetails.get("minimumBet") !== undefined &&
                    <div className="row">
                    <div className="col col-label text-right">
                      {translations('minBet')}:
                    </div>
                    <div className="col">
                      {this.state.tournamentDetails.get("minimumBet") &&
                      this.state.tournamentDetails.get("minimumBet") !==
                        "0.00" ? (
                        <span>
                          {addSymbol(
                            this.state.tournamentDetails.get("minimumBet"),
                            {
                              currency: this.state.tournamentDetails.get(
                                "currency"
                              ),
                            }
                          )}
                        </span>
                      ) : (
                        <span>{translations('noMinimumBet')}</span>
                      )}
                    </div>
                  </div>
                  }
                  <div className="row">
                    <div className="col col-label text-right">
                      {translations('howToWin')}:
                    </div>
                    <div className="col">{`${this.state.tournamentDetails.get(
                      "campaignType"
                    )}`}</div>
                  </div>
                  <div className="row">
                    <div className="col col-label text-right">
                      {translations('category')}:
                    </div>
                    <div className="col">
                      {this.state.tournamentDetails
                        .getIn(["gameTypes"])
                        .map(
                          (gameType, i) =>
                            `${gameType} ${
                              this.state.tournamentDetails.getIn(["gameTypes"])
                                .size !==
                                i + 1 &&
                              this.state.tournamentDetails.getIn(["gameTypes"])
                                .length !==
                                i + 1
                                ? " ・ "
                                : ""
                            } `
                        )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-label text-right">
                      {translations('startDate')}:
                    </div>
                    <div className="col">
                      {this.state.tournamentDetails.get("startDate")}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col col-label text-right">
                      {translations('endDate')}:
                    </div>
                    <div className="col">
                      {this.state.tournamentDetails.get("endDate")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    const { promotionRoute, isMobile } = this.props;
    let { match } = this.props;
    if (!this.props.showTournamentDetailsRel) return null;

    return (
      <Fragment>
        <div
          className={`tournament-view-details-desktop show-more-games-desktop-modal ${
            isMobile ? "mobile" : "desktop"
          }`}
          ref={this.viewTournamentModalRef}
        >
          <div className="row tournament-view-details-desktop-row-header">
            <div className="col">
              {promotionRoute && (
                <div className="header">
                  <CommonHeaderDesktop
                    link={promotionRoute}
                    title={this.state.tournamentDetails}
                    showCategoryLabel={this.state.showCategoryLabel}
                    detailsAvailable={this.props.detailsAvailable}
                    leftPanel={() => {
                      return (
                        <span
                          className="float-left prev-btn"
                          onClick={() => {
                            this.setState({
                              skeleton: [1, 2, 3, 4, 5, 6],
                              games: null,
                              gamesRoute: null,
                              tournamentDetails: null,
                              description: null,
                              pointsConditionsDescription: null,
                              prizesDescription: null,
                              tab: "games",
                              activeDetails: false,
                              showCategoryLabel: false,
                              bannerLoaded: false,
                            });
                            this.onClickPrevious();
                          }}
                        >
                          <PreviousIcon width="34" />
                        </span>
                      );
                    }}
                    rightPanel={() => {
                      return (
                        <CloseIcon
                          width="34"
                          onClick={() => {
                            this.setState({
                              skeleton: [1, 2, 3, 4, 5, 6],
                              games: null,
                              gamesRoute: null,
                              tournamentDetails: null,
                              description: null,
                              pointsConditionsDescription: null,
                              prizesDescription: null,
                              tab: "games",
                              showCategoryLabel: false,
                              bannerLoaded: false,
                            });
                            this.props.navigateTo(this.props.location.pathname);
                          }}
                        />
                      );
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="row tournament-view-details-desktop-row-panel">
            <div className="col col-left-panel">
              <div className="left-panel-content" onScroll={this.handleScroll}>
                {!this.props.isMobile && this.renderDetails(true)}
                {!this.props.isMobile && this.state.tournaments && (
                  <CategoryListDesktop
                    category={this.state.tournaments.getIn(["rel"])}
                    link={this.state.tournaments}
                    match={match}
                    navigateTo={(path) => {
                      this.setState({
                        skeleton: [1, 2, 3, 4, 5, 6],
                        games: null,
                        gamesRoute: null,
                        tournaments: null,
                        tournamentLinks: null,
                        tournamentDetails: null,
                        description: null,
                        pointsConditionsDescription: null,
                        prizesDescription: null,
                        tab: "games",
                        activeDetails: false,
                        showCategoryLabel: false,
                        bannerLoaded: false,
                      });
                      this.props.navigateTo(path);
                    }}
                    maxCount={25}
                    isTournament={true}
                    listItemComponent={TournamentTypeListItem}
                    location={this.props.location}
                  />
                )}
                <ShowMoreTournaments />
              </div>
            </div>
            <div
              className={`col col-main-panel nopadding ${
                isMobile ? "panel-mobile" : ""
              }`}
            >
              <div className="banner-holder">
                <img
                  className={`banner-image ${
                    this.state.bannerLoaded ? "d-none" : ""
                  }`}
                  src={bannerDefault}
                  alt=""
                />
                <img
                  className={`banner-image ${
                    this.state.bannerLoaded ? "" : "d-none"
                  }`}
                  alt={bannerDefault}
                  src={`${
                    this.props.image
                  }&theme=dark&format=${this.props.imageFormat.get(
                    "gamelobbyBanner"
                  )}`}
                  onLoad={() => {
                    this.setState({
                      bannerLoaded: true,
                    });
                  }}
                />
              </div>

              <div
                className={`tournament-view-details-desktop-content ${
                  !isMobile ? "desktop" : ""
                }`}
              >
                {!this.state.tournamentDetails && (
                  <Fragment>
                    <div
                      className={`tournament-view-details-desktop-content-title`}
                    >
                      <div className="col nopadding">
                        <span className="dummy">XXXXXXXXXXXXXXXX</span>
                      </div>
                    </div>
                    <div className="row nopadding providers">
                      <div
                        className={`col tournament-view-details-desktop-content-provider`}
                      >
                        <span className="dummy">XXXXXXXXXX</span>
                      </div>
                    </div>
                  </Fragment>
                )}
                {this.renderDetails(false)}
                <div className="tournament-view-details-desktop-content-navigation ">
                  {!this.state.tournamentDetails && (
                    <ul className="nav mx-auto justify-content-center">
                      <li className="nav-item">
                        <span className="nav-link">
                          <span className="dummy">XXXXX</span>&nbsp;&nbsp;
                        </span>
                      </li>
                      <li className="nav-item">
                        <span className="nav-link">
                          <span className="dummy">XXXXXX</span>&nbsp;&nbsp;
                        </span>
                      </li>
                      <li className="nav-item">
                        <span className="nav-link">
                          <span className="dummy">XXXXXXXXXX</span>&nbsp;&nbsp;
                        </span>
                      </li>
                      <li className="nav-item">
                        <span className="nav-link">
                          <span className="dummy">XXXXXX</span>&nbsp;&nbsp;
                        </span>
                      </li>
                      <li className="nav-item">
                        <span className="nav-link">
                          <span className="dummy">XXXXXXXXXXXX</span>
                          &nbsp;&nbsp;
                        </span>
                      </li>
                    </ul>
                  )}
                  {this.state.tournamentDetails && (
                    <ul className="nav mx-auto justify-content-center">
                      {this.props.leaderboardsRoute && (
                        <li
                          className="nav-item"
                          onClick={() => this._onChangeTabHandler("results")}
                        >
                          <span
                            className={`nav-link ${
                              this.state.tab === "results" ? "active" : ""
                            } text-center`}
                          >
                            {translations('results')}
                          </span>
                        </li>
                      )}
                      <li
                        className="nav-item"
                        onClick={() => this._onChangeTabHandler("games")}
                      >
                        <span
                          className={`nav-link ${
                            this.state.tab === "games" || !this.state.tab
                              ? "active"
                              : ""
                          } text-center`}
                        >
                          {translations('games')}
                        </span>
                      </li>
                      {this.state.tournamentDetails.get(
                        "pointsConditionsDescription"
                      ) && (
                        <li
                          className="nav-item"
                          onClick={() => this._onChangeTabHandler("howToWin")}
                        >
                          <span
                            className={`nav-link ${
                              this.state.tab === "howToWin" ? "active" : ""
                            } text-center`}
                          >
                            {translations('howToWin')}
                          </span>
                        </li>
                      )}
                      {this.state.tournamentDetails.get(
                        "prizesDescription"
                      ) && (
                        <li
                          className="nav-item"
                          onClick={() => this._onChangeTabHandler("prizes")}
                        >
                          <span
                            className={`nav-link ${
                              this.state.tab === "prizes" ? "active" : ""
                            } text-center`}
                          >
                            {translations('prizes')}
                          </span>
                        </li>
                      )}
                    </ul>
                  )}
                </div>

                <div className="row tab-content">
                  <div className="col tournament-view-details-desktop-content-section">
                    {this.state.tab === "games" && (
                      <div className={"game-count row"}>
                        {this.state.games &&
                          this.state.games.getIn(["supportingText"])}
                      </div>
                    )}
                    {(this.state.tab === "games" ||
                      this.state.tab === undefined) && (
                      <VericalList onReachEnd={this.onReachEnd}>
                        {this.state.games &&
                          this.state.games.getIn(["items"]) &&
                          this.state.games.getIn(["items"]).map((game, key) => {
                            return (
                              <div className={`col`} key={key}>
                                {this.renderSeeAllListItem(game, key)}
                              </div>
                            );
                          })}
                        {(!this.state.games ||
                          (this.state.games &&
                            this.state.games.getIn(["isLoading"]))) && (
                          <Fragment>
                            {this.state.skeleton.map((key2) => {
                              return (
                                <div className={`col`} key={key2}>
                                  <CategoryType8Skeleton />
                                </div>
                              );
                            })}
                          </Fragment>
                        )}
                      </VericalList>
                    )}
                    {this.state.tab === "results" &&
                      this.props.leaderboardsRoute && (
                        <LeaderBoard route={this.props.leaderboardsRoute} />
                      )}
                    {this.state.tab === "howToWin" &&
                      this.state.pointsConditionsDescription && (
                        <div className="pre-wrap points-condition">
                          {this.state.pointsConditionsDescription}
                        </div>
                      )}
                    {this.state.tab === "prizes" &&
                      this.state.prizesDescription && (
                        <div className="pre-wrap prize-description">
                          <div className="row">
                            {this.state.prizesDescription}
                          </div>
                        </div>
                      )}
                  </div>
                  {this.state.tab === "howToWin" &&
                    this.state.tournamentDetails &&
                    this.state.tournamentDetails.get("tncDescription") && (
                      <div className="col">
                        <div className="header terms-condition">
                          {translations('termsAndCondition')}
                        </div>
                        {this.state.tournamentDetails && (
                          <div className="pre-wrap terms-and-conditions">
                            {this.state.tournamentDetails.get("tncDescription")}
                          </div>
                        )}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let qs = queryString.parse(window.location.search);
  let { showTournamentDetails, images, routes, tab, providers } = qs;

  if (!state.security.get("sessionId") || !showTournamentDetails) {
    return {};
  }

  let links;
  let link;
  let games;
  let isLoading = true;
  let detailsAvailable = false;
  let image;
  let promotionRoute;
  let leaderboardsRoute;
  let providersArr;

  try {
    providersArr = JSON.parse(decodeURIComponent(atob(providers)));
  } catch (err) {}

  try {
    let imRoutes = fromJS(JSON.parse(decodeURIComponent(atob(routes))));
    promotionRoute = imRoutes.find((route) => {
      return route.get("rel") === showTournamentDetails;
    });
  } catch (err) {}

  try {
    let imRoutes = fromJS(JSON.parse(decodeURIComponent(atob(routes))));
    leaderboardsRoute = imRoutes.find((route) => {
      return route.get("rel").indexOf("tournament-leaderboards") > -1;
    });
  } catch (err) {}

  try {
    games = state.games.getIn([showTournamentDetails, "data"]);
    isLoading = state.games.getIn([showTournamentDetails, "isLoading"]);
    detailsAvailable = state.games.getIn([link.get("rel"), "route", "links"])
      ? true
      : false;
  } catch (err) {}

  try {
    links = state.pages
      .getIn(["wl-pages", 0, "content"])
      .find((contentItem) => {
        return contentItem.get("rel") === "tournament.games";
      })
      .get("links")
      .find((link) => {
        return link.get("rel") === showTournamentDetails;
      })
      .get("links");
  } catch (err) {}

  try {
    let qsimages = fromJS(JSON.parse(decodeURIComponent(atob(images))));
    image = qsimages.get("tournaments");
  } catch (err) {}

  return {
    gameDefaultAction: state.links.get("gameDefaultAction")
      ? state.links.get("gameDefaultAction")
      : null,
    isMobile: state.window.get("isMobile"),
    qs,
    detailsAvailable,
    showTournamentDetailsRel: showTournamentDetails,
    providers: providersArr,
    promotionRoute,
    leaderboardsRoute,
    tab,
    link,
    image,
    links,
    games,
    isLoading,
    isMultiGame: state.links.get("isMultiGame"),
    imageFormat: state.window.get("imageFormat"),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    setGameAction: (params) => dispatchProps.setGameAction(params),
    fetchGames: (route, params) => dispatchProps.fetchGames(route, params),
    fetchTournamentPreview: (link, params) =>
      dispatchProps.fetchTournamentPreview(link, params),
    navigateTo: (path) => ownProps.history.push(path),
    showModal: (modal) => dispatchProps.showModal(modal),
    hideModal: (modal) => dispatchProps.hideModal(modal),
    fetchTournamentDetails: (link) =>
      dispatchProps.fetchTournamentDetails(link),
    setTournamentDetails: (data) => dispatchProps.setTournamentDetails(data),
    goBack: (url) => dispatchProps.goBack(url),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(TournamentViewDetailsDesktop);
