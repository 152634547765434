import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreator } from "actions";
import * as queryString from "query-string";
import { isEmpty } from "lodash";
import GameDetails from "./GameDetails";
import "containers/Game3/styles.scss";
import { ReactComponent as FullscreenIcon } from "assets/icons/fullscreen-alt-svgrepo-com.svg";
import { ReactComponent as ExitFullscreenIcon } from "assets/icons/fullscreen-exit-alt-svgrepo-com.svg";
import { ReactComponent as TransferIcon } from "assets/icons/transfer.svg";
import { ReactComponent as CashierIcon } from "assets/icons/cashier.svg";
import { ReactComponent as PreviousIcon } from "assets/icons/previous.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import includes from "lodash/includes";
import { fromJS } from "immutable";
import { currencyFormatter } from "utils/currencyHelper";
import { BannerImage, GameButtons } from "component/details";
import GameFrame from "component/game/GameFrame";
import defaultBanner from "assets/images/bannerDefault.png";
import translations from "translations";
import { setDefaultImageFormat } from "utils/image";

const GAME = "GAME";

class Game3 extends Component {
  constructor(props) {
    super(props);
    this.onClickHandler = this._onClickHandler.bind(this);
    this.onCloseClickHandler = this._onCloseClickHandler.bind(this);
    this.onNavigateListHandler = this._onNavigateListHandler.bind(this);
    this.onFullscreenHandler = this._onFullscreenHandler.bind(this);
    this.fullscreenChangeHandler = this._fullscreenChangeHandler.bind(this);
    this.onPlayClick = this._onPlayClick.bind(this);
    this.onGameDetailsClick = this._onGameDetailsClick.bind(this);
    this.onClickPrevious = this._onClickPrevious.bind(this);
    this.gameContainer = createRef();

    this.state = {
      gameUrl: null,
      similarTitle: null,
      details: null,
      realLink: null,
      demoLink: null,
      gameCol: "col-9",
      relatedLinks: null,
      similarList: null,
      currentPage: 1,
      gameFullscreen: false,
      gameVerticalPadding: 0,
      gameHeight: "inherit",
      real: false,
      gameDetailsOpened: false,
      isLiveBetGames: false,
    };
  }

  _onFullscreenHandler(bol) {
    let elem = this.gameContainer.current;
    this.props.setGameFullscreen(bol);
    if (bol) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
    } else {
      if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
    }
  }

  _fullscreenChangeHandler(event) {
    if (document.webkitIsFullScreen === false) {
      this.onFullscreenHandler(false);
    } else if (document.mozFullScreen === false) {
      this.onFullscreenHandler(false);
    } else if (document.msFullscreenElement === false) {
      this.onFullscreenHandler(false);
    }
  }

  _onNavigateListHandler(navigateto) {
    let relatedRoute = this.state.relatedLinks.find((link) => {
      return link.get("rel") === "game.similar.play";
    });
    let { totalPages, page, size } = this.props.similarList.toJSON();

    relatedRoute = relatedRoute.set(
      "rel",
      `${this.props.gameId}.${relatedRoute.get("rel")}`
    );

    if (navigateto === "prev" && this.state.currentPage > 1) {
      let currentPage = this.state.currentPage - 1;
      let start = (currentPage - 1) * 5;
      let end = currentPage * 5;

      this.setState({
        currentPage: currentPage,
        similarList: this.props.similarList.get("items").slice(start, end),
      });
    } else if (navigateto === "next") {
      if (this.state.currentPage === page && page < totalPages) {
        let currentPage = this.state.currentPage + 1;

        this.props.fetchGames(relatedRoute, {
          page: this.state.currentPage + 1,
          size: size,
        });

        this.setState({
          currentPage: currentPage,
        });
      } else if (this.state.currentPage < totalPages) {
        let currentPage = this.state.currentPage + 1;
        let start = (currentPage - 1) * 5;
        let end = currentPage * 5;

        this.setState({
          currentPage: currentPage,
          similarList: this.props.similarList.get("items").slice(start, end),
        });
      }
    }
  }

  _onCloseClickHandler() {
    this.props.fetchBalance();
    this.props.onGameClose({
      location: this.props.location,
    });
    let search = queryString.parse(this.props.location.search);
    delete search.gameId;
    delete search.playType;
    delete search.nav;
    this.props.navigateTo(`${this.props.location.pathname}`);
  }

  loadGame(gameId, playType) {
    this.setState({
      realLink: null,
      relatedLinks: null,
      demoLink: null,
    });
    this.retrieveGameDetailsUrl(gameId)
      .then((link) => {
        return this.retrieveGameDetails(link);
      })
      .then((details) => {
        this.setState({
          details: details,
        });
        let playtypeLink = details.get("links").find((link) => {
          return link.get("rel") === playType;
        });

        /**
         *
         * check if demo mode on keyops
         *
         */

        let realLink = details.get("links").find((link) => {
          return link.get("rel") === "launch.real";
        });
        let demoLink = details.get("links").find((link) => {
          return link.get("rel") === "launch.demo";
        });

        let real = false;
        if (typeof realLink === "undefined") real = false;
        else real = true;

        this.setState({
          real: real,
          realLink: realLink,
          demoLink: demoLink,
        });

        /**
         *
         * end
         *
         */
        if (playType === "launch.real" || playType === "launch.demo") {
          this.retrieveGameUrl(playtypeLink);
        }
        return details;
      })
      .then((details) => {
        let relatedLink;
        try {
          relatedLink = details.get("links").find((link) => {
            return link.get("rel") === "related.links";
          });
          let { nav } = queryString.parse(this.props.location.search);
          let params = {
            nav,
          };
          return this.props
            .fetchRelatedLinks(relatedLink, params)
            .then((response) => {
              this.setState({
                relatedLinks: fromJS(response),
              });
            });
        } catch (err) {}
      })
      .then(() => {
        let params = {
          location: this.props.location,
          playType,
          url: this.state.gameUrl,
          hideSplash: this.props.hideSplash,
          isMobile: this.props.isMobile,
          appId: this.props.appId,
          target: "_self",
        };
        this.props.onGameLaunch(params);
      });
  }

  componentDidMount() {
    document.addEventListener("fullscreenchange", this.fullscreenChangeHandler);

    if (this.props.gameId && this.props.isMobile === false) {
      if (this.props.gameFullscreen) {
        this.onFullscreenHandler(this.props.gameFullscreen);
      }

      this.props.showModal(GAME);
      this.loadGame(this.props.gameId, this.props.playType);
    }
  }

  componentWillUnmount() {
    document.removeEventListener(
      "fullscreenchange",
      this.fullscreenChangeHandler
    );
    try {
      this.props.hideModal("GAME");
    } catch (err) {}
  }

  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.gameId !== this.props.gameId ||
        nextProps.playType !== this.props.playType) &&
      nextProps.isMobile === false
    ) {
      if (!nextProps.gameId) {
        this.setState({
          gameUrl: null,
          similarTitle: null,
          details: null,
          realLink: null,
          relatedLinks: null,
          demoLink: null,
          gameCol: "col-9",
          similarList: null,
          currentPage: 1,
          gameFullscreen: false,
          gameVerticalPadding: 0,
          gameHeight: "inherit",
          real: false,
        });
        this.props.hideModal(GAME);
      } else {
        this.props.showModal(GAME);
        this.loadGame(nextProps.gameId, nextProps.playType);
      }
    }

    if (
      nextProps.similarList &&
      nextProps.similarList !== this.props.similarList
    ) {
      let start = (this.state.currentPage - 1) * 5;
      let end = this.state.currentPage * 5;
      this.setState({
        similarList: nextProps.similarList.get("items").slice(start, end),
      });
    }
  }

  retrieveGameDetailsUrl(gameId) {
    return this.props.fetchGameDetailsUrl(gameId).then((link) => {
      return fromJS(link);
    });
  }

  retrieveGameDetails(link) {
    return this.props.fetchGameDetails(link).then(({ payload }) => {
      return fromJS(payload.data);
    });
  }

  retrieveGameUrl(link) {
    this.setState({
      gameUrl: null,
    });

    let params;

    return this.props.fetchGameUrl(link, params).then((response) => {
      this.setState({
        gameUrl: `${response.payload.data.url}${
          this.props.hideSplash ? "&hideSplash=true" : ""
        }`,
      });
      return response;
    });
  }

  _onClickHandler(game) {
    let params = {
      gameId: game.get("gameId"),
    };
    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;
    this.props.navigateTo(newLoc);
  }

  _onPlayClick(playType) {
    let params = {
      gameId: this.props.gameId,
      playType,
    };
    let newLoc = `${this.props.location.pathname}?${queryString.stringify(
      params
    )}`;
    this.props.navigateTo(newLoc);
  }

  _onGameDetailsClick() {
    this.setState({ gameDetailsOpened: !this.state.gameDetailsOpened });
  }

  _onClickPrevious() {
    this.props.history.goBack();
  }

  render() {
    if (!this.props.gameId || !this.state.details || this.props.isMobile)
      return null;

    const displayGameFrame =
      includes(["launch.real", "launch.demo"], this.props.playType) &&
      this.state.gameUrl;

    return (
      <div className={`game3 ${this.props.skin ? "game-right" : "game-left"}`}>
        <div
          className="game-dialog game-dialog-centered"
          ref={this.gameContainer}
        >
          <div className="modal-content">
            <div
              className="row top-buttons nopadding"
              style={{
                marginBottom:
                  displayGameFrame === null && displayGameFrame !== false
                    ? "95%"
                    : "",
              }}
            >
              <div className="col-auto" onClick={this.props.history.goBack}>
                <PreviousIcon />
              </div>

              <div className="col">&nbsp;</div>

              {this.props.showCashier && (
                <div
                  className="col-auto"
                  onClick={this.props.broadcastCashier}
                  title={translations('cashIn')}
                >
                  <CashierIcon />
                </div>
              )}
              {this.props.showSwitchDevice && (
                <div
                  className="col-auto"
                  onClick={this.props.broadcastSwitchDevice}
                  title={translations('switchDevice')}
                >
                  <TransferIcon />
                </div>
              )}

              {this.props.gameFullscreen && (
                <div
                  className="col-auto"
                  onClick={() => {
                    this.onFullscreenHandler(!this.props.gameFullscreen);
                  }}
                  title={translations('minimize')}
                >
                  <ExitFullscreenIcon />
                </div>
              )}
              {displayGameFrame && !this.props.gameFullscreen && (
                <div
                  className="col-auto"
                  onClick={() => {
                    this.onFullscreenHandler(!this.props.gameFullscreen);
                  }}
                  title={translations('maximize')}
                >
                  <FullscreenIcon />
                </div>
              )}

              <div
                className="col-auto"
                onClick={this.onCloseClickHandler}
                title={translations('close')}
              >
                <CloseIcon />
              </div>

              <div className="col-auto  d-none">
                {this.props.balance && (
                  <span className="float-right game-wallet-bal">
                    {currencyFormatter(this.props.balance, {
                      currency: this.props.currency,
                      symbol: this.props.symbol,
                    })}
                  </span>
                )}
              </div>
            </div>
            <div className="row game-info nopadding">
              {displayGameFrame && (
                <GameFrame
                  className={`gameContent col col-game${
                    this.props.gameFullscreen ? "-fullscreen" : ""
                  } ${
                    this.props.isLotteryCasino ? "game-scroll" : ""
                  } nopadding`}
                  src={this.state.gameUrl}
                  isNewSkin={true}
                  skin={this.props.skin}
                  onGameDetailsClick={this.onGameDetailsClick}
                  gameDetailsOpened={this.state.gameDetailsOpened}
                  details={this.state.details}
                  params={this.props.match.params}
                  match={this.props.match}
                  fetchGameUrl={this.props.fetchGameUrl}
                  onPlayClick={this.onPlayClick}
                  playType={this.props.playType}
                  relatedLinks={this.state.relatedLinks}
                  location={this.props.location}
                  navigateTo={this.props.navigateToUrl}
                  history={this.props.history}
                  isFullHeight={
                    this.state.details
                      ? this.state.details.get("hasBetSlip")
                      : false
                  }
                />
              )}
              {!displayGameFrame && this.props.playType === "game.details" && (
                <BannerImage
                  className={`col col-game${
                    this.props.gameFullscreen ? "-fullscreen" : ""
                  } ${this.props.playType !== "game.details" ? "d-none" : ""}`}
                  ref="BANNER_FRAME"
                  defaultSrc={defaultBanner}
                  src={`${setDefaultImageFormat(
                    this.state.details.getIn(["images", "bannerUrl"]) +
                      "&width=800&theme=dark",
                    this.props.imageFormat.get("banner")
                  )}`}
                  alt={this.state.details.get("name")}
                />
              )}
              {!displayGameFrame && this.props.playType !== "game.details" && (
                <div
                  className={`col col-game${
                    this.props.gameFullscreen ? "-fullscreen" : ""
                  } h-100`}
                />
              )}
              {(this.props.playType === "game.details" ||
                this.props.playType === undefined) && (
                <GameButtons
                  className={`col col-game-buttons${
                    this.props.gameFullscreen ? "-fullscreen" : ""
                  }`}
                  realLink={this.state.realLink}
                  demoLink={this.state.demoLink}
                  onPlayClick={this.onPlayClick}
                />
              )}
            </div>
            {
              <GameDetails
                skin={this.props.skin}
                onGameDetailsClick={this.onGameDetailsClick}
                gameDetailsOpened={this.state.gameDetailsOpened}
                details={this.state.details}
                params={this.props.match.params}
                match={this.props.match}
                fetchGameUrl={this.props.fetchGameUrl}
                onPlayClick={this.onPlayClick}
                playType={this.props.playType}
                relatedLinks={this.state.relatedLinks}
                location={this.props.location}
                navigateTo={this.props.navigateToUrl}
                history={this.props.history}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let sessionId = state.security.get("sessionId");
  let isMobile = state.window.get("isMobile");
  //app not yet initialized
  if (!sessionId && isMobile === false) {
    return {};
  }

  let similarList;
  let currency;
  let skinDefault = false;
  let pathLocation = ownProps.location;
  let isLotteryCasinoURI = false;
  let showCashier;
  let showSwitchDevice;
  let gameLaunchTarget;

  try {
    gameLaunchTarget = state.links.get("gameLaunchTarget");
  } catch (err) {}

  try {
    showCashier = state.profile.get("showCashier");
  } catch (err) {}
  try {
    showSwitchDevice = state.profile.get("showSwitchDevice");
  } catch (err) {}

  try {
    let list = state.games.getIn([
      `${ownProps.match.params.game}.game.similar.play`,
      "data",
    ]);
    similarList = list.get("items").size > 0 ? list : null;
  } catch (err) {}

  // skin = 1 (right) else (left)
  if (state.links.get("skin") === "1") skinDefault = true;

  let symbols;
  try {
    let symbolList = state.user.getIn(["symbol"]);
    symbolList = symbolList.map((symbol) => {
      return String.fromCharCode(symbol);
    });
    symbols = symbolList.toJSON().join("");
  } catch (err) {}

  if (!isEmpty(state.links.get("currency"))) {
    currency = state.links.get("currency");
  }
  isLotteryCasinoURI =
    pathLocation.pathname.includes("lotteryGames") ||
    pathLocation.pathname.includes("liveCasinoGames") ||
    pathLocation.pathname.includes("virtualSportsGames");

  let { gameId, playType } = queryString.parse(pathLocation.search);
  return {
    gameLaunchTarget,
    gameId,
    playType,
    isMobile,
    gameFullscreen: state.config.get("gameFullscreen"),
    params: ownProps.match.params,
    host: state.config.get("host"),
    displayName: state.user.get("displayName"),
    pages: state.urls.get("pages"),
    similarList: similarList,
    appId: state.config.get("appId"),
    hideSplash: state.config.get("hideSplash"),
    balance: state.user.get("balance"),
    currency: currency,
    symbol: symbols,
    skin: skinDefault,
    isLotteryCasino: isLotteryCasinoURI,
    showCashier,
    showSwitchDevice,
    imageFormat: state.window.get("imageFormat"),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    logout: () => dispatchProps.logout(),
    fetchGameDetailsUrl: (gameId) => dispatchProps.fetchGameDetailsUrl(gameId),
    fetchGameDetails: (link, params) =>
      dispatchProps.fetchGameDetails(link, params),
    fetchGameUrl: (link, params) => dispatchProps.fetchGameUrl(link, params),
    fetchGames: (route, params) => dispatchProps.fetchGames(route, params),
    navigateTo: (path) => ownProps.history.push(`${path}`),
    navigateToUrl: (path) => ownProps.history.push(`${path}`),
    setGameFullscreen: (bol) => dispatchProps.setGameFullscreen(bol),
    showModal: (modal) => dispatchProps.showModal(modal),
    hideModal: (modal) => dispatchProps.hideModal(modal),
    fetchBalance: () => dispatchProps.fetchBalance(),
    fetchRelatedLinks: (route, params) =>
      dispatchProps.fetchRelatedLinks(route, params),
    goBack: (url) => dispatchProps.goBack(url),
    onGameLaunch: (params) => dispatchProps.onGameLaunch(params),
    onGameClose: (params) => dispatchProps.onGameClose(params),
    broadcastCashier: () => dispatchProps.broadcastCashier(),
    broadcastSwitchDevice: () => dispatchProps.broadcastSwitchDevice(),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Game3);
