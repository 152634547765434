import React, { Component, Fragment, createRef } from "react";
import "component/listItem/CategoryType8/styles.scss";
import bannerDefault from "assets/images/bannerDefault.png";
import {
  PlayDesktopIcon,
  TrophyIcon,
  RibbonIcon,
  EditorsChoiceIcon,
} from "component/icons";
//import reactStringReplace from 'react-string-replace';
import { ReactComponent as CrownIcon } from "assets/icons/crown.svg";
import { currencyFormatter } from "utils/currencyHelper";
import Highlighter from "react-highlight-words";
import { setDefaultImageFormat } from "utils/image";

const ICON_DEFAULT = "ICON_DEFAULT";

class CategoryType8 extends Component {
  constructor(props) {
    super(props);
    this.onMouseOverHandler = this._onMouseOverHandler.bind(this);
    this.checkSentence = this._checkSentence.bind(this);
    let jackpot = null;
    this.mainComponent = createRef();

    if (props.game.get("jackpotBalance")) {
      jackpot = currencyFormatter(props.game.get("jackpotBalance"), {
        currency: this.props.currency,
        symbol: this.props.symbol,
      });
    }
    this.state = {
      hovered: false,
      jackpot: jackpot,
    };
  }

  _checkSentence(sentence, searchArr) {
    // Split the sentence into words
    const words = sentence.split(" ");

    const firstLetters = words.map((word) => word.charAt(0));
    let hasFirtLetters;

    const test = searchArr.map((search, i) => {
      if (search === "") {
        return null;
      }

      const index = firstLetters
        .join("")
        .toLocaleLowerCase()
        .indexOf(search.toLocaleLowerCase());
      hasFirtLetters = index > -1;

      if (index > -1) {
        let renderedWord = words.map((word, j) => {
          if (index <= j && j - index <= search.length - 1) {
            return (
              <Fragment key={j}>
                <span className="matched-search">{word[0]}</span>
                <span>{word.slice(1)} </span>
              </Fragment>
            );
          } else {
            return <span key={j}>{word} </span>;
          }
        });
        return <>{renderedWord}</>;
      }
      return null;
    });

    if (hasFirtLetters) {
      return test;
    }

    return (
      <Highlighter
        highlightClassName="matched-search"
        searchWords={searchArr}
        textToHighlight={sentence}
      />
    );
  }

  _onMouseOverHandler(bol) {
    this.setState({
      hovered: bol,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.game && nextProps.game !== this.props.game) {
      let jackpot;
      let imgSrc = `${nextProps.game.getIn([
        "images",
        "bannerUrl",
      ])}&width=180&theme=dark&format=${this.props.format}`;

      if (nextProps.game.get("jackpotBalance")) {
        jackpot = currencyFormatter(nextProps.game.get("jackpotBalance"), {
          currency: this.props.currency,
          symbol: this.props.symbol,
        });
      }

      this.setState({
        imgSrc: imgSrc,
        jackpot: jackpot,
      });
    }
  }

  componentDidMount() {
    this.initObeserver();
  }

  initObeserver() {
    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      let observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (this.mainComponent.current) {
                this.setState({
                  isVisible: true,
                });
              }
            } else {
              if (this.mainComponent.current) {
                //clearInterval(this.initLoadDelay)
              }
            }
          });
        },
        {
          //rootMargin: '500px 0px 500px 0px'
          rootMargin: "0px 0px 0px 0px",
        }
      );
      observer.observe(this.mainComponent.current);
    } else {
      //this.getGames(this.props.link);
      //this.getTournamentPreview(this.props.links)
    }
  }

  render() {
    let { game, filter } = this.props;

    let searchArr;

    if (filter) {
      searchArr = filter.split(" ");
    }

    return (
      <div
        className={`${this.props.className} category-type-8`}
        onMouseOver={() => {
          this.onMouseOverHandler(true);
        }}
        onMouseOut={() => {
          this.onMouseOverHandler(false);
        }}
        onClick={(event) => {
          this.props.onClick(this.props.linkDetails.get("rel"));
          event.stopPropagation();
        }}
        ref={this.mainComponent}
      >
        <div>
          <div className="img-holder">
            {this.state.isVisible && (
              <img
                src={`${setDefaultImageFormat(
                  game.getIn(["images", "bannerUrl"]) + "&width=450&theme=dark",
                  this.props.imageFormat.get("banner")
                )}`}
                className="icon d-none"
                alt={game.get("name")}
                onLoad={({ currentTarget }) => {
                  currentTarget.classList.remove("d-none");
                  this.refs[ICON_DEFAULT].classList.add("d-none");
                }}
              />
            )}
            <img
              ref={ICON_DEFAULT}
              src={bannerDefault}
              className="icon"
              alt={game.get("name")}
            />
            {this.state.jackpot && (
              <div className="jackpot-amount">
                <CrownIcon />
                {this.state.jackpot}
                <CrownIcon />
              </div>
            )}
            <div
              className={`actions w-100 h-100 justify-content-center align-items-center ${
                this.state.hovered ? "d-flex" : "d-none"
              }`}
            >
              {this.props.linkReal && (
                <div
                  className="play-button text-center"
                  onClick={(event) => {
                    this.props.onClick(this.props.linkReal.get("rel"));
                    event.stopPropagation();
                  }}
                >
                  <PlayDesktopIcon />
                </div>
              )}
              {this.props.linkDemo && (
                <button
                  className="demo-button btn btn-secondary btn-xs"
                  onClick={(event) => {
                    this.props.onClick(this.props.linkDemo.get("rel"));
                    event.stopPropagation();
                  }}
                >
                  {this.props.linkDemo.get("name")}
                </button>
              )}
            </div>
          </div>
          <div className="details">
            {filter ? (
              <Fragment>
                <div className="name">
                  {this.checkSentence(game.get("name"), searchArr)}
                </div>
                <div className="provider">
                  {this.checkSentence(game.get("provider"), searchArr)}
                </div>
                <div className="game-type">
                  {this.checkSentence(game.get("gameType"), searchArr)}
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className="name">{game.get("name")}</div>
                <div className="provider">{game.get("provider")}</div>
                <div className="game-type">
                  {game.get("gameType") ? game.get("gameType") : null}
                </div>
              </Fragment>
            )}
            <div className="game-feature">
              {game.get("hasFreeRounds") && (
                <div className="game-feature-badge ribbonIcon">
                  <RibbonIcon color="rgba(255, 204, 0, 1)" selectedColor="" />
                </div>
              )}
              {game.get("partOfOnGoingTournament") && (
                <div className="game-feature-badge trophyIcon">
                  <TrophyIcon color="rgba(255, 204, 0, 1)" selectedColor="" />
                </div>
              )}
              {game.get("isJackpot") && (
                <div className="game-feature-badge jackpotIcon">
                  <CrownIcon color="rgba(255, 204, 0, 1)" />
                </div>
              )}
              {game.get("isFeatured") && (
                <div className="game-feature-badge editorsChoiceIcon">
                  <EditorsChoiceIcon color="rgba(255, 204, 0, 1)" />
                </div>
              )}
            </div>
            <div className="game-type">
              {game.get("gameType") ? null : <>&nbsp;</>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CategoryType8;
