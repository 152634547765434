import React, { Component, Fragment} from 'react';
import { connect } from 'react-redux';
import { ActionCreator } from 'actions';
import { bindActionCreators } from 'redux';
import { isEqual } from 'lodash';
import {
	currencyFormatter
} from 'utils/currencyHelper';
import * as queryString from 'query-string';
import { fromJS } from 'immutable';
import { ReactComponent as PreviousIcon} from 'assets/icons/previous.svg';
import { ReactComponent as CloseIcon} from 'assets/icons/close.svg';
import { ReactComponent as SortArrowDown } from 'assets/icons/sort-arrow-down.svg';
import { ReactComponent as SortArrowUp } from 'assets/icons/sort-arrow-up.svg';

import 'containers/GamesRoundHistory/styles.scss';
import translations from 'translations';

class GamesRoundHistory extends Component {

	constructor(props) {
    super(props);
		this.onClickPrevious = this._onClickPrevious.bind(this);
		this.onGameClick = this._onGameClick.bind(this);
		this.onCloseClickHandler = this._onCloseClickHandler.bind(this);
		this.onSetSorting = this._onSetSorting.bind(this);
		this.onScrollHandler = this._onScrollHandler.bind(this);
		this.dummyArr = [0,1,2,3,4,5];
		this.state = {
			showGameHistoryRounds:false,
			gameRoundHistory: null,
			sorting: {
				sortBy: "lastPlayed", //"gameName, provider, totalPendingRounds, totalFailedRounds, totalCompletedRounds, lastPlayed, totalBet, totalPayout"
				orderBy: "DESC" //"ASC or DESC"
			},
			sortedGameRoundHistory: null,
			showTitle: false
		};
  }

	componentWillReceiveProps( nextProps ) {
		if(!isEqual(nextProps.gameRoundHistoryLink, this.props.gameRoundHistoryLink) &&
			nextProps.gameRoundHistoryLink) {
			this.setState({
				gameRoundHistory: null
			})

			this.props.fetchGameRoundHistory(nextProps.gameRoundHistoryLink)
			.then(response => {
				return this.props.setGameRoundHistory(response);
			})
			.then(response => {
				this.setState({
					gameRoundHistory: response,
					sortedGameRoundHistory: response
				})
			})
			.catch(err => {
				throw err
			})
		}
	}

	_onClickPrevious() {
		this.props.goBack(`${this.props.location.pathname}${this.props.location.search}`)
		this.props.history.goBack();
	}

	_onGameClick(game) {
		let params = {
			modalRel: game.get('rel'),
			link: JSON.stringify(game.toJSON())
		}
		let newLoc = `${this.props.location.pathname}?${queryString.stringify(params)}`
  	this.props.navigateTo( newLoc )
	}

	_onCloseClickHandler() {
    this.props.navigateTo(this.props.location.pathname)
  }

	_onSetSorting(sortBy) {
		let orderBy = "DESC"

		if(this.state.sorting.sortBy === sortBy) {
			orderBy = (this.state.sorting.orderBy === "DESC") ? "ASC" : "DESC";
		}

		let sorting = {
			sortBy,
			orderBy
		}

		let sortedGameRoundHistory = this.state.gameRoundHistory;
		let sortedItem

		switch(sortBy) {
			case "lastPlayed":
				sortedItem = sortedGameRoundHistory.get("items")
				break;
			case "gameName":
				sortedItem = sortedGameRoundHistory.get("items").sortBy(
					(f) => f.get(sortBy)
				);
				break
			default:
				sortedItem = sortedGameRoundHistory.get("items").sortBy(
					(f) => Number(f.get(sortBy))
				);
				break;
		}


		sortedGameRoundHistory = sortedGameRoundHistory.setIn(['items'], orderBy === "DESC" ? sortedItem : sortedItem.reverse())

		this.setState({
			sorting,
			sortedGameRoundHistory
		})

	}
  _onScrollHandler(event) {
	const scrollDemo = document.querySelector(".game-round-history-modal");
	if(scrollDemo.scrollTop !== 0){
		this.setState({ showTitle: true })
	} else {
		this.setState({ showTitle: false })
	}
  }


	render() {
		if (this.props.modalRel !== "games-round-history") {
			return null
		}

		return <Fragment> <div className={`game-round-history-modal active`} onScroll={this.onScrollHandler}>
			<div className={`game-round-history-modal-header`}>
			<div className='row'>
				<div className="col-auto">
					<span className="float-left prev-btn"
						onClick={this.onClickPrevious}>
						<PreviousIcon width="34"/>
					</span>
				</div>
				<div className={`col details-actions ${this.state.showTitle ? 'fade-in' : ''}`} ref={this.actionLabel}>
				{
					this.state.gameRoundHistory && <span className={`action-name`}>
						<div>{ this.state.gameRoundHistory.get('title') }</div>
					</span>
				}
				</div>
				<div className="col-auto">
					<CloseIcon
						width="34"
						onClick={this.onCloseClickHandler}
					/>
				</div>
			</div>
			<div>
				<div className={`game-round-history-modal-header-title`}>
					{
						!this.state.gameRoundHistory && <div className='dummy'>
							<span>XX XXXXXXX</span>
						</div>
					}
					{
						this.state.gameRoundHistory && <span>{ this.state.gameRoundHistory.get('title') }</span>
					}
				</div>
				{
					!this.state.gameRoundHistory && <div className={`game-round-history-modal-header-sub-title dummy`}>
						<span>XX XXXXXXX</span>
					</div>
				}
				{
					this.state.gameRoundHistory && <div className={`game-round-history-modal-header-sub-title`}>
						{this.state.gameRoundHistory.get("totalCount")} {translations('games')}
					</div>
				}
				{
				this.state.gameRoundHistory && this.state.gameRoundHistory.get("totalCount") !== 0 ?
					<div className={`game-round-history-modal-table`}>
						<table className="table">
						<thead>
							<tr className="titles">
								<th scope="col" onClick={() => {
									this.onSetSorting("gameName")
									}}>
									{translations('game')}
									{
										this.state.sorting && this.state.sorting.sortBy === "gameName" &&
										<Fragment>
											{
												this.state.sorting.orderBy === "DESC" ? <SortArrowDown /> : <SortArrowUp />
											}
										</Fragment>
									}
								</th>
								<th scope="col" className="d-none-sm" onClick={() => {
									this.onSetSorting("totalPendingRounds")
									}}>
									{translations('pending')}
									{
										this.state.sorting && this.state.sorting.sortBy === "totalPendingRounds" &&
										<Fragment>
											{
												this.state.sorting.orderBy === "DESC" ? <SortArrowDown /> : <SortArrowUp />
											}
										</Fragment>
									}
								</th>
								<th scope="col" className="d-none-sm" onClick={() => {
									this.onSetSorting("totalFailedRounds")
									}}>
									{translations('failed')}
									{
										this.state.sorting && this.state.sorting.sortBy === "totalFailedRounds" &&
										<Fragment>
											{
												this.state.sorting.orderBy === "DESC" ? <SortArrowDown /> : <SortArrowUp />
											}
										</Fragment>
									}
								</th>
								<th scope="col" className="d-none-sm" onClick={() => {
									this.onSetSorting("totalCompletedRounds")
									}}>
									{translations('completed')}
									{
										this.state.sorting && this.state.sorting.sortBy === "totalCompletedRounds" &&
										<Fragment>
											{
												this.state.sorting.orderBy === "DESC" ? <SortArrowDown /> : <SortArrowUp />
											}
										</Fragment>
									}
								</th>
								<th scope="col sort" onClick={() => {
								this.onSetSorting("lastPlayed")
								}}>
									{translations('lastPlayed')}
									{
										this.state.sorting && this.state.sorting.sortBy === "lastPlayed" &&
										<Fragment>
											{
												this.state.sorting.orderBy === "DESC" ? <SortArrowDown /> : <SortArrowUp />
											}
										</Fragment>
									}
								</th>
								<th scope="col" className="text-right align-middle" onClick={() => {
									this.onSetSorting("totalBet")
									}}>
									{translations('totalBet')}
									{
										this.state.sorting && this.state.sorting.sortBy === "totalBet" &&
										<Fragment>
											{
												this.state.sorting.orderBy === "DESC" ? <SortArrowDown /> : <SortArrowUp />
											}
										</Fragment>
									}
								</th>
								<th scope="col" className="text-right align-middle" onClick={() => {
									this.onSetSorting("totalPayout")
									}}>
									{translations('totalPayout')}
									{
										this.state.sorting && this.state.sorting.sortBy === "totalPayout" &&
										<Fragment>
											{
												this.state.sorting.orderBy === "DESC" ? <SortArrowDown /> : <SortArrowUp />
											}
										</Fragment>
									}
								</th>
							</tr>
						</thead>
						{
							this.state.gameRoundHistory && <tbody>
								{
									this.state.sortedGameRoundHistory.get('items').map((game, key) => {
										let link = game.get('links').find(link => {return link.get('rel').indexOf("round-history-per-game") > -1})
										return <tr onClick={() => {
											this.onGameClick(link)
											}} key={key}>
										<td className="game align-middle">
											<div><span className="name">{game.get("gameName")}</span></div>
											<div><span className="provider">{game.get("provider")}</span></div>
										</td>
										<td className="d-none-sm align-middle">{game.get("totalPendingRounds")}</td>
										<td className="d-none-sm align-middle">{game.get("totalFailedRounds")}</td>
										<td className="d-none-sm align-middle">{game.get("totalCompletedRounds")}</td>
										<td className="align-middle">{game.get("lastPlayed")}</td>
										<td className="text-right align-middle">{currencyFormatter(game.get("totalBet"), { currency : null })}</td>
										<td className="text-right align-middle">{currencyFormatter(game.get("totalPayout"), { currency : null })}</td>
									</tr>
									})
								}
							</tbody>
						}

						{
							!this.state.gameRoundHistory && <tbody>
								{
									this.dummyArr.map((dummy, key) => {
										return <tr key={key} className='dummy'>
											<td className="game align-middle">
												<div><span className="name">XXXXX XXXXXX XXXXX</span></div>
												<div><span className="provider">XXXXXX</span></div>
											</td>
											<td className="d-none-sm align-middle"><span>X</span></td>
											<td className="d-none-sm align-middle"><span>X</span></td>
											<td className="d-none-sm align-middle"><span>X</span></td>
											<td className="align-middle"><span>XXX X XXXX</span></td>
											<td className="text-right align-middle"><span>XXX.XX</span></td>
											<td className="text-right align-middle"><span>XXX.XX</span></td>
										</tr>
									})
								}
							</tbody>
						}
					</table>
				</div> :
				this.state.gameRoundHistory && <div className='text-center'>{translations('thereIsNoData')}</div>
				}
			</div>
		</div>
			</div>
			</Fragment>
	}
}

const mapStateToProps = (state, ownProps) => {
	let headerAuthorization = state.config.getIn(['headers', 'Authorization']);
	let { modalRel, link } = queryString.parse(ownProps.location.search);


	if(!headerAuthorization || modalRel !== "games-round-history") {
		return {}
	}

	let gameRoundHistoryLink = link ? fromJS(JSON.parse(link)) : undefined;
	return {
		modalRel,
		gameRoundHistoryLink,
		showGameHistory: state.application.get('showGameHistory'),
        locale: state.config.getIn( [ 'locale' ] ),
        isMobile: state.window.get( 'isMobile' )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(ActionCreator, dispatch);
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return Object.assign({}, stateProps, ownProps, {
			setGameHistoryVisibility: (value) => dispatchProps.setGameHistoryVisibility(value),
			navigateTo: ( path ) => ownProps.history.push( path ),
			showModal: (modal) => dispatchProps.showModal(modal),
			hideModal: (modal) => dispatchProps.hideModal(modal),
			fetchGameRoundHistory: (route) => dispatchProps.fetchGameRoundHistory(route),
			setGameRoundHistory: (payload) => dispatchProps.setGameRoundHistory(payload),
			goBack: url => dispatchProps.goBack(url)
    })
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(GamesRoundHistory);
