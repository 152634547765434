import React, { Component, createRef } from "react";
import "component/listItem/IconCategory/styles.scss";
import iconDefault from "assets/images/iconDefault.png";
import {
  PlayDesktopIcon,
  RibbonIcon,
  TrophyIcon,
  EditorsChoiceIcon,
} from "component/icons";
import { ReactComponent as CrownIcon } from "assets/icons/crown.svg";
import { setDefaultImageFormat } from "utils/image";

const ICON_DEFAULT = "ICON_DEFAULT";

class Category extends Component {
  constructor(props) {
    super(props);
    this.onMouseOverHandler = this._onMouseOverHandler.bind(this);
    this.mainComponent = createRef();
    this.state = {
      hovered: false,
    };
  }

  _onMouseOverHandler(bol) {
    this.setState({
      hovered: bol,
    });
  }

  componentDidMount() {
    this.initObeserver();
  }

  initObeserver() {
    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      let observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (this.mainComponent.current) {
                this.setState({
                  isVisible: true,
                });
              }
            } else {
              if (this.mainComponent.current) {
                //clearInterval(this.initLoadDelay)
              }
            }
          });
        },
        {
          //rootMargin: '500px 0px 500px 0px'
          rootMargin: "0px 0px 0px 0px",
        }
      );
      observer.observe(this.mainComponent.current);
    } else {
      //this.getGames(this.props.link);
      //this.getTournamentPreview(this.props.links)
    }
  }

  render() {
    let { game } = this.props;

    return (
      <div
        className={`${this.props.className} iconCategory`}
        onMouseOver={() => {
          this.onMouseOverHandler(true);
        }}
        onMouseOut={() => {
          this.onMouseOverHandler(false);
        }}
        onClick={() => {
          if (this.props.isMobile) {
            this.props.onShowActionClick();
          } else {
            this.props.onClick(this.props.linkDetails.get("rel"));
          }
        }}
        ref={this.mainComponent}
      >
        <div>
          {this.state.isVisible && (
            <img
              src={`${setDefaultImageFormat(
                game.getIn(["images", "iconUrl"]) + "&theme=dark",
                this.props.imageFormat.get("logoRound")
              )}`}
              className="icon d-none"
              alt={game.get("name")}
              onLoad={({ currentTarget }) => {
                currentTarget.classList.remove("d-none");
                this.refs[ICON_DEFAULT].classList.add("d-none");
              }}
            />
          )}
          <img
            ref={ICON_DEFAULT}
            src={iconDefault}
            className="icon"
            alt={game.get("name")}
          />
          <div className="details h-100 d-flex align-items-center">
            <div>
              <div className="name">{game.get("name")}</div>
              <div className="provider">{game.get("provider")}</div>
              <div className="game-type">
                {game.get("gameType") ? game.get("gameType") : null}
              </div>
            </div>
            <span className="game-feature-badge">
              {game.get("hasFreeRounds") && (
                <span className="ribbonIcon">
                  <RibbonIcon color="rgba(255, 204, 0, 1)" selectedColor="" />
                </span>
              )}
              {game.get("partOfOnGoingTournament") && (
                <span className="trophyIcon">
                  <TrophyIcon color="rgba(255, 204, 0, 1)" selectedColor="" />
                </span>
              )}
              {game.get("isJackpot") && (
                <span className="jackpotIcon">
                  <CrownIcon color="rgba(255, 204, 0, 1)" />
                </span>
              )}
              {game.get("isFeatured") && (
                <span className="editorsChoiceIcon">
                  <EditorsChoiceIcon color="rgba(255, 204, 0, 1)" />
                </span>
              )}
            </span>
            <div className="game-type">
              {game.get("gameType") ? null : <>&nbsp;</>}
            </div>
          </div>
          {!this.props.isMobile && (
            <div
              className={`actions row w-100 justify-content-center align-items-center ${
                this.state.hovered ? "" : "d-none"
              }`}
            >
              <div className="col">
                {this.props.linkReal && (
                  <div
                    className="play-button text-center"
                    onClick={(event) => {
                      this.props.onClick(this.props.linkReal.get("rel"));
                      event.stopPropagation();
                    }}
                  >
                    <PlayDesktopIcon />
                  </div>
                )}
                {this.props.linkDemo && (
                  <div className="demo-button">
                    <button
                      className="btn btn-secondary btn-xs"
                      onClick={(event) => {
                        this.props.onClick(this.props.linkDemo.get("rel"));
                        event.stopPropagation();
                      }}
                    >
                      {this.props.linkDemo.get("name")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Category;
