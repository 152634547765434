import React, {
  Component,
	createRef
} from 'react';
import translations from 'translations';
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down-2.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import 'component/filters/Filter/styles.scss';

class Filter extends Component {
	constructor(props) {
		super(props)
		this.topPosition = createRef()
		this.state ={
			showMore: false,
			filterValues: props.filter.get('values'),
			sortFilterValues: props.filter.get('values').sort((a, b) => a.get("name").localeCompare(b.get('name')))
		}
	}
	componentWillReceiveProps( nextProps ) {
		if(nextProps.page && nextProps.page !== this.props.page) {
			this.setState({
				showMore: false
			})
		}
		if(nextProps.filter) {
			let sortFilterValues
			try {
				sortFilterValues = nextProps.filter.get('values').sort((a, b) => a.get("name").localeCompare(b.get('name')))
			} catch (err) {}
			this.setState({
				filterValues: nextProps.filter.get('values'),
				sortFilterValues
			})
		}
	}

	render() {
		return (
			<div className="filter">
				<div className="position" ref={this.topPosition} ></div>
				<div className="label">{this.props.filter.get('name')}</div>
				<div className={`list ${this.props.filter.get('values').size > 10 && !this.state.showMore ? 'show-more' : ''}`}>
						<span
								className={`badge badge-${this.props.isShowAll ? 'danger' : 'secondary'}`}
								onClick={() => {this.props.onFilterClearHandler(this.props.subCategory)}}>
								{ translations('all') }
						</span>
						{
								this.state.showMore === false && this.state.filterValues && this.state.filterValues.map((value, key) => {
										return <span
														key={key}
														className={`badge badge-${value.get('toggled') ? 'danger' : 'secondary'} ${value.get('enabled') ? '' : 'disabled'}`}
														onClick={() => {
															if (value.get('enabled') && !this.props.filtersIsLoading) {
																this.props.onFilterClickHandler(this.props.subCategory, value)
															}
														}}>
														{value.get('name')}
												</span>
								})
						}
						{
								this.state.showMore === true && this.state.sortFilterValues && this.state.sortFilterValues.map((value, key) => {
										return <span
														key={key}
														className={`badge badge-${value.get('toggled') ? 'danger' : 'secondary'} ${value.get('enabled') ? '' : 'disabled'}`}
														onClick={() => {
															if (value.get('enabled') && !this.props.filtersIsLoading) {
																this.props.onFilterClickHandler(this.props.subCategory, value)
															}
														}}>
														{value.get('name')}
												</span>
								})
						}
						{
							this.props.filter.get('values').size > 10 &&
							<div className="see-more text-center">
								<button className="btn btn-primary btn-sm" onClick={() => {
									this.topPosition.current.scrollIntoView({
										behavior: `smooth`
									});
									this.setState({
										showMore: !this.state.showMore
									})
								}}>
									{ this.state.showMore ? translations('showLess') : translations('showMore') }
									{ this.state.showMore ? <ArrowUp />  :  <ArrowDown />  }
								</button>
							</div>
						}
				</div>
			</div>
		)
	}
}

export default Filter