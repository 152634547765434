import createReducer from "utils/createReducer";
import { WindowTypes } from "actions/types";

import { fromJS } from "immutable";

export const defaultValue = fromJS({
  sizeSuffix: "",
  width: 0,
  height: 0,
  scroll: {
    x: 0,
    y: 0,
  },
  layout: "landscape",
  modals: [],
  browser: null,
  isMobile: false,
  inIframe: false,
  imageFormat: {
    banner: "jpeg",
    icon: "png",
    gamelobbyBanner: "jpeg",
    tournaments: "jpeg",
    logoRound: "png",
  },
});

export const window = createReducer(defaultValue, {
  [WindowTypes.SET_WINDOW_SIZE](state, { payload }) {
    let suffix;
    let layout;
    let newState = state;

    if (payload.width >= 1400) {
      suffix = "-xxl";
    } else if (payload.width >= 1200) {
      suffix = "-xl";
    } else if (payload.width >= 992) {
      suffix = "-lg";
    } else if (payload.width >= 768) {
      suffix = "-md";
    } else if (payload.width >= 576) {
      suffix = "-sm";
    } else {
      suffix = "-xs";
    }

    if (payload.width < payload.height) {
      layout = "portrait";
    } else {
      layout = "landscape";
    }

    newState = newState.setIn(["height"], payload.height);
    newState = newState.setIn(["width"], payload.width);
    newState = newState.setIn(["sizeSuffix"], suffix);
    newState = newState.setIn(["layout"], layout);

    return newState;
  },
  [WindowTypes.SET_WINDOW_SCROLL](state, { payload }) {
    let newState = state.set("scroll", fromJS(payload));
    return newState;
  },
  [WindowTypes.SHOW_MODAL](state, { payload }) {
    let modals = state.get("modals").push(payload.modal);
    let newState = state.setIn(["modals"], modals);
    return newState;
  },
  [WindowTypes.HIDE_MODAL](state, { payload }) {
    let modals = state.get("modals").filter((modal) => {
      return modal !== payload.modal;
    });
    let newState = state.setIn(["modals"], modals);
    return newState;
  },
  [WindowTypes.SET_BROWSER](state, { payload }) {
    let newState = state.setIn(["isMobile"], payload.isMobile);
    newState = newState.setIn(["browser"], payload.browser);
    newState = newState.setIn(["inIframe"], payload.inIframe);
    newState = newState.setIn(["imageFormat"], payload.imageFormat);
    return newState;
  },
});
