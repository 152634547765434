import React, { Component } from "react";
import "component/game/GameItemBanner/styles.scss";
import defaultBanner from "assets/images/bannerDefault.png";
import { PlayIcon } from "component/icons";
import { isEqual } from "lodash";
import { setDefaultImageFormat } from "utils/image";

class GameItemBanner extends Component {
  constructor(props) {
    super(props);
    this.loadGame = this._loadGame.bind(this);
    this.state = {
      game: null,
      bannerUrl: null,
      isLoading: true,
      linkReal: null,
      linkDemo: null,
    };
  }

  _loadGame(game) {
    let newGame = game;

    if (newGame.get("playType") === "game.details") {
      newGame = newGame.set("playType", "launch.real");
    }

    this.props
      .fetchGame(newGame)
      .then((resp) => {
        this.setState({
          game: resp.payload,
        });
        return resp;
      })
      .then((resp) => {
        let linkDemo = resp.payload.get("links").find((link) => {
          return link.get("rel") === "launch.demo";
        });

        let linkReal = resp.payload.get("links").find((link) => {
          return link.get("rel") === "launch.real";
        });

        this.setState({
          bannerUrl: resp.payload.getIn(["images", "bannerUrl"]),
          linkDemo,
          linkReal,
        });
      });
  }

  componentDidMount() {
    if (this.props.game) {
      this.loadGame(this.props.game);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.game, this.props.game) && nextProps.game) {
      this.loadGame(nextProps.game);
    }
  }

  render() {
    return (
      <div className="game-item-banner d-flex justify-content-center align-items-center">
        <div className="background-container">
          {!this.state.isLoading && (
            <div
              className="background"
              style={{
                backgroundImage: `url(${setDefaultImageFormat(
                  this.state.bannerUrl + "width=800&theme=dark&",
                  this.props.imageFormat.get("banner")
                )})`,
              }}
            />
          )}
        </div>
        {this.state.isLoading && (
          <div
            className="background"
            style={{
              backgroundImage: `url(${defaultBanner})`,
            }}
          />
        )}

        <div className="banner-container d-flex justify-content-center align-items-center">
          {this.state.bannerUrl && (
            <img
              className={this.state.isLoading ? "d-none" : ""}
              src={`${setDefaultImageFormat(
                this.state.bannerUrl + "width=800&theme=dark&",
                this.props.imageFormat.get("banner")
              )}`}
              alt="banner"
              onLoad={() => {
                this.setState({
                  isLoading: false,
                });
              }}
            />
          )}
          {this.state.isLoading && <img src={defaultBanner} alt="banner" />}
          {this.state.linkReal && (
            <div
              className="play-button"
              onClick={(event) => {
                let gameId = this.state.game.get("gameId");
                let playType = this.state.linkReal.get("rel");
                let nav = this.state.game.get("nav");
                this.props.onGameItemClickHandler({
                  gameId,
                  playType,
                  nav,
                });
              }}
            >
              <PlayIcon />
            </div>
          )}
          {this.state.linkDemo && (
            <div className="demo-button">
              <button
                className="btn btn-secondary btn-sm"
                onClick={(event) => {
                  let gameId = this.state.game.get("gameId");
                  let playType = this.state.linkDemo.get("rel");
                  this.props.onGameItemClickHandler({
                    gameId,
                    playType,
                  });
                  event.stopPropagation();
                }}
              >
                {this.state.linkDemo.get("name")}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default GameItemBanner;
