import React, { Component, Fragment, createRef } from "react";
import "component/listItem/CategoryType4/styles.scss";
import bannerDefault from "assets/images/bannerDefault.png";
import { RibbonIcon, TrophyIcon, EditorsChoiceIcon } from "component/icons";
import { ReactComponent as CrownIcon } from "assets/icons/crown.svg";
import { currencyFormatter } from "utils/currencyHelper";
import Highlighter from "react-highlight-words";
import { setDefaultImageFormat } from "utils/image";

/**
 * game icon with game title, provider and game type on the left
 */

const ICON_DEFAULT = "ICON_DEFAULT";

class CategoryType4 extends Component {
  constructor(props) {
    super(props);
    this.onMouseOverHandler = this._onMouseOverHandler.bind(this);
    this.checkSentence = this._checkSentence.bind(this);
    let jackpot = null;
    this.mainComponent = createRef();

    if (props.game.get("jackpotBalance")) {
      jackpot = currencyFormatter(props.game.get("jackpotBalance"), {
        currency: this.props.currency,
        symbol: this.props.symbol,
      });
    }
    this.state = {
      hovered: false,
      jackpot: jackpot,
      isVisible: false,
    };
  }

  _checkSentence(sentence, searchArr) {
    // Split the sentence into words
    const words = sentence.split(" ");

    const firstLetters = words.map((word) => word.charAt(0));
    let hasFirtLetters;

    const test = searchArr.map((search, i) => {
      if (search === "") {
        return null;
      }

      const index = firstLetters
        .join("")
        .toLocaleLowerCase()
        .indexOf(search.toLocaleLowerCase());
      hasFirtLetters = index > -1;

      if (index > -1) {
        let renderedWord = words.map((word, j) => {
          if (index <= j && j - index <= search.length - 1) {
            return (
              <Fragment key={j}>
                <span className="matched-search">{word[0]}</span>
                <span>{word.slice(1)} </span>
              </Fragment>
            );
          } else {
            return <span key={j}>{word} </span>;
          }
        });
        return <>{renderedWord}</>;
      }
      return null;
    });

    if (hasFirtLetters) {
      return test;
    }

    return (
      <Highlighter
        highlightClassName="matched-search"
        searchWords={searchArr}
        textToHighlight={sentence}
      />
    );
  }

  _onMouseOverHandler(bol) {
    this.setState({
      hovered: bol,
    });
  }

  componentDidMount() {
    this.initObeserver();
  }

  initObeserver() {
    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      let observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (this.mainComponent.current) {
                this.setState({
                  isVisible: true,
                });
              }
            } else {
              if (this.mainComponent.current) {
                //clearInterval(this.initLoadDelay)
              }
            }
          });
        },
        {
          //rootMargin: '500px 0px 500px 0px'
          rootMargin: "0px 0px 0px 0px",
        }
      );
      observer.observe(this.mainComponent.current);
    } else {
      //this.getGames(this.props.link);
      //this.getTournamentPreview(this.props.links)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.game && nextProps.game !== this.props.game) {
      let jackpot;
      let imgSrc = `{nextProps.game.getIn(['images', 'url'])}&width=180&theme=dark`;

      if (nextProps.game.get("jackpotBalance")) {
        jackpot = currencyFormatter(nextProps.game.get("jackpotBalance"), {
          currency: this.props.currency,
          symbol: this.props.symbol,
        });
      }

      this.setState({
        imgSrc: imgSrc,
        jackpot: jackpot,
      });
    }
  }

  render() {
    let { game, filter } = this.props;

    let searchArr;

    if (filter) {
      searchArr = filter.split(" ");
    }

    const hasPromotionIcons =
      game.get("hasFreeRounds") ||
      game.get("partOfOnGoingTournament") ||
      game.get("isFeatured") ||
      game.get("isJackpot");

    return (
      <div
        className={`${this.props.className} category-type-4`}
        onMouseOver={() => {
          this.onMouseOverHandler(true);
        }}
        onMouseOut={() => {
          this.onMouseOverHandler(false);
        }}
        onClick={(event) => {
          this.props.onClick(this.props.linkDetails.get("rel"));
          event.stopPropagation();
        }}
        ref={this.mainComponent}
      >
        <div className="row">
          <div className="col-5 icon">
            {this.state.jackpot && (
              <div className="jackpot-amount">
                <CrownIcon />
                {this.state.jackpot}
                <CrownIcon />
              </div>
            )}
            {this.state.isVisible && (
              <img
                src={`${setDefaultImageFormat(
                  game.getIn(["images", "bannerUrl"]) + "&width=180&theme=dark",
                  this.props.imageFormat.get("banner")
                )}`}
                className="d-none"
                alt={game.get("name")}
                onLoad={({ currentTarget }) => {
                  currentTarget.classList.remove("d-none");
                  this.refs[ICON_DEFAULT].classList.add("d-none");
                }}
              />
            )}
            <img
              ref={ICON_DEFAULT}
              src={bannerDefault}
              alt={game.get("name")}
            />
          </div>
          <div className="col-7 details">
            <div className="h-100 d-flex align-items-center">
              <div>
                {filter ? (
                  <Fragment>
                    <div className="name">
                      {this.checkSentence(game.get("name"), searchArr)}
                    </div>
                    <div className="provider">
                      {this.checkSentence(game.get("provider"), searchArr)}
                    </div>
                    <div className="game-type">
                      {this.checkSentence(game.get("gameType"), searchArr)}
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="name">{game.get("name")}</div>
                    <div className="provider">{game.get("provider")}</div>
                    <div className="game-type">
                      {game.get("gameType") ? (
                        game.get("gameType")
                      ) : (
                        <>&nbsp;</>
                      )}
                    </div>
                  </Fragment>
                )}
              </div>
              {hasPromotionIcons && (
                <span className="game-feature-badge">
                  {game.get("hasFreeRounds") && (
                    <span className="ribbonIcon">
                      <RibbonIcon
                        color="rgba(255, 204, 0, 1)"
                        selectedColor=""
                      />
                    </span>
                  )}
                  {game.get("partOfOnGoingTournament") && (
                    <span className="trophyIcon">
                      <TrophyIcon
                        color="rgba(255, 204, 0, 1)"
                        selectedColor=""
                      />
                    </span>
                  )}
                  {game.get("isJackpot") && (
                    <span className="jackpotIcon">
                      <CrownIcon color="rgba(255, 204, 0, 1)" />
                    </span>
                  )}

                  {game.get("isFeatured") && (
                    <span className="editorsChoiceIcon">
                      <EditorsChoiceIcon color="rgba(255, 204, 0, 1)" />
                    </span>
                  )}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CategoryType4;
