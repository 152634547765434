import React, { Component } from "react";

import { ReactComponent as FullscreenIcon } from "assets/icons/fullscreen-alt-svgrepo-com.svg";
import { ReactComponent as ExitFullscreenIcon } from "assets/icons/fullscreen-exit-alt-svgrepo-com.svg";
import { ReactComponent as TransferIcon } from "assets/icons/transfer.svg";
import { ReactComponent as CashierIcon } from "assets/icons/cashier.svg";
import { ReactComponent as PreviousIcon } from "assets/icons/previous.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/refresh.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";

import { ReactComponent as Screen1Icon } from "assets/icons/screens-icon-01.svg";
import { ReactComponent as Screen2Icon } from "assets/icons/screens-icon-02.svg";
import { ReactComponent as Screen3Icon } from "assets/icons/screens-icon-03.svg";
import { ReactComponent as Screen4Icon } from "assets/icons/screens-icon-04.svg";

import translations from "translations";

import "./styles.scss";

class MultiGameController extends Component {
  render() {
    return (
      <div className="multi-game-controller">
        <div className="row nopadding">
          <div
            className="col-auto"
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <PreviousIcon />
          </div>

          <div className="col text-center game-num">
            <span
              className={this.props.multiGameCount === 1 ? "selected" : ""}
              onClick={() => this.props.setMultiGame(1)}
            >
              <Screen1Icon />
            </span>
            <span
              className={this.props.multiGameCount === 2 ? "selected" : ""}
              onClick={() => this.props.setMultiGame(2)}
            >
              <Screen2Icon />
            </span>
            <span
              className={this.props.multiGameCount === 3 ? "selected" : ""}
              onClick={() => this.props.setMultiGame(3)}
            >
              <Screen3Icon />
            </span>
            <span
              className={this.props.multiGameCount === 4 ? "selected" : ""}
              onClick={() => this.props.setMultiGame(4)}
            >
              <Screen4Icon />
            </span>
          </div>

          {this.props.showInfo && (
            <div
              className="col-auto"
              onClick={() => {
                this.props.toggleInfo();
              }}
            >
              <InfoIcon />
            </div>
          )}

          {this.props.showCashier && (
            <div
              className="col-auto"
              onClick={this.props.broadcastCashier}
              title={translations("cashIn")}
            >
              <CashierIcon />
            </div>
          )}
          {this.props.showSwitchDevice && this.props.multiGameCount === 1 && (
            <div
              className="col-auto"
              onClick={this.props.broadcastSwitchDevice}
              title={translations("switchDevice")}
            >
              <TransferIcon />
            </div>
          )}

          {this.props.onRefreshClickHandler && this.props.multiGameCount === 1 && (
            <div
              className="col-auto refresh"
              onClick={this.props.onRefreshClickHandler}
              title={translations("refresh")}
            >
              <RefreshIcon />
            </div>
          )}

          {this.props.gameFullscreen && (
            <div
              className="col-auto"
              onClick={() => {
                this.props.onFullscreenHandler(!this.props.gameFullscreen);
              }}
              title={translations("minimize")}
            >
              <ExitFullscreenIcon />
            </div>
          )}

          {!this.props.gameFullscreen && (
            <div
              className="col-auto"
              onClick={() => {
                this.props.onFullscreenHandler(!this.props.gameFullscreen);
              }}
              title={translations("maximize")}
            >
              <FullscreenIcon />
            </div>
          )}

          <div
            className="col-auto"
            onClick={this.props.onCloseClickHandler}
            title={translations("close")}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="row nopadding d-none">
          <div className="col" />
          <div className="col-auto">multi game layout</div>
          <div className="col nopadding">
            <div className="row nopadding">
              <div className="col" />
              {this.props.showInfo && (
                <div
                  className="col-auto"
                  onClick={() => {
                    this.props.toggleInfo();
                  }}
                >
                  <InfoIcon />
                </div>
              )}

              {this.props.showCashier && (
                <div
                  className="col-auto"
                  onClick={this.props.broadcastCashier}
                  title={translations("cashIn")}
                >
                  <CashierIcon />
                </div>
              )}
              {this.props.showSwitchDevice && (
                <div
                  className="col-auto"
                  onClick={this.props.broadcastSwitchDevice}
                  title={translations("switchDevice")}
                >
                  <TransferIcon />
                </div>
              )}

              {this.props.onRefreshClickHandler &&
                this.props.multiGameCount === 1 && (
                  <div
                    className="col-auto refresh"
                    onClick={this.props.onRefreshClickHandler}
                    title={translations("refresh")}
                  >
                    <RefreshIcon />
                  </div>
                )}

              {this.props.gameFullscreen && (
                <div
                  className="col-auto"
                  onClick={() => {
                    this.props.onFullscreenHandler(!this.props.gameFullscreen);
                  }}
                  title={translations("minimize")}
                >
                  <ExitFullscreenIcon />
                </div>
              )}

              {!this.props.gameFullscreen && (
                <div
                  className="col-auto"
                  onClick={() => {
                    this.props.onFullscreenHandler(!this.props.gameFullscreen);
                  }}
                  title={translations("maximize")}
                >
                  <FullscreenIcon />
                </div>
              )}
              <span
                className="col-auto"
                onClick={this.props.onCloseClickHandler}
                title={translations("close")}
              >
                <CloseIcon />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MultiGameController;
