import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import ReactGA from "react-ga4";
import { bindActionCreators } from "redux";
import { ActionCreator } from "actions";
import { isEqual } from "lodash";
import * as queryString from "query-string";
//TO REMOVE

import { ClientJS } from "clientjs";

import Exit from "containers/Exit";
import Error from "containers/Error";
import Content from "containers/Content";
import Categories from "containers/Categories";
import Navigation from "containers/Navigation";
import GameNavigation from "containers/GameNavigation";
import Game3 from "containers/Game3";
import Game from "containers/Game";
import Games from "containers/Games";
import Update from "containers/Update";
import Menu from "containers/Menu";
import BetHistory from "containers/BetHistory";
import Search from "containers/Search";
import GamesRoundHistory from "containers/GamesRoundHistory";
import RoundHistoryPerGame from "containers/RoundHistoryPerGame";
import ShowMore from "containers/ShowMore";
import TournamentViewDetailsDesktop from "containers/TournamentViewDetailsDesktop";
import TournamentLeaderboard from "containers/TournamentLeaderboard";
import JackpotDetails from "containers/JackpotDetails";
import JackpotViewDetails from "containers/JackpotViewDetails";
import GameAction from "containers/GameAction";
import GameActionCollapse from "containers/GameActionCollapse";

import "containers/Application/styles.scss";
import { getCookie } from "utils/cookie";
import ShowMoreDesktop from "containers/ShowMoreDesktop";
import GameRounds from "containers/GameRounds";
import { fromJS } from "immutable";

class App extends Component {
  constructor(props) {
    super(props);
    this.onWindowResize = this._onWindowResize.bind(this);
    this.state = {
      noPath: false,
      scrollY: 0,
    };
  }

  _onWindowResize() {
    let height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight,
      width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

    this.props.setWindowSize({
      height: height,
      width: width,
    });
  }

  async supportsAVIF() {
    return new Promise((resolve) => {
      var avif = new Image();
      avif.src =
        "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=";
      avif.onload = () => {
        resolve(true);
      };
      avif.onerror = () => {
        resolve(false);
      };
    });
  }

  async supportsWebp() {
    return new Promise((resolve) => {
      var webp = new Image();
      webp.src =
        "data:image/webp;base64,UklGRiIAAABXRUJQVlA4TAYAAAAvAAAAAAfQ//73v/+BiOh/AAA=";
      webp.onload = () => {
        resolve(true);
      };
      webp.onerror = () => {
        resolve(false);
      };
    });
  }

  async componentDidMount() {
    const isAvifSupported = await this.supportsAVIF();
    const isWebpSupported = await this.supportsAVIF();
    let imageFormat = {
      banner: "jpeg",
      icon: "png",
      gamelobbyBanner: "jpeg",
      tournaments: "jpeg",
      providers: "jpeg",
    };

    if (isAvifSupported) {
      imageFormat.banner = "avif";
      imageFormat.icon = "avif";
      imageFormat.gamelobbyBanner = "avif";
      imageFormat.tournaments = "avif";
      imageFormat.logoRound = "avif";
      imageFormat.providers = "avif";
    } else if (isWebpSupported) {
      imageFormat.banner = "webp";
      imageFormat.icon = "webp";
      imageFormat.gamelobbyBanner = "webp";
      imageFormat.tournaments = "webp";
      imageFormat.logoRound = "webp";
      imageFormat.providers = "webp";
    }

    this.onWindowResize();

    window.addEventListener("resize", this.onWindowResize);
    if (this.props.match.path === "/") {
      this.setState({
        noPath: true,
      });
    }

    //eslint-disable-next-line
    let client = new ClientJS();
    //let nameArr = [client.getBrowserData().os.name.toLowerCase(), client.getBrowserData().browser.name.toLowerCase()];

    if (client.getBrowserData().os.name.toLowerCase() === "android") {
      document.documentElement.classList.add("android");
    }

    let { params } = this.props.match;

    let hideSplash = undefined;
    let showMenu = undefined;
    let appId;

    if (this.props.location.search) {
      let urlParams = new URLSearchParams(this.props.location.search);
      hideSplash = urlParams.get("hideSplash")
        ? urlParams.get("hideSplash") === "true"
        : undefined;
      showMenu = urlParams.get("showMenu")
        ? urlParams.get("showMenu") === "true"
        : undefined;
      appId = urlParams.get("appId");
    }

    let inIframe = () => {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    };
    var isTouch = "ontouchstart" in document.documentElement;
    let browserProps = {
      browser: navigator.userAgent,
      isMobile:
        client.isMobile() || client.getBrowser() === "Mobile Safari" || isTouch,
      isMobileIOS: client.isMobileIOS(),
      hideSplash,
      showMenu,
      appId,
      inIframe: inIframe(),
      imageFormat: fromJS(imageFormat),
    };
    let srvcred = getCookie("srvcred");
    srvcred = srvcred === "" ? {} : JSON.parse(srvcred);

    if (!srvcred[params.sessionId]) {
      srvcred[params.sessionId] = {
        dateCreated: new Date(),
        uuid: null,
        gs: null,
      };
    }

    let cookieProps = {
      sessionId: params.sessionId,
      hideSplash,
      showMenu,
      srvcred: JSON.stringify(srvcred),
    };

    let { uuid, gs } = srvcred[params.sessionId];
    params.uuid = uuid;
    params.gs = gs;

    this.props.setBrowser(browserProps);
    this.props.initApp(params);
    this.props.setCookie(cookieProps);

    if (window.APP_ENV) {
      console.log("APP_ENV", window.APP_ENV);
      let measurementId;

      switch (window.APP_ENV) {
        case "production": //prod
          measurementId = "G-0WSCKQDVMJ";
          break;
        default:
          //int and dev
          measurementId = "G-YLRXB8S8G1";
          break;
      }

      ReactGA.initialize(measurementId, { debug: true });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.appEnv !== this.props.appEnv && nextProps.appEnv) {
      console.log("appEnv:", nextProps.appEnv);
      let measurementId;

      switch (nextProps.appEnv) {
        case "production": //prod
          measurementId = "G-0WSCKQDVMJ";
          break;
        default:
          //int and dev
          measurementId = "G-YLRXB8S8G1";
          break;
      }

      ReactGA.initialize(measurementId);
    }

    if (nextProps.mode && nextProps.mode !== this.props.mode) {
      ReactGA.event({
        action: "load_app",
        category: nextProps.mode,
      });
    }

    if (
      nextProps.getBalance &&
      nextProps.getBalance !== this.props.getBalance
    ) {
      this.props.fetchBalance();
    }

    if (nextProps.getPages && nextProps.getPages !== this.props.getPages) {
      this.props.wlFetchPages();
    }

    if (
      nextProps.notificationUrl &&
      nextProps.notificationUrl !== this.props.notificationUrl
    ) {
      this.props.initServerSent(nextProps.notificationUrl);
    }

    if (nextProps.wlProfile && nextProps.wlProfile !== this.props.wlProfile) {
      this.props.wlFetchProfile();
    }

    if (nextProps.sessionId && nextProps.sessionId !== this.props.sessionId) {
      this.props
        .fetchLinks()
        .then((response) => {
          let { gs, uuid } = response;
          let payload = {
            gs,
            uuid,
          };
          this.props.setCookie(payload);
          return response;
        })
        .then((response) => {
          return this.props.setLinks(response);
        });
    }

    if (
      nextProps.pages &&
      nextProps.pages !== this.props.pages &&
      nextProps.match.url === nextProps.location.pathname
    ) {
      let { locale, sessionId, page, category } = this.props.match.params;

      let myQT = nextProps.pages.get("wl-pages").find((page) => {
        return page.get("rel") === "myQT";
      });
      let webMainLobby = nextProps.pages.get("wl-pages").find((page) => {
        return page.get("rel") === "webLobbyMain";
      });
      let initPage = webMainLobby.getIn(["content", 0]);
      if (myQT) {
        this.props.navigateTo(
          `/${locale}/${sessionId}/categories/${myQT.get("rel")}`
        );
      } else if (!page || !category) {
        this.props.navigateTo(
          `/${locale}/${sessionId}/games/${initPage.get(
            "rel"
          )}/${initPage.getIn(["links", 1, "rel"])}`
        );
      }
    }

    if (nextProps.modals && nextProps.modals !== this.props.modals) {
      if (nextProps.modals.size > 0) {
        let scrollY = window.pageYOffset;
        this.setState({
          scrollY: scrollY,
        });
      } else {
        window.scrollTo(0, this.state.scrollY);
      }
    }

    if (isEqual(nextProps.location, this.props.location)) {
      let search = queryString.parse(nextProps.location.search);
      if (search.nav && search.playType) {
        if (search.gameId) {
          ReactGA.event({
            category: search.nav,
            action: search.playType,
            label: search.gameId,
          });
          if (search.q) {
            ReactGA.event({
              action: "launch_on_search",
              category: search.nav,
              label: search.q,
            });
          }
        }
        if (search.gameActionId) {
          ReactGA.event({
            category: search.nav,
            action: search.playType,
            label: search.gameActionId,
          });
          if (search.q) {
            ReactGA.event({
              action: "launch_on_search",
              category: search.nav,
              label: search.q,
            });
          }
        }
      }
    }
  }

  render() {
    return (
      <div className="container-fluid application nopadding">
        <div className="d-flex flex-column">
          <Switch>
            <Route
              path="/:locale/:sessionId/games/:page"
              component={Navigation}
            />
            <Route
              path="/:locale/:sessionId/categories/:page"
              component={Navigation}
            />
          </Switch>
          <Switch>
            <Route
              path="/:locale/:sessionId/games/:page"
              component={GameNavigation}
            />
            <Route
              path="/:locale/:sessionId/categories/:page"
              component={GameNavigation}
            />
          </Switch>
          <Switch>
            <Route
              path="/:locale/:sessionId/games/:page/:category"
              component={Content}
            />
            <Route
              path="/:locale/:sessionId/categories/:page"
              component={Categories}
            />
          </Switch>
          <Switch>
            <Route path="/:locale/:sessionId" component={Search} />
          </Switch>
          <Switch>
            <Route
              path="/:locale/:sessionId"
              render={(props) => {
                if (this.props.isMobile) {
                  return <ShowMore {...props} />;
                } else {
                  return <ShowMoreDesktop {...props} />;
                }
              }}
            />
          </Switch>
          <Switch>
            <Route path="/:locale/:sessionId" component={Game} />
          </Switch>
          <Switch>
            <Route path="/:locale/:sessionId" component={Game3} />
          </Switch>
          <Switch>
            <Route path="/:locale/:sessionId" component={Games} />
          </Switch>
          <Switch>
            <Route
              path="/:locale/:sessionId"
              component={TournamentViewDetailsDesktop}
            />
          </Switch>
          <Switch>
            <Route
              path="/:locale/:sessionId"
              component={TournamentLeaderboard}
            />
          </Switch>
          <Switch>
            <Route path="/:locale/:sessionId" component={JackpotDetails} />
          </Switch>
          <Switch>
            <Route path="/:locale/:sessionId" component={JackpotViewDetails} />
          </Switch>
          <Switch>
            <Route path="/:locale/:sessionId" component={GameAction} />
          </Switch>
          <Switch>
            <Route path="/:locale/:sessionId" component={GameActionCollapse} />
          </Switch>
          <Switch>
            <Route path="/:locale/:sessionId" component={Menu} />
          </Switch>
          <Switch>
            <Route path="/:locale/:sessionId" component={GamesRoundHistory} />
          </Switch>
          <Switch>
            <Route path="/:locale/:sessionId" component={RoundHistoryPerGame} />
          </Switch>
          <Switch>
            <Route path="/:locale/:sessionId" component={GameRounds} />
          </Switch>
          <Switch>
            <Route exact path="*" component={BetHistory} />
          </Switch>
          <Error />
          <Switch>
            <Route exact path="*" component={Update} />
          </Switch>
          <Switch>
            <Route path="/:locale/:sessionId/exit" component={Exit} />
          </Switch>
          <div className="d-none" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    getBalance: state.links.get("getBalance"),
    getPages: state.links.get("getPages"),
    notificationUrl: state.links.getIn(["notificationUrls", 0]),
    wlProfile: state.links.get("wl.profile"),
    skin: state.links.get("skin"),
    isMobile: state.window.get("isMobile"),
    filtersVisible: state.config.get("filtersVisible"),
    sizeSuffix: state.window.get("sizeSuffix"),
    locale: state.config.getIn(["locale"]),
    configRoute: state.config.get("route"),
    mode: state.links.get("mode"),
    urls: state.urls,
    loginUrl: state.urls
      ? state.urls.getIn(["loginUrls", state.config.get("currentLoginIndex")])
      : null,
    username: state.user.get("username"),
    sessionId: state.security.get("sessionId"),
    pages: state.pages,
    modals: state.window.get("modals"),
    appEnv: window.APP_ENV,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(ActionCreator, dispatch);
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return Object.assign({}, stateProps, ownProps, {
    login: (username, password) => dispatchProps.login(username, password),
    logout: () => dispatchProps.logout(),
    navigateTo: (path) => ownProps.history.push(path),
    restoreState: () => dispatchProps.restoreState(),
    wlFetchPages: () => dispatchProps.wlFetchPages(),
    setWindowSize: (size) => dispatchProps.setWindowSize(size),
    setWindowScroll: (props) => dispatchProps.setWindowScroll(props),
    initApp: (params) => dispatchProps.initApp(params),
    setBrowser: (props) => dispatchProps.setBrowser(props),
    fetchLinks: () => dispatchProps.fetchLinks(),
    setLinks: (data) => dispatchProps.setLinks(data),
    fetchBalance: () => dispatchProps.fetchBalance(),
    wlFetchProfile: () => dispatchProps.wlFetchProfile(),
    setCookie: (params) => dispatchProps.setCookie(params),
    initServerSent: (route) => dispatchProps.initServerSent(route),
    onGameClose: (params) => dispatchProps.onGameClose(params),
  });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(App);
