import { UserTypes } from "actions/types";
import axios from "axios";
import { fromJS } from "immutable";
import { serverError } from "actions/error";

export function fetchBalance() {
  return (dispatch, getState) => {
    try {
      var host = getState().config.get("host");
      var route = getState().links.get("getBalance");
      var headers = getState()
        .config.get("headers")
        .toJSON();
      var href = route.get("href");
      var url = `${host}${href}`;
      var method = route.get("method");
    } catch (error) {
      return;
    }

    dispatch({
      type: UserTypes.FETCH_BALANCE,
      payload: {
        route,
        url,
      },
    });
    return axios({
      method,
      headers,
      url,
    })
      .then(({ data }) => {
        return dispatch(
          setBalance(
            fromJS(
              Object.assign({
                route,
                data,
              })
            )
          )
        );
      })
      .catch((error) => {
        dispatch(serverError(error));
        throw error;
      });
  };
}

export function setBalance(payload) {
  return (dispatch, getState) => {
    return dispatch({
      type: UserTypes.SET_BALANCE,
      payload: payload,
    });
  };
}

export function wlFetchProfile() {
  return (dispatch, getState) => {
    try {
      var host = getState().config.get("host");
      var route = getState().links.get("wl.profile");
      var headers = getState()
        .config.get("headers")
        .toJSON();
      var href = route.get("href");
      var url = `${host}${href}`;
      var method = route.get("method");
    } catch (error) {
      return;
    }

    dispatch({
      type: UserTypes.FETCH_PROFILE,
      payload: {
        route,
        url,
      },
    });
    return axios({
      method,
      headers,
      url,
    })
      .then(({ data }) => {
        return dispatch(
          setProfile(
            fromJS(
              Object.assign({
                route,
                data,
              })
            )
          )
        );
      })
      .catch((error) => {
        dispatch(serverError(error));
        throw error;
      });
  };
}

export function setProfile(payload) {
  return (dispatch, getState) => {
    return dispatch({
      type: UserTypes.SET_PROFILE,
      payload: payload,
    });
  };
}

export function broadcastCashier() {
  window.parent.postMessage(
    {
      type: "CASHIER_REQUESTED",
    },
    "*"
  );

  return (dispatch, getState) => {
    return dispatch({
      type: UserTypes.BROADCAST_SHOW_CASHIER,
    });
  };
}

export function broadcastSwitchDevice() {
  window.parent.postMessage(
    {
      type: "SWITCH_DEVICE_REQUESTED",
    },
    "*"
  );
  return (dispatch, getState) => {
    return dispatch({
      type: UserTypes.BROADCAST_SWITCH_DEVICE,
    });
  };
}

export function broadcastSessionExpired() {
  window.parent.postMessage(
    {
      type: "SESSION_EXPIRED",
    },
    "*"
  );

  return (dispatch, getState) => {
    return dispatch({
      type: UserTypes.BROADCAST_SESSION_EXPIRED,
    });
  };
}
