import createReducer from "../utils/createReducer";
import { fromJS } from "immutable";
import { LinkTypes } from "../actions/types";

export const defaultValue = fromJS({});

export const links = createReducer(defaultValue, {
  [LinkTypes.SET_LINKS](state, { payload }) {
    let newState = fromJS(payload);
    newState = newState.setIn(["isMultiGame"], payload.allowMultiGamePlay);
    return newState;
  },
  [LinkTypes.SET_MULTIGAME_ENABLED](state, { payload }) {
    let newState = state.setIn(["isMultiGame"], payload);
    return newState;
  },
});
