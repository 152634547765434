import React, { Component, Fragment, createRef } from "react";
import "component/listItem/JackpotType/styles.scss";
import jackpotDefault from "assets/images/jackpotDefault.png";
import bannerDefault from "assets/images/headerBannerDefault.png";
import { currencyFormatter } from "utils/currencyHelper";
import { fromJS } from "immutable";
import { isEqual } from "lodash";

class JackpotType extends Component {
  constructor(props) {
    super(props);

    this.onMouseOverHandler = this._onMouseOverHandler.bind(this);
    this.iconRef = createRef();
    this.iconDefaultRef = createRef();
    this.mainComponent = createRef();

    let games;
    let promotion;
    let winners;
    let gameTypes;

    try {
      games = props.game.get("links").find((link) => {
        return link.get("rel").indexOf("jackpot-games") > -1;
      });
    } catch (err) {}
    try {
      promotion = props.game.get("links").find((link) => {
        return link.get("rel").indexOf("tournament-promotions") > -1;
      });
    } catch (err) {}
    try {
      winners = props.game.get("links").find((link) => {
        return link.get("rel").indexOf("jackpot-winners") > -1;
      });
    } catch (err) {}
    try {
      gameTypes = props.game.get("links").find((link) => {
        return link.get("rel").indexOf("game-types") > -1;
      });
    } catch (err) {}

    this.state = {
      game: null,
      hovered: false,
      gameTypes: null,
      highestJpPoolAmount: null,
      links: {
        games,
        promotion,
        winners,
        gameTypes,
      },
      reloadJpPool: false,
      isVisible: false,
    };
  }

  componentDidMount() {
    this.setState({
      game: this.props.game,
      highestJpPoolAmount:
        this.props.game && this.props.game.get("highestJpPoolAmount")
          ? this.props.game.get("highestJpPoolAmount")
          : null,
    });

    if (this.props.game && this.props.game.getIn(["gameTypes"]) !== undefined) {
      this.setState({
        gameTypes: this.props.game.get("gameTypes"),
      });
    } else {
      if (this.state.links.gameTypes && this.props.fetchGameTypes) {
        this.props
          .fetchGameTypes(this.state.links.gameTypes)
          .then((response) => {
            this.setState({
              gameTypes: response.gameTypes ? response.gameTypes : [],
            });
          });
      }
    }
    this.initObeserver();
  }

  initObeserver() {
    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      let observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (this.mainComponent.current) {
                this.setState({
                  isVisible: true,
                });
              }
            } else {
              if (this.mainComponent.current) {
                //clearInterval(this.initLoadDelay)
              }
            }
          });
        },
        {
          //rootMargin: '500px 0px 500px 0px'
          rootMargin: "0px 0px 0px 0px",
        }
      );
      observer.observe(this.mainComponent.current);
    } else {
      //this.getGames(this.props.link);
      //this.getTournamentPreview(this.props.links)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.latestBroadcast !== this.props.latestBroadcast &&
      nextProps.latestBroadcast.get("jackpotGroupId") ===
        this.state.game.get("id")
    ) {
      let game = this.state.game;

      let jppKey;

      try {
        game.getIn(["jpPools"]).findKey((pool, index) => {
          if (pool.get("type") === nextProps.latestBroadcast.getIn(["type"])) {
            jppKey = index;
          }
        });
      } catch (err) {
        jppKey = 0;
      }

      game = game.setIn(
        ["jpPools"],
        fromJS([nextProps.latestBroadcast.toJSON()])
      );

      game = game.setIn(
        ["jpPools", jppKey, "amount"],
        nextProps.latestBroadcast.getIn(["amount"])
      );
      let sortedList;
      try {
        sortedList = game
          .get("jpPools")
          .sort((a, b) => Number(b.get("amount")) - Number(a.get("amount")));
      } catch (err) {}

      let highestJpPoolAmount = sortedList
        ? Number(sortedList.getIn([0, "amount"]))
        : Number(game.getIn(["jpPools", 0, "amount"]));

      this.setState({
        game,
        highestJpPoolAmount,
      });
    }
    if (!isEqual(nextProps.game, this.props.game)) {
      this.setState(
        {
          reloadJpPool: false,
        },
        () => {
          this.setState({
            reloadJpPool: true,
          });
        }
      );
    } else {
      this.setState({
        reloadJpPool: false,
      });
    }
  }

  componentWillUnmount() {
    if (this.props.game) {
      window.cancelAnimationFrame(this.animationFrameId);
    }
  }

  _onMouseOverHandler(bol) {
    this.setState({
      hovered: bol,
    });
  }

  resize2fit(elements) {
    elements.forEach((el) => {
      if (!el.parentElement) return;

      el.style.setProperty("--font-size", "1.5em");
      const {
        width: max_width,
        height: max_height,
      } = el.getBoundingClientRect();
      const { width, height } = el.children[0].getBoundingClientRect();
      el.style.setProperty(
        "--font-size",
        Math.min(max_width / width, max_height / height) + "em"
      );
    });
  }

  render() {
    const { game } = this.props;

    const { gameTypes } = this.state;

    this.resize2fit(document.querySelectorAll(".prize-container"));

    return (
      <div
        className={`${this.props.className} jackpot-type`}
        onMouseOver={() => {
          this.onMouseOverHandler(true);
        }}
        onMouseOut={() => {
          this.onMouseOverHandler(false);
        }}
        onClick={(event) => {
          // this.props.onClick(this.props.linkDetails.get('rel'))
          if (!this.props.categoryLink) {
            this.props.onClick();
          } else {
            this.props.categoryLink.get("rel");
          }
          event.stopPropagation();
        }}
        ref={this.mainComponent}
      >
        <div>
          <div>
            <div className="img-holder">
              {game && this.state.isVisible && (
                <img
                  ref={this.iconRef}
                  src={jackpotDefault}
                  className="icon d-none"
                  alt={game.get("name")}
                  onLoad={({ currentTarget }) => {
                    currentTarget.classList.remove("d-none");
                    this.iconDefaultRef.current.classList.add("d-none");
                  }}
                />
              )}
              <img
                ref={this.iconDefaultRef}
                src={bannerDefault}
                className="icon"
                alt=""
              />
              {this.props.game && this.props.game.get("highestJpPoolAmount") && (
                <Fragment>
                  <div
                    className={`amount-shadow ${
                      this.state.reloadJpPool ? "jp-update" : ""
                    }`}
                  >
                    <div className="row h-100 justify-content-center align-items-center prize-container">
                      <div className="col-auto text-center name">
                        {currencyFormatter(
                          this.props.game.get("highestJpPoolAmount"),
                          { currency: this.props.currency }
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`amount ${
                      this.state.reloadJpPool ? "jp-update" : ""
                    }`}
                  >
                    <div className="row h-100 justify-content-center align-items-center prize-container">
                      <div className="col-auto text-center name">
                        {currencyFormatter(
                          this.props.game.get("highestJpPoolAmount"),
                          { currency: this.props.currency }
                        )}
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
            {game && (
              <div className="details">
                <div className="title">{game.get("title")}</div>
                {gameTypes && gameTypes.size === 0 && <div>&nbsp;</div>}
                {game.get("subtitle") && (
                  <div className="secondary">
                    {<span>{game.get("subtitle")}</span>}
                  </div>
                )}
                <div className="row button-status">
                  <div className="col nopadding">
                    {this.state.links.winners && (
                      <div
                        className="btn btn-dark"
                        onClick={(event) => {
                          this.props.onViewWinnersClick();
                          event.stopPropagation();
                        }}
                      >
                        {this.state.links.winners.get("name")}
                      </div>
                    )}
                    {this.state.links.games && (
                      <div
                        className="btn btn-dark"
                        onClick={(event) => {
                          this.props.onViewGamesClick();
                          event.stopPropagation();
                        }}
                      >
                        {this.state.links.games.get("name")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {!game && (
              <div className="dummy">
                <div className="row">
                  <div className="col">
                    <span className="title">XXXXXXXXXXXXX</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <span className="prize-pool">XXXXXXXXX</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-auto">
                    <span className="btn1">XXXX XXXXXXX</span>
                  </div>
                  <div className="col-auto">
                    <span className="btn2">XXXX XXXXXXXXX</span>
                  </div>
                  <div className="col" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default JackpotType;
