import {
    LanguageTypes
  } from 'actions/types';
  import axios from 'axios';
  import {
    fromJS
  } from 'immutable';

  export function SHOW_LANGUAGE() {
    return ( dispatch, getState) => {
      dispatch({
        type: LanguageTypes.SHOW_LANGUAGE,
        payload: true
      })
      return true;
    }
  }

  export function fetchLanguage( route, params ) {
    return ( dispatch, getState ) => {
      try {
        var headers = getState().config.get( 'headers' ).toJSON();
        var url = route.get( 'href' );
        var method = route.get( 'method' )
      } catch ( error ) {
        throw error
      }

      dispatch( {
        type: LanguageTypes.FETCH_LANGUAGE,
        payload: {
          headers,
          route,
          params
        }
      })

      return axios( {
        method,
        headers,
        url,
        params
      })
      .then(({data}) => {
        return fromJS(data)
      })
      .catch(err => {
        throw err
      })
    }
  }

  export function setLanguage(payload) {
    return ( dispatch, getState ) => {
      dispatch( {
        type: LanguageTypes.SET_LANGUAGE,
        payload: payload
      })
      return payload
    }
  }
