import React, { Component, createRef } from "react";
import "component/listItem/BannerCategory/styles.scss";
import { TrophyIcon, PlayIcon } from "component/icons";
import { ReactComponent as CrownIcon } from "assets/icons/crown.svg";
import { currencyFormatter } from "utils/currencyHelper";

import defaultBanner from "assets/images/bannerDefault.png";

const BANNER_DEFAULT = "BANNER_DEFAULT";

class BannerCategory extends Component {
  constructor(props) {
    super(props);
    this.onMouseOverHandler = this._onMouseOverHandler.bind(this);
    this.checkIfCached = this._checkIfCached.bind(this);
    this.mainComponent = createRef();

    let jackpot = null;
    if (props.game.get("jackpotBalance")) {
      jackpot = currencyFormatter(props.game.get("jackpotBalance"), {
        currency: this.props.currency,
        symbol: this.props.symbol,
      });
    }
    this.state = {
      hovered: false,
      isLoadingImage: true,
      imgSrc: `${this.props.host}${this.props.game.getIn([
        "images",
        "url",
      ])}&width=180&theme=dark`,
      jackpot: jackpot,
      isVisible: false,
    };
  }

  _onMouseOverHandler(bol) {
    this.setState({
      hovered: bol,
    });
  }

  _checkIfCached(src) {
    let img = new Image();
    img.src = src;
    let isCached = img.complete;
    let hasNaturalWidth = !(img.naturalWidth === 0);
    img = null;
    if (!isCached || !hasNaturalWidth) {
      return false;
    }

    return true;
  }

  componentDidMount() {
    this.initObeserver();
  }

  initObeserver() {
    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      let observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (this.mainComponent.current) {
                this.setState({
                  isVisible: true,
                });
              }
            } else {
              if (this.mainComponent.current) {
                //clearInterval(this.initLoadDelay)
              }
            }
          });
        },
        {
          //rootMargin: '500px 0px 500px 0px'
          rootMargin: "0px 0px 0px 0px",
        }
      );
      observer.observe(this.mainComponent.current);
    } else {
      //this.getGames(this.props.link);
      //this.getTournamentPreview(this.props.links)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.game && nextProps.game !== this.props.game) {
      let jackpot;
      let imgSrc = `${nextProps.host}${nextProps.game.getIn([
        "images",
        "url",
      ])}&width=180&theme=dark`;

      if (nextProps.game.get("jackpotBalance")) {
        jackpot = currencyFormatter(nextProps.game.get("jackpotBalance"), {
          currency: this.props.currency,
          symbol: this.props.symbol,
        });
      }

      this.setState({
        imgSrc: imgSrc,
        jackpot: jackpot,
      });
    }
  }

  render() {
    let { game } = this.props;

    return (
      <div
        className={`${this.props.className} bannerCategory`}
        onMouseOver={() => {
          this.onMouseOverHandler(true);
        }}
        onMouseOut={() => {
          this.onMouseOverHandler(false);
        }}
        onClick={(event) => {
          if (this.props.linkDetails) {
            this.props.onClick(this.props.linkDetails.get("rel"));
            event.stopPropagation();
          }
        }}
        ref={this.mainComponent}
      >
        <div>
          {this.state.jackpot && (
            <div className="jackpot-amount">
              <CrownIcon />
              {this.state.jackpot}
              <CrownIcon />
            </div>
          )}
          {this.state.isVisible && (
            <img
              src={this.state.imgSrc}
              className={`img-fluid w-100 ${
                this.state.isLoadingImage ? "d-none" : ""
              } `}
              alt={game.get("name")}
              onLoad={({ currentTarget }) => {
                this.setState({
                  isLoadingImage: false,
                });
              }}
            />
          )}
          <img
            ref={BANNER_DEFAULT}
            src={defaultBanner}
            className={`img-fluid w-100 ${
              this.state.isLoadingImage ? "" : "d-none"
            } `}
            alt={game.get("name")}
          />
          {!this.props.isMobile && (
            <div
              className={`actions row w-100 justify-content-center align-items-center ${
                this.state.hovered ? "" : "d-none"
              }`}
            >
              <div className="col">
                {this.props.linkReal && (
                  <div
                    className="play-button text-center"
                    onClick={(event) => {
                      this.props.onClick(this.props.linkReal.get("rel"));
                      event.stopPropagation();
                    }}
                  >
                    <PlayIcon />
                  </div>
                )}
                {this.props.linkDemo && (
                  <div className="demo-button">
                    <button
                      className="btn btn-secondary btn-xs"
                      onClick={(event) => {
                        this.props.onClick(this.props.linkDemo.get("rel"));
                        event.stopPropagation();
                      }}
                    >
                      {this.props.linkDemo.get("name")}
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="labelRow">
            <span className={`label text-center`}>{game.get("name")}</span>
            {game.get("partOfOnGoingTournament") && (
              <span>
                <TrophyIcon color="rgba(255, 204, 0, 1)" selectedColor="" />
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default BannerCategory;
