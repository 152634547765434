import React, { Component } from "react";

import "component/listItem/RelatedCategory/styles.scss";
import {
  TrophyIcon,
  PlayIcon,
  RibbonIcon,
  EditorsChoiceIcon,
} from "component/icons";
import { ReactComponent as CrownIcon } from "assets/icons/crown.svg";
import { currencyFormatter } from "utils/currencyHelper";

import defaultBanner from "assets/images/bannerDefault.png";
import { setDefaultImageFormat } from "utils/image";

const BANNER_DEFAULT = "BANNER_DEFAULT";

class RelatedCategory extends Component {
  constructor(props) {
    super(props);
    this.onMouseOverHandler = this._onMouseOverHandler.bind(this);
    this.checkIfCached = this._checkIfCached.bind(this);
    let jackpot = null;
    let imgSrc = null;

    if (props.game && props.game.get("jackpotBalance")) {
      imgSrc = `${setDefaultImageFormat(
        this.props.game.getIn(["images", "bannerUrl"]) +
          "&width=450&theme=dark",
        props.imageFormat.get("banner")
      )}`;
      jackpot = currencyFormatter(props.game.get("jackpotBalance"), {
        currency: this.props.currency,
        symbol: this.props.symbol,
      });
    }
    this.state = {
      hovered: false,
      isLoadingImage: true,
      imgSrc,
      jackpot,
    };
  }

  _onMouseOverHandler(bol) {
    this.setState({
      hovered: bol,
    });
  }

  _checkIfCached(src) {
    let img = new Image();
    img.src = src;
    let isCached = img.complete;
    let hasNaturalWidth = !(img.naturalWidth === 0);
    img = null;
    if (!isCached || !hasNaturalWidth) {
      return false;
    }

    return true;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.game && nextProps.game !== this.props.game) {
      let jackpot;
      let imgSrc = `${setDefaultImageFormat(
        nextProps.game.getIn(["images", "bannerUrl"]) + "&width=450&theme=dark",
        this.props.imageFormat.get("banner")
      )}`;

      if (nextProps.game.get("jackpotBalance")) {
        jackpot = currencyFormatter(nextProps.game.get("jackpotBalance"), {
          currency: this.props.currency,
          symbol: this.props.symbol,
        });
      }
      this.setState({
        imgSrc: imgSrc,
        jackpot: jackpot,
      });
    }
  }

  renderSkeleton() {
    return (
      <div className="related-category">
        <img
          ref={BANNER_DEFAULT}
          src={defaultBanner}
          className="img-fluid w-100"
          alt=" "
        />
        <div className="labelRow">
          <span className="label">
            <div className="name">
              <span className="dummy">XXXXXXXXXXXXX</span>
            </div>
            <div className="provider">
              <span className="dummy">XXXXXXXXXXXXX</span>
            </div>
            <div className="game-type">
              <span className="dummy">XXXXXXXXXXXXX</span>
            </div>
          </span>
        </div>
      </div>
    );
  }

  hasPromotionIcons(game) {
    return (
      game.get("hasFreeRounds") ||
      game.get("partOfOnGoingTournament") ||
      game.get("isFeatured") ||
      game.get("isJackpot")
    );
  }

  render() {
    let { game } = this.props;

    return (
      <div
        className={`${this.props.className}`}
        onMouseOver={() => {
          this.onMouseOverHandler(true);
        }}
        onMouseOut={() => {
          this.onMouseOverHandler(false);
        }}
        onClick={(event) => {
          this.props.onClick(this.props.linkDetail.get("rel"));
          event.stopPropagation();
        }}
      >
        {(!game || game === undefined) && this.renderSkeleton()}
        {game && (
          <div className="related-category">
            {this.state.jackpot && (
              <div className="jackpot-amount">
                <CrownIcon />
                {this.state.jackpot}
                <CrownIcon />
              </div>
            )}
            <img
              src={`${setDefaultImageFormat(
                game.getIn(["images", "bannerUrl"]) + "&width=450&theme=dark",
                this.props.imageFormat.get("banner")
              )}`}
              className={`img-fluid w-100 ${
                this.state.isLoadingImage ? "d-none" : ""
              }`}
              alt={game.get("name")}
              onLoad={({ currentTarget }) => {
                this.setState({
                  isLoadingImage: false,
                });
              }}
            />
            <img
              ref={BANNER_DEFAULT}
              src={defaultBanner}
              className={`img-fluid w-100  ${
                this.state.isLoadingImage ? "" : "d-none"
              } `}
              alt={game.get("name")}
            />
            {!this.props.isMobile && (
              <div
                className={`actions row justify-content-center align-items-center ${
                  this.state.hovered ? "" : "d-none"
                }`}
              >
                <div className="col">
                  {this.props.linkReal && (
                    <div
                      className="play-button text-center"
                      onClick={(event) => {
                        this.props.onClick(this.props.linkReal.get("rel"));
                        event.stopPropagation();
                      }}
                    >
                      <PlayIcon />
                    </div>
                  )}
                  {this.props.linkDemo && (
                    <div className="demo-button">
                      <button
                        className="btn btn-secondary btn-xs"
                        onClick={(event) => {
                          this.props.onClick(this.props.linkDemo.get("rel"));
                          event.stopPropagation();
                        }}
                      >
                        {this.props.linkDemo.get("name")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="labelRow">
              <span className="label">
                <div className="name">{game.get("name")}</div>
                <div className="provider">{game.get("provider")}</div>
                <div className="game-type">{game.get("gameType")}</div>
              </span>
              {this.hasPromotionIcons && (
                <div className="game-feature">
                  {game.get("hasFreeRounds") && (
                    <div className="game-feature-badge ribbonIcon">
                      <RibbonIcon
                        color="rgba(255, 204, 0, 1)"
                        selectedColor=""
                      />
                    </div>
                  )}
                  {game.get("partOfOnGoingTournament") && (
                    <div className="game-feature-badge trophyIcon">
                      <TrophyIcon
                        color="rgba(255, 204, 0, 1)"
                        selectedColor=""
                      />
                    </div>
                  )}
                  {game.get("isJackpot") && (
                    <div className="game-feature-badge jackpotIcon">
                      <CrownIcon color="rgba(255, 204, 0, 1)" />
                    </div>
                  )}
                  {game.get("isFeatured") && (
                    <div className="game-feature-badge editorsChoiceIcon">
                      <EditorsChoiceIcon color="rgba(255, 204, 0, 1)" />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default RelatedCategory;
