import React, { Component, createRef } from "react";
import "component/listItem/ProviderType/styles.scss";
import iconDefault from "assets/images/iconDefault.png";
import { setDefaultImageFormat } from "utils/image";

const ICON_DEFAULT = "ICON_DEFAULT";

class ProviderType extends Component {
  constructor(props) {
    super(props);
    this.onMouseOverHandler = this._onMouseOverHandler.bind(this);
    this.mainComponent = createRef();
    let imgUrl =
      props.game && props.game.getIn(["images", "providers"])
        ? `${props.game.getIn(["images", "providers"])}`
        : iconDefault;

    this.state = {
      hovered: false,
      imgUrl,
      isVisible: false,
    };
  }

  _onMouseOverHandler(bol) {
    this.setState({
      hovered: bol,
    });
  }

  componentDidMount() {
    this.initObeserver();
  }

  initObeserver() {
    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      let observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (this.mainComponent.current) {
                this.setState({
                  isVisible: true,
                });
              }
            } else {
              if (this.mainComponent.current) {
                //clearInterval(this.initLoadDelay)
              }
            }
          });
        },
        {
          //rootMargin: '500px 0px 500px 0px'
          rootMargin: "0px 0px 0px 0px",
        }
      );
      observer.observe(this.mainComponent.current);
    } else {
      //this.getGames(this.props.link);
      //this.getTournamentPreview(this.props.links)
    }
  }

  render() {
    let { game } = this.props;

    return (
      <div
        className={`${this.props.className} provider-type`}
        onMouseOver={() => {
          this.onMouseOverHandler(true);
        }}
        onMouseOut={() => {
          this.onMouseOverHandler(false);
        }}
        onClick={(event) => {
          if (!this.props.categoryLink) {
            this.props.onClick();
          } else {
            this.props.categoryLink.get("rel");
          }

          event.stopPropagation();
        }}
        ref={this.mainComponent}
      >
        <div>
          {this.props.game && this.state.isVisible && (
            <img
              src={`${setDefaultImageFormat(
                this.state.imgUrl + "&width=200&theme=dark",
                this.props.imageFormat.get("providers")
              )}`}
              className="icon d-none"
              alt={game.get("name")}
              onLoad={({ currentTarget }) => {
                currentTarget.classList.remove("d-none");
                this.refs[ICON_DEFAULT].classList.add("d-none");
              }}
              ref={this.mainComponent}
            />
          )}
          <img ref={ICON_DEFAULT} src={iconDefault} className="icon" alt="" />
        </div>
      </div>
    );
  }
}

export default ProviderType;
