import React, { Component, createRef } from "react";

import "component/listItem/Similar/styles.css";

class Similar extends Component {
  constructor(props) {
    super(props);
    this.onMouseOverHandler = this._onMouseOverHandler.bind(this);
    this.mainComponent = createRef();
    let imgSrc;
    let imageCol;

    switch (props.sizeSuffix) {
      default:
        imageCol = "";
        imgSrc = props.iconSrc;
        break;
    }

    this.state = {
      imageCol: imageCol,
      imgSrc: imgSrc,
      hovered: false,
      isVisible: false,
    };
  }

  _onMouseOverHandler(bol) {
    this.setState({
      hovered: bol,
    });
  }

  setImgSrc(imgProps) {
    let imgSrc;
    let imageCol;

    switch (imgProps.sizeSuffix) {
      case "-lg":
        imageCol = "-4";
        imgSrc = imgProps.bannerSrc;
        break;
      default:
        imageCol = "";
        imgSrc = imgProps.iconSrc;
        break;
    }

    this.setState({
      imageCol: imageCol,
      imgSrc: imgSrc,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.sizeSuffix &&
      nextProps.sizeSuffix !== this.props.sizeSuffix
    ) {
      this.setImgSrc({
        sizeSuffix: nextProps.sizeSuffix,
        bannerSrc: nextProps.bannerSrc,
        iconSrc: nextProps.iconSrc,
      });
    }

    if (nextProps.bannerSrc && nextProps.bannerSrc !== this.props.bannerSrc) {
      this.setImgSrc({
        sizeSuffix: nextProps.sizeSuffix,
        bannerSrc: nextProps.bannerSrc,
        iconSrc: nextProps.iconSrc,
      });
    }

    if (nextProps.iconSrc && nextProps.iconSrc !== this.props.bannerSrc) {
      this.setImgSrc({
        sizeSuffix: nextProps.sizeSuffix,
        bannerSrc: nextProps.bannerSrc,
        iconSrc: nextProps.iconSrc,
      });
    }
  }

  componentDidMount() {
    this.initObeserver();
  }

  initObeserver() {
    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      let observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (this.mainComponent.current) {
                this.setState({
                  isVisible: true,
                });
              }
            } else {
              if (this.mainComponent.current) {
                //clearInterval(this.initLoadDelay)
              }
            }
          });
        },
        {
          //rootMargin: '500px 0px 500px 0px'
          rootMargin: "0px 0px 0px 0px",
        }
      );
      observer.observe(this.mainComponent.current);
    } else {
      //this.getGames(this.props.link);
      //this.getTournamentPreview(this.props.links)
    }
  }

  render() {
    return (
      <div
        className="col similar"
        onMouseOver={() => {
          this.onMouseOverHandler(true);
        }}
        onMouseOut={() => {
          this.onMouseOverHandler(false);
        }}
        ref={this.mainComponent}
      >
        <div className={`row ${this.props.selected ? "selected" : ""}`}>
          {this.state.isVisible && (
            <img
              className={`col-auto img img-fluid`}
              src={this.state.imgSrc}
              alt={this.props.imgAlt}
            />
          )}

          <div className="col info">
            <div className="title">{this.props.name}</div>
            <div className="provider">{this.props.provider}</div>
            <div className="gameType">{this.props.gameType}</div>
          </div>
        </div>
        <div
          className={`actions row h-100 justify-content-center align-items-center ${
            this.state.hovered ? "" : "d-none"
          }`}
        >
          <div className="col">
            {this.props.linkReal && (
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-danger btn-xs"
                  onClick={() => {
                    this.props.onClick(this.props.linkReal.get("rel"));
                  }}
                >
                  {this.props.linkReal.get("name")}
                </button>
              </div>
            )}
            {this.props.linkDemo && (
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-secondary btn-xs"
                  onClick={() => {
                    this.props.onClick(this.props.linkDemo.get("rel"));
                  }}
                >
                  {this.props.linkDemo.get("name")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Similar;
